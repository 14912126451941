import React, { ReactNode } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { Globals, ROLES } from "utils";
import { RootState } from "reducers";
import { SidebarMenu } from "utils/menu";
import { Module } from "models";

// const items = [
//   { path: "/prices", display_name: "Precios" },
//   { path: "/brands", display_name: "Marcas" },
//   { path: "/vehicles", display_name: "Vehiculos" },
//   { path: "/reports/most-searched", display_name: "Reporte - Mas Buscados" },
//   // {path: '/profile', display_name: "Perfil"},
// ];

const mapState = (state: RootState) => ({
  user: state.user,
  modules: state.modules,
});

const mapDispatch = {
  dispatchUser: () => ({ user: null, type: "User/REMOVE" }),
  // dispatchToken: () => ({ token: null, type: "Token/REMOVE" }),
  dispatchModules: () => ({ modules: [], type: "Modules/REMOVE" }),
  // dispatchAreas: () => ({ areas: [], type: "Area/REMOVE" }),
  // dispatchServices: () => ({ services: [], type: "Service/REMOVE" }),
};

const connector = connect(mapState, mapDispatch);

export type Menu = {
  display_name: string;
  path: string;
  roles: Array<any>;
};

export type State = {
  leftOpen: boolean;
  items: Menu[];
};

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

class LayoutAdmin extends React.PureComponent<Props, State> {
  readonly state: State = {
    leftOpen: true,
    items: SidebarMenu,
  };

  logout = () => {
    const {
      dispatchUser,
      // dispatchToken,
      dispatchModules,
      // dispatchAreas,
      // dispatchServices,
      history,
    } = this.props;

    Globals.confirm("¿Desea cerrar sesión?", () => {
      //--
      dispatchUser();
      // dispatchToken();
      dispatchModules();
      // dispatchAreas();
      // dispatchServices();

      history.replace("/login");
    });
  };

  toggleSidebar = (event: any) => {
    let key: any = `${event.currentTarget.parentNode.id}Open`;
    // @ts-ignore
    this.setState({ [key]: !this.state[key] });
  };

  render(): ReactNode {
    const leftOpen = this.state.leftOpen ? "open" : "closed";
    const { user, modules, history, children } = this.props;
    const { items } = this.state;
    let userName: string = "";

    if (user) {
      userName = user.email;
      if (user.firstName && user.lastName) {
        userName = user.firstName + " " + user.lastName;
      }
    }

    const role_id = !!user ? user.rol : 0;
    const modulesByRole = filterMenu(items, role_id);
    const isModerator = role_id === ROLES.MODERATOR;

    const menu: any = isModerator ? modules : modulesByRole;
    return (
      <div className="layout-admin">
        <nav className="navbar navbar-admin navbar-expand-md bg-dark navbar-dark fixed-top justify-content-end">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav">
              // @ts-ignore
              {menu.map((item, key) => {
                return (
                  <li
                    key={key}
                    className={
                      "nav-item " + history.location.pathname === item.path
                        ? "active"
                        : ""
                    }
                  >
                    <Link to={item.path} className="nav-link">
                      {item.display_name}
                    </Link>
                  </li>
                );
              })}
              <li className="nav-item">
                <button
                  data-toggle="collapse"
                  data-target=".navbar-collapse.show"
                  className="nav-link"
                  onClick={this.logout}
                >
                  Cerrar Sesión
                </button>
              </li>
            </ul>
          </div>
        </nav>

        <div id="layout">
          <div id="left" className={leftOpen}>
            <div className="icon mt-2 ml-2" onClick={this.toggleSidebar}>
              &equiv;
            </div>

            <div className={`sidebar ${leftOpen}`}>
              <div className="container-menu">
                <div className="menu">
                  <button
                    className="btn btn-default btn-logout"
                    onClick={this.logout}
                  >
                    <i className="fa fa-power-off"></i>
                  </button>
                  {/* <Link
                    className="btn btn-default btn-logout btn-profile"
                    to="/profile"
                  >
                    <i className="fa fa-user"></i>
                  </Link> */}
                  <div className="container-user">
                    <h2>{userName}</h2>
                  </div>
                  <ul className="menu">
                    {/* // @ts-ignore */}
                    {menu.map((item: any, key: number) => {
                      return (
                        <li
                          key={key.toString()}
                          className={
                            history.location.pathname === item.path
                              ? "active"
                              : ""
                          }
                        >
                          <Link to={item.path}>{item.display_name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`container-router ${leftOpen}`}>{children}</div>
        </div>
      </div>
    );
  }
}

function filterMenu(menu: Menu[], roleId: number) {
  return menu.filter(
    ({ roles }: { roles: Array<any> }) => !roles || roles.includes(roleId)
  );
}

export default withRouter(connector(LayoutAdmin));
