import { axios } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { User, Paginate, UserForm, UserResponse } from "models";

export interface Moderators {
  meta: Paginate;
  items: User[];
}

type Source = {
  token: CancelToken;
};

class ModeratorService {
  static getByPage = (page: number = 0, source: { token: CancelToken }) => {
    return new Promise<Moderators>((resolve, reject) => {
      axios
        .get(`users/moderators/get?page=${page}`, {
          cancelToken: source.token,
        })
        .then(
          (response: AxiosResponse<Moderators>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static store = (userForm: UserForm, source: Source) => {
    return new Promise<UserResponse>((resolve, reject) => {
      axios.post("users/moderators", userForm).then(
        (response: AxiosResponse<UserResponse>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static destroy = (id: number | null) => {
    return new Promise<User>((resolve, reject) => {
      axios.delete(`users/moderators/${id}`).then(
        (response: AxiosResponse<User>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static update = (userForm: UserForm) => {
    return new Promise<UserResponse>((resolve, reject) => {
      axios.post("users/moderators-update", userForm).then(
        (response: AxiosResponse<UserResponse>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default ModeratorService;
