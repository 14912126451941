import React from "react";
import {connect} from "react-redux";
import {RootState} from "reducers";
import {Pagination, Button, Modal, Table, Icon, Checkbox, Select, Input} from 'components';
import CreateEditVehicle from './create_edit_vehicle';
import {VehicleService} from 'services';
import {Globals, Constants} from 'utils';
import moment from 'moment';

// Se usa la variable para mostrar el boton de nuevo vehiculo, si la variable no existe (Produccion) no se mostrara el boton

class Vehicles extends React.Component<any> {

    state = {
        vehicles: [],
        errorMessage: '',
        noResultsMsg: 'No hay vehiculos registrados',
        create: false,
        header: [
            'Stock',
            'Fecha Registro',
            'Año',
            'Marca',
            'Modelo',
            'Precio',
            'Estatus',
            'Acciones'
        ],
        pages: {
            last: '',
            next: '',
            currentPage: 1,
            totalPages: ''
        },
        edit: null,
        form: {
            selectedVehicles: [] as number[],
            publishType: '',
            searchText: '',
            filterToday: false
        }
    }

    componentDidMount(): void {
       this.getVehicles()
    }
    
    getVehicles = (page: number = 1, params:any = {}) => {
        Globals.setLoading();
        VehicleService.paginate(page, {
            ...params,
            orderBy: 'createdAt',
            orderByType: 'DESC'
        })
            .then(({items, links, meta}) => {
                this.props.dispatch({
                    type: 'SET_VEHICLES',
                    payload: items
                });
                this.setState((state:any) => ({
                    noResultsMsg: params.filterToday ? 'No hay registros nuevos para el día de hoy' : state.noResultsMsg,
                    pages: {
                        ...links,
                        currentPage: parseInt(meta.currentPage),
                        totalPages: meta.totalPages
                    }
                }));
        })
        .catch((err) => {
            Globals.showError()
        })
        .finally(() => {
            Globals.quitLoading();
        });
    }

    create = () => {
        this.setState({
            create: true
        })
    }
    close = () => {
        const {pages, form} = this.state;
        this.setState({
            create: false,
            edit: null
        }, () => {
            this.getVehicles(pages?.currentPage, form);
            this.forceUpdate()
        });
    }

    delete = (element: any) => {
        const {pages, form} = this.state;
        Globals.confirm('¿Esta seguro de eliminar este vehiculo?', () => {
            Globals.setLoading()
            VehicleService.delete({id: element.id}).then(({ msg }) => {
                Globals.showSuccess(msg)
                this.getVehicles(pages?.currentPage, form);
            })
            .catch((err) => {
                Globals.showError()
            })
            .finally(() => {
                Globals.quitLoading();
            });
        })
    }

    publish = (element: any) => {
        const {pages, form} = this.state;
        const status:string = element.status === Constants.VEHICLE_STATUS_LIST.PUBLISHED ? 'despublicar' : 'publicar';
        Globals.confirm(`¿Esta seguro que desea ${status} este vehiculo?`, () => {
            VehicleService.publish({id: element.id}).then(({ msg }) =>{
                Globals.showSuccess(msg)
                this.getVehicles(pages?.currentPage, form);
            })
            .catch((err) => {
                Globals.showError()
            })
            .finally(() => {
                Globals.quitLoading();
            });
        })
    }

    change = (e: any) => {
        e.preventDefault();
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }            
        });
    }

    handleCheckbox = (e: any) => {
        const { value:id } = e.target
        const { selectedVehicles }:any = this.state.form
        const index:number = selectedVehicles.findIndex((vehicle:number) => vehicle === parseInt(id))
        if(index > -1) {
            selectedVehicles.splice(index, 1)
        } else {
            selectedVehicles.push(parseInt(id))
        }
        this.setState((state:any) => ({
            form: {
                ...state.form,
                selectedVehicles: selectedVehicles
            }            
        }));
    }

    massPublish = (selectedVehicles:Array<number> = []) => {
        const {form, pages} = this.state;
        const { publishType } = form
        const { currentPage } = pages
        if(publishType === ''){
            Globals.showError('Debe seleccionar un estado para continuar')
            return
        }
        const status:string = parseInt(publishType) === Constants.VEHICLE_STATUS_LIST.PUBLISHED ? 'publicar' : 'despublicar';
        Globals.confirm(`¿Esta seguro que desea ${status} estos vehiculos?`, () => {
            VehicleService.massPublish({selectedVehicles: selectedVehicles, publishType: parseInt(publishType)}).then(({ msg }) => {
                Globals.showSuccess(msg)
                this.getVehicles(currentPage, form);
                this.setState((state:any) => ({
                    form: {
                        publishType: '',
                        selectedVehicles: []
                    }            
                }));
            })
            .catch((err) => {
                Globals.showError()
            })
            .finally(() => {
                Globals.quitLoading();
            });

        })
    }

    clearFilter = () => {
        this.setState((state:any) => ({
            form: {
                ...state.form,
                filterToday: false,
                searchText: ''
            }            
        }), () => {
            this.getVehicles(1, this.state.form);
        });
    }

    render() {
        const {create, edit, form, header, pages, noResultsMsg} = this.state;
        const {vehicles} = this.props;
        return (
            <div className="vehicles-admin">
                <Modal
                    className="modal-lg"
                    visible={create}
                    onClose={this.close}
                    title="Crear/Editar Vehiculos"
                >
                    <CreateEditVehicle
                        onClose={this.close}
                        edit={edit}
                    />
                </Modal>
                <div className="row">
                    <form className="col-md-12 col-lg-12 row" onSubmit={ async (e: any) => {
                        e.preventDefault();
                        this.getVehicles(1, form);
                    } }>
                        <div className="col-md-6 col-lg-3">
                            <Input
                                name="searchText"
                                label="Buscar"
                                placeholder="Buscar por nombre, marca, modelo, año o numero de stock"
                                onChange={ this.change }
                                value={ form.searchText }/>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <Button className="btn-block btn-align-bottom text-black font-bold">
                                Filtrar
                            </Button>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <Button type="button" className="btn-block btn-align-bottom text-black font-bold" onClick={() => {
                                this.clearFilter();
                            }} >
                                Limpiar Filtro
                            </Button>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <Button type="button" className="btn-block btn-align-bottom text-black font-bold" onClick={() => {
                                form.filterToday = true
                                this.getVehicles(1, form);
                            }} >
                                Vehiculos nuevos
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                            <Select
                                name="publishType"
                                label="Actualizar Vehiculos"
                                color="white"
                                disabledFirst={true}
                                placeholder="Seleccione"this
                                onChange={ this.change }
                                value={ form.publishType }
                                options={ Constants.VEHICLE_PUBLISH_OPTIONS } />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Button className="btn-block btn-align-bottom text-black font-bold" onClick={() => this.massPublish(form.selectedVehicles)}>
                            Seleccionados
                        </Button>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <Button className="btn-block btn-align-bottom text-black font-bold" onClick={() => this.massPublish()}>
                            Todos
                        </Button>
                    </div>
                </div>
                <Table noResultsMsg={noResultsMsg} textRight={ [5] } data={ vehicles.length } title="Vehiculos" header={header} right={
                        <Button outline={true} small={true} className="btn-font-white" onClick={this.create} >
                            Agregar Nuevo
                        </Button>
                    }>
                    { vehicles && Array.isArray(vehicles) && vehicles.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ i.stockNumber }</td>
                                <td>{ moment(i.createdAt).format('MM/DD/YYYY') }</td>
                                <td>{ i.year }</td>
                                <td>{ i.brand?.name }</td>
                                <td>{ i.model?.name }</td>
                                <td className="text-right">{ Globals.formatMiles(i.price) }</td>                                
                                <td>{ i.status === Constants.VEHICLE_STATUS_LIST.PUBLISHED ? 'Publicado' : 'No Publicado' }</td>
                                <td>
                                    <Button title="Editar" color="primary" small={true} onClick={ () => 
                                        this.setState({
                                            create: true,
                                            edit: {
                                                element: i,
                                                index: index,
                                            }
                                        }) }>
                                        <Icon name="edit" />                                                
                                    </Button>
                                    <Button 
                                        title="Publicar" 
                                        color={i.status === Constants.VEHICLE_STATUS_LIST.PUBLISHED ? 'warning' : 'success'} 
                                        small={true} 
                                        onClick={ () => this.publish(i) }
                                    >
                                        <Icon name={i.status === Constants.VEHICLE_STATUS_LIST.PUBLISHED ? 'close' : 'check'} />                                                
                                    </Button>
                                    <Button title="Eliminar" color="danger" small={true} onClick={ () => this.delete(i) }>
                                        <Icon name="trash" />                                                
                                    </Button>
                                    <Checkbox
                                        value={i.id}
                                        label='Seleccione'
                                        checked={this.state.form.selectedVehicles.includes(i.id)}
                                        onChange={(e) => this.handleCheckbox(e)}
                                    />
                                </td>
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ pages } 
                    active={ pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState({
                            page: page
                        });
                        this.getVehicles(page, form);
                } } />
            </div>
        )
    }
}

const redux = (state: RootState) => ({
    vehicles: state.vehicles
});
export default connect(redux)(Vehicles);
