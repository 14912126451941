import React from 'react';
import { Tabs } from 'components'
import  { VehicleService } from 'services'
import { Globals } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import VehiclePhotos from './vehicle-photos';
import VehicleData from './vehicle-data';
import { emit } from 'jetemit';
	
const eventSubmit = "vehicle/edit";
const eventErrorSubmit = "vehicle/error";

const typeData = 'data';
const typePhoto = 'photos';

class CreateEditVehicle extends React.Component<any>{

	state = {
		itemSelected: 1,
		vehicle: this.props.edit?.element,
		translate: {
			brand: 'Marca',
			model: 'Modelo',
			stockNumber: 'Numero de Stock',
			year: 'Año'
		},
	}

	componentDidMount() {
		this.loadData()
	}


	submit = (form:any, models:any, brands:any, showMessage: boolean = true) =>{
		const { translate } = this.state;
		for(const propName in form){
			// @ts-ignore
			if(['model', 'brand', 'year', 'stockNumber'].includes(propName)){
				if(!form[propName]){
					Globals.quitLoading();
					// @ts-ignore
					Globals.showError('El campo '+translate[propName]+' es requerido');
					emit(eventErrorSubmit, typeData)
					emit(eventErrorSubmit, typePhoto)
					return
				}
			}
		}
		const { vehicle }:any = this.state;
		let photosData:any;
		const { label: brandLabel } = brands.find((brand:any) => parseInt(brand.value) === parseInt(form.brand))
		const { label: modelLabel } = models.find((model:any) => parseInt(model.value) === parseInt(form.model))
		if(!vehicle){
			const slug:string = form.year + '-' + brandLabel.toLowerCase() + '-' + modelLabel.toLowerCase() + '-' + uuidv4();
			form.slug = slug;
			form.name = form.year + ' ' + brandLabel + ' ' + modelLabel;
		} else {
			form.name = form.year + ' ' + brandLabel + ' ' + modelLabel;
		}
		if(form.photos) {
			photosData = {
				toUpload: form.photos.toUpload,
				toRemove: form.photos.toRemove,
				corner: form.photos.corner
			}
		}
		delete form.photos;
		// Globals.setLoading()
		VehicleService[ this.props.edit?.element ? 'edit' : 'create'](form)
			.then(({ msg, vehicle }) => {
				if (showMessage) {
					Globals.showSuccess(msg)

					photosData.id = vehicle.id;
					photosData.slug = vehicle.slug;

					this.submitPhotos(photosData, true)
					// this.props.onClose();
				}		
			})
			.catch((err) => {
				// console.log(err, 'err')
				// Globals.showError();
				emit(eventErrorSubmit, typePhoto)
				Globals.quitLoading();
			})
			.finally(() => {
				emit(eventErrorSubmit, typeData);
				
			})
	}

	submitPhotos = async (form:any, showMessage: boolean = true) =>{
		// Globals.setLoading()
		const { toUpload, toRemove, id, slug, corner } = form;
		const formDataRaw = {
			toUpload: toUpload ? toUpload.filter((i: any) => i.id === undefined).map((i: any) => i.value) : [],
			toRemove: toRemove ? toRemove : [],
			corner: corner,
			id: id,
			hasFile: true
		}
		VehicleService.removePhotos(formDataRaw, slug)
		.then(({ msg }) => {
			// Globals.showSuccess(msg);	
		})
		.catch((err) => {
			console.log(err, 'err')
			Globals.showError()
		})
		.finally(() => {
			emit(eventErrorSubmit, typePhoto)
			Globals.quitLoading()
			this.props.onClose();
		})
	}

	loadData = () => {
		const { vehicle } = this.state;
		if(vehicle){
			this.setState({
				form:{
					id: vehicle.id,
					slug: vehicle.slug,
					body: vehicle.body,
					// bodySpanish: vehicle.bodySpanish,
					style: vehicle.style,
					styleSpanish: vehicle.styleSpanish,
					price: vehicle.price,
					year: vehicle.year,
					mileage: vehicle.mileage,
					exterior: vehicle.exterior,
					exteriorSpanish: vehicle.exteriorSpanish,
					interior: vehicle.interior,
					interiorSpanish: vehicle.interiorSpanish,
					package: vehicle.package,
					packageSpanish: vehicle.packageSpanish,
					stockNumber: vehicle.stockNumber,
					engine: vehicle.engine,
					horsepower: vehicle.horsepower,
					torqueRating: vehicle.torqueRating,
					engineCylinders: vehicle.engineCylinders,
					engineDisplacement: vehicle.engineDisplacement,
					drivetrain: vehicle.drivetrain,
					doors: vehicle.doors,
					transmission: vehicle.transmission,
					fuelType: vehicle.fuelType,
					fuelTypeSpanish: vehicle.fuelTypeSpanish,
					fuelTank: vehicle.fuelTank,
					mpgCity: vehicle.mpgCity,
					mpgHwy: vehicle.mpgHwy,
					status: vehicle.status,
					damageType: vehicle.damageType,
					damageTypeSpanish: vehicle.damageTypeSpanish,
					titleStatus: vehicle.titleStatus,
					titleStatusSpanish: vehicle.titleStatusSpanish,
					vehicleDetails: vehicle.vehicleDetails,
					vehicleDetailsSpanish: vehicle.vehicleDetailsSpanish,
					airbagStatus: vehicle.airbagStatus,
					airbagStatusSpanish: vehicle.airbagStatusSpanish,
					brand: vehicle.brand?.id,
					model: vehicle.model?.id
				}
			})
		}
	}

	render(){
		const { itemSelected, vehicle } = this.state;
		return(
			<React.Fragment>
				<div id="modal-data-photos-vehicle" className="col-md-12">
					<Tabs
			    	onChange={ async (i: number) => {
			    		await this.setState({
							itemSelected: i
			    		});
			    	} }
			    	selected={ itemSelected }
			    	items={ [
						{ value: 1, label: 'Datos' },
						{ value: 2, label: 'Fotos' }
				    ] } />

					{/* Datos */}
					<div style={ {
						display: itemSelected === 1 ? undefined : 'none'
					} }>
						<VehicleData 
							eventSubmit={ eventSubmit } 
							eventErrorSubmit= { eventErrorSubmit }
							vehicle={ vehicle } 
							onSubmit={
								async (form:any, models:any, brands:any, showMessage: boolean = true) => {
								this.submit(form, models, brands, showMessage)
							}} 
						/>
					</div>
					

					{/* Fotos */}
					<div style={ {
						display: itemSelected === 2 ? undefined : 'none'
					} }>
						<VehiclePhotos 
							eventSubmit={ eventSubmit } 
							eventErrorSubmit= { eventErrorSubmit }
							vehicle={ vehicle }
							onSubmit={async (form:any, showMessage: boolean = true) =>{
								this.submitPhotos(form, showMessage)
							}} 
						/>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default CreateEditVehicle;