import React from 'react';

class ImageRegular extends React.Component<any> {
	
	render() {
		return (
			<div className={`image-component ${this.props.classcontainer} ${this.props.rounded ? 'image-component--rounded' : ''}`} onClick={this.props.onClick}>
				<img 
					alt={this.props.label ? this.props.label : ''} 
					src={ this.props.src }
					width={this.props.width}
					height={this.props.height}
				/>
				<label className="form-check-label" htmlFor={ `checkbox-${ this.props.label?.replace(' ','') }` }> 
					{ this.props.label }
				</label>
			</div>			
		)
	}
}

export default ImageRegular;