import React from "react";
import {connect} from "react-redux";
import {RootState} from "reducers";
import {Button, Modal, Table, Icon, Pagination} from 'components';
import CreateEditModel from './create_edit_model';
import {ModelService} from 'services'
import {Globals, Constants} from 'utils';
import { Link } from "react-router-dom";

class Models extends React.Component<any> {

    state = {
        models: [],
        errorMessage: '',
        create: false,
        header: [
            '#',
            'Nombre'
        ],
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null,
        brandId: this.props.match.params.brandId
    }

    componentDidMount(): void {
       this.getModels()
    }
    
    getModels = async (page: number = 1, params:any = {}) => {
        const { brandId } = this.state;
        const paramsSend = {
            ...params,
            brandId: brandId
        }
        const {items, links, meta} = await ModelService.paginate(page, paramsSend);
        this.props.dispatch({
            type: 'SET_MODELS',
            payload: items
        });
        this.setState({
            pages: {
                ...links,
                currentPage: meta.currentPage,
                totalPages: meta.totalPages
            }
        });
    }

    create = () => {
        this.setState({
            create: true
        })
    }
    close = () => {
        this.setState({
            create: false,
            edit: null,
        });
        this.getModels();
    }

    delete = (element: any) => {
        Globals.confirm('¿Esta seguro de eliminar este modelo?', async () => {
            const { msg } = await ModelService.delete({id: element.id});
            Globals.showSuccess(msg)
            this.getModels();
        })
    }

    render() {
        const { brandId } = this.state;
        return (
            <React.Fragment>
                <Modal
                    visible={this.state.create}
                    onClose={this.close}
                    title="Crear/Editar Modelos"
                >
                    <CreateEditModel
                        onClose={this.close}
                        brandId={brandId}
                        edit={this.state.edit}
                    />
                </Modal>
                <div className="col-md-2">
                    <Link to={`/brands`} className="nav-link">
                        <Button title="Volver">
                            Volver                                                
                        </Button>
                    </Link>
                </div>
                <Table data={ this.props.models.length } title="Modelos" header={this.state.header} right={
                        <Button className="btn-font-white" outline={true} small={true} onClick={this.create} >
                            <Icon name="plus" />
                        </Button>
                    }>
                    { this.props.models && Array.isArray(this.props.models) && this.props.models.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ i.id }</td>
                                <td>{ i.name }</td>
                                { Constants.MODEL_TYPES.ADMIN_CREATED === i.type && (
                                    <td>
                                        <Button title="Editar" color="primary" small={true} onClick={ () => 
                                            this.setState({
                                                create: true,
                                                edit: {
                                                    element: i,
                                                    index: index,
                                                }
                                            }) }>
                                            <Icon name="edit" />                                                
                                        </Button>
                                        <Button title="Editar" color="danger" small={true} onClick={ () => this.delete(i) }>
                                            <Icon name="trash" />                                                
                                        </Button>
                                    </td>
                                )}
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ this.state.pages } 
                    active={ this.state.pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState({
                            page: page
                        });
                        this.getModels(page);
                } } />
            </React.Fragment>
        )
    }
}

const redux = (state: RootState) => ({
    models: state.models
});
export default connect(redux)(Models);
