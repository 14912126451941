import { axios, Globals, Error } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class PriceService {
  static create = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`prices/create`, params)
        .then((res: AxiosResponse) => {
					resolve(res.data);
				})
        .catch((err: AxiosError) => {
					Error.default(err);
					reject(err);
				})
				.finally(Globals.quitLoading);
    });
  };

  static get = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`prices/get-all`, params)
        .then((res: AxiosResponse) => {
					resolve(res.data);
				})
        .catch((err: AxiosError) => {
					Error.default(err);
					reject(err);
				})
				.finally(Globals.quitLoading);
    });
  };

  static edit = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`prices/edit`, params)
        .then((res: AxiosResponse) => {
					resolve(res.data);
				})
        .catch((err: AxiosError) => {
					Error.default(err);
					reject(err);
				})
				.finally(Globals.quitLoading);
    });
  };
  static delete = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`prices/delete`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
  static paginate = (page:number, params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`prices/get?page=${page}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
}

export default PriceService;