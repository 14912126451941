import React from 'react';

class Select extends React.Component<any> {

	render() {
		return (
			<div className="form-group">
				{this.props.label && <label htmlFor={this.props.name}>{this.props.label}</label>}
				<select {...this.props} className={`form-control ${this.props.color ? this.props.color : ''}`}>
					{this.props.disabledFirst && (
						<option value="">
							{this.props.placeholder ? this.props.placeholder : 'Seleccione'}
						</option>
					)}
					{!this.props.disabledFirst && (
						<option value="" disabled>
							{this.props.placeholder ? this.props.placeholder : 'Seleccione'}
						</option>
					)}
					{this.props.options.map((i: any, index: number) => {
						return (
							<option
								key={index}
								value={i.value}>
								{i.label}
							</option>
						)
					})}
				</select>
			</div>
		)
	}
}

export default Select;