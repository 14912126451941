import Swal, { SweetAlertOptions } from "sweetalert2";

export const confirm = ({
  title = "",
  text,
  type = "warning"
}: SweetAlertOptions) => {
  return new Promise((resolve, _) => {
    Swal.fire({
      title,
      text,
      type,
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar"
    }).then(({ value }) => {
      resolve(!!value);
    });
  });
};
