import React from "react";

interface CheckboxProps {
  inline?: boolean;
  value: number | string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ inline, value, label, onChange, checked }) => (
  <div className={`form-check form-group ${inline ? "form-check-inline" : ""}`}>
    <input
      className="form-check-input"
      type="checkbox"
      checked={checked}
      id={`checkbox-${value}`}
      value={value}
      onChange={onChange}
    />
    <label className="form-check-label" htmlFor={`checkbox-${value}`}>
      {label}
    </label>
  </div>
);

export default Checkbox;
