const lang = (state = 'es', action: any) => {
	switch(action.type) {
		case 'SET_LANG':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default lang;