import React from "react";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { Button, Modal, Table, Icon } from 'components';
import EditAbout from './edit_about';
import { AboutService, BannerService } from 'services'

const language: any = {
    es: 'Español',
    en: 'Ingles'
}

const types: any = {
    1: 'Marcas',
    2: 'Inventario',
    3: 'Contacto',
    4: 'Galería'
}

const aboutTypes: any = {
    1: 'Inicio',
    2: 'Nosotros'
}

const typesEdit = {
    ABOUT: 1,
    BANNERS: 2
}

class About extends React.Component<any> {

    state: any = {
        about: [],
        banners: [],
        edit: false,
        header: [
            'Texto',
            'Idioma',
            'Tipo',
            'Acciones'
        ],
        headerBanners: [
            'Texto',
            'Idioma',
            'Tipo',
            'Acciones'
        ],
        item: null
    }

    async componentDidMount() {
       if (this.props.about) {
           await this.setState({
               about: this.props.about
           });
       }
       if (this.props.banners) {
           await this.setState({
               about: this.props.banners
           });
       }
       this.load()
    }
    
    load = async () => {
       const res: any = await Promise.all([
            AboutService.get(),
            BannerService.get()
       ]);
       this.setState({
           about: res[0].about,
           banners: res[1].banners
       });
    }

    close = async (data: any) => {
        this.setState({
            edit: false
        });
        if (data) {
            if (data.type == typesEdit.ABOUT) {
                let about = [...this.state.about];
                about[data.index] = data.element;
                await this.setState({
                    about
                });
                await AboutService.update(data.element);
            }
            else if (data.type == typesEdit.BANNERS) {
                let banners = [...this.state.banners];
                banners[data.index] = data.element;
                await this.setState({
                    banners
                });
                await BannerService.update(data.element);
            }            
        }
    }

    render() {
        const { about, item, banners } = this.state;

        return (
            <div className="about-admin">
                <Modal
                    visible={ this.state.edit }
                    onClose={ this.close }
                    title="Editar"
                >
                    <EditAbout
                        lang={ item && item.title }
                        item={ this.state.item }
                        onClose={ this.close }
                    />
                </Modal>
                <Table data={ about.length } title="Nosotros" header={ this.state.header }>
                    { about && about.map((i: any, index: number) => {
                        return (
                            <tr key={ index }>
                                <td>
                                    <p className="ellipsis">{ i.text }</p>
                                </td>
                                <td>{ language[i.language.name] }</td>
                                <td>{ aboutTypes[i.type] }</td>
                                <td>
                                    <Button title="Editar" color="primary" small={ true } onClick={ () => 
                                        this.setState({
                                            edit: true,
                                            item: {
                                                element: i,
                                                index: index,
                                                type: typesEdit.ABOUT,
                                                title: language[i.language.name]  + ` (${ aboutTypes[i.type] })`
                                            }
                                        }) }>
                                        <Icon name="edit" />                                                
                                    </Button>
                                </td>
                            </tr>
                        )
                    }) }
                </Table>
                <Table data={ about.length } title="Banners" header={ this.state.headerBanners }>
                    { banners && banners.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>
                                    <p className="ellipsis">{ i.text }</p>
                                </td>
                                <td>{ language[i.language.name] }</td>
                                <td>{ types[i.type] }</td>
                                <td>
                                    <Button title="Editar" color="primary" small={ true } onClick={ () => 
                                        this.setState({
                                            edit: true,
                                            item: {
                                                element: i,
                                                index: index,
                                                type: typesEdit.BANNERS,
                                                title: language[i.language.name] + ` (${ types[i.type] })`
                                            }
                                        }) }>
                                        <Icon name="edit" />                                                
                                    </Button>
                                </td>
                            </tr>
                        )
                    }) }
                </Table>
            </div>
        )
    }
}

const redux = (state: RootState) => ({
    about: state.about,
    banners: state.banners
});

export default connect(redux)(About);