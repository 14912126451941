let data: any;
let mode = 1;
let Base = "127.0.0.1:3000";
switch (mode) {
  case 1: // Local
    data = {
      BaseURI: "http://" + Base,
      BasePublic: "http://" + Base + "/api/public/storage/",
      Socket: "http://" + Base + ":11036"
    };
    break;
}
data.NAME = "Cars by Cars";
export default data;
