import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Icon from "./icon";
import moment from 'moment';

registerLocale('es', es)
setDefaultLocale('es')

class CustomInput extends React.Component<any> {
	render () {
		return (
			<div className={ `container-datepicker ${ this.props.color ? this.props.color : '' }` } 
				onClick={ this.props.onClick }>
				<p className={`${this.props.value ? this.props.value : 'container-datepicker-placeholder'}`}>{this.props.value ? moment(this.props.value,'DD/MM/YYYY').format('MM/DD/YYYY') : this.props.textDefault}</p>
        <Icon name="calendar" />
			</div>
		)
	}
}

const _DatePicker = (props: any) => (
	<div className="form-group">
		{ props.label && <label className="label-datepicker">{ props.label }</label> }
		<DatePicker
			{ ...props }
			maxDate={ props.maxDate }
			minDate={ props.minDate }
		    selected={ props.value }
		    onChange={ props.onChange }
		    dateFormat={ props.dateFormat || 'dd/MM/yyyy' }
		    customInput={ <CustomInput { ...props } /> }
		/>
	</div>
)

export default _DatePicker;