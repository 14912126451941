const RoleId = Object.freeze({
  ROOT: 1,
  USER: 2,
  MODERATOR: 3,

  getDisplayName(role: number) {
    switch (role) {
      case RoleId.ROOT:
        return "Administrador";
      case RoleId.MODERATOR:
        return "Moderador";
      case RoleId.USER:
        return "Usuario";
      default:
        return "";
    }
  },
});

export default RoleId;
