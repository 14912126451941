import React from 'react';
import moment from 'moment';
import { ENV } from 'utils';

const Copyright = () => {
	return (
		<div className="copyright-container">
			<p className="text-center copyright">
				&copy; { moment().format('YYYY') } { ENV.NAME }, Corp. | Designed by 
				<a href="https://www.limonbyte.com/" target="_blank" rel="noopener noreferrer"> Limónbyte</a>
			</p>
		</div>
	)
}

export default Copyright;