import React, { useState, useEffect, useMemo, FunctionComponent } from "react";
import axios, { CancelToken } from "axios";
import { Button, Modal, Table, Loading, Icon, Pagination } from "components";
import { useTranslation } from "react-i18next";

import { User } from "models";
import { ModeratorService } from "services";
import { handlerError, confirm } from "functions";
import { Constants } from "utils";

// Components
import ModeratorCreate from "./ModeratorCreate";
import ModeratorEdit from "./ModeratorEdit";

const header: string[] = ["Id", "Nombres", "Estatus", "Acciones"];

const ModeratorList = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<any | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [shouldCreate, setShouldCreate] = useState<boolean>(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const cancelToken = axios.CancelToken;
  const source = useMemo(() => cancelToken.source(), [cancelToken]);

  useEffect(() => {
    getModerators(page, source);
    return () => source.cancel("Cancel by user");
    // eslint-disable-next-line
  }, [page, source]);

  const getModerators = (page: number, source: { token: CancelToken }) => {
    if (page === 0) {
      return;
    }

    ModeratorService.getByPage(page, source)
      .then((response) => {
        const { items } = response;
        // setLastPage(meta.last_page);
        setUsers(items);
        setPage(page);
        // if (page <= last_page) {
        // } else {
        //   // TODO: Mejorar este hack para error de paginación
        //   getModerators(page - 1, source);
        // }
      })
      .catch(handlerError)
      .finally(() => setLoading(false));
  };

  const toggleCreateModal = () => setShouldCreate(!shouldCreate);

  const onCloseCreate = () => {
    const source = cancelToken.source();
    toggleCreateModal();
    getModerators(page, source);
  };

  const onCloseUpdate = () => {
    const source = cancelToken.source();
    toggleUpdateModal();
    getModerators(page, source);
  };

  const toggleUpdateModal = () => setShouldUpdate(!shouldUpdate);

  const onDelete = async (userId: number) => {
    const hasDelete = await confirm({ text: "¿Desea eliminar este usuario?" });

    if (!hasDelete) {
      return;
    }

    const isDeleted = await ModeratorService.destroy(userId);
    setUsers(users.filter(({ id }) => id !== isDeleted.id));
    const source = cancelToken.source();
    getModerators(page, source);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      {shouldCreate && (
        <Modal
          title="Crear nuevo moderador"
          onClose={toggleCreateModal}
          visible
        >
          <ModeratorCreate onClose={onCloseCreate} />
        </Modal>
      )}
      {shouldUpdate && (
        <Modal
          title="Editar moderador"
          onClose={toggleUpdateModal}
          visible
        >
          <ModeratorEdit moderator={user} onClose={onCloseUpdate} />
        </Modal>
      )}
      <Table
        data={users.length}
        title="Moderadores"
        header={header}
        right={
          <Button
            outline={true}
            small={true}
            className="btn-font-white"
            onClick={(event: React.FormEvent<HTMLButtonElement>): void => {
              event.preventDefault();
              toggleCreateModal();
            }}
          >
            <Icon name="plus" />
          </Button>
        }
      >
        {users.map((user) => (
          <RowUser
            key={user.id}
            user={user}
            onEdit={(user) => {
              setUser(user);
              toggleUpdateModal();
            }}
            onDelete={onDelete}
          />
        ))}
      </Table>
    </React.Fragment>
  );
};

type UserProps = {
  user: User;
  onEdit: (user: User) => void;
  onDelete: (id: number) => void;
};

const RowUser: FunctionComponent<UserProps> = (props) => {
  const { user, onEdit, onDelete } = props;

  const fullName = user.firstName + " " + user.lastName;
  const statusName =
    user.status === Constants.STATUS_ACTIVE ? "Activo" : "Inactivo";

  return (
    <tr>
      <td>{user.id}</td>
      <td>{fullName}</td>
      <td>{statusName}</td>
      <td>
        <Button
          title="Editar"
          color="primary"
          small={true}
          onClick={() => onEdit(user)}
        >
          <Icon name="edit" />
        </Button>
        <Button
          title="Editar"
          color="danger"
          small={true}
          onClick={() => onDelete(user.id)}
        >
          <Icon name="trash" />
        </Button>
      </td>
    </tr>
  );
};

export default ModeratorList;
