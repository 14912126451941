import React from 'react';
import { Input, Button, Avatar, PaperClip } from 'components';
import { CollectionService } from 'services';
import { Globals } from 'utils';
import OwlCarousel from "react-owl-carousel";
const { REACT_APP_BASE_API: BaseURL } = process.env; 

class CreateEditCollection extends React.Component<any>{

	state = {
		form: {
			id: null,
			title: '',
			photo: {
				file: null,
				preview: null
			},
		},
		images: [],
		deletedImages: []
	}

	componentDidMount(){
		this.load()
	}

	load = () => {
		if (this.props.edit?.element) {
			this.setState({
				form: {
					...this.state.form,
					id: this.props.edit.element.id,
					title: this.props.edit.element.title
				},
				images: this.props.edit.element.photos
			},this.forceUpdate);
		}
	}

	submit = async (e: any) => {
		e.preventDefault();
		if (!this.state.form.photo.file && !this.props.edit) {
			Globals.showError("Debe subir la foto principal");
			return false;
		}
		if (this.state.images.length < 1) {
			Globals.showError("Debe subir al menos 1 foto secundaria");
			return false;
		}
		if (!this.state.form.title) {
			Globals.showError("Debe indicar un título para la galería");
			return false;
		}
		Globals.setLoading();
		await CollectionService[this.props.edit?.element ? 'edit' : 'create']({
			hasFile: true,
			id: this.state.form.id,
			title: this.state.form.title,
			photo: this.state.form.photo.file,
			images: this.state.images.filter((i: any) => i.id == undefined).map((i: any) => i.value),
			deletedImages: JSON.stringify(this.state.deletedImages)
		})
			  .finally(Globals.quitLoading);
		Globals.showSuccess("Se ha guardado correctamente la galería");
		this.props.onClose();
	}

	change = (e: any) => {
		if (e.preventDefault)
			e.preventDefault();
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	deleteItem = (i: any,index: number) => {
		Globals.confirm('¿Eliminar la imagen secundaria?',() => {
			let images: any = [...this.state.images];
			images.splice(index,1);
			let deletedImages: any = [...this.state.deletedImages];
			if (i.id) {
				deletedImages.push(i.id);
			}
			this.setState({
				images,
				deletedImages
			},this.forceUpdate);
		});		
	}

	render() {
		const { images } = this.state;
		const { edit } = this.props;

		return (
			<form className="edit-collection" onSubmit={ this.submit }>
				<div className="row">
					<div className="col-md-6 text-center">
						<Avatar
							name="photo"
							onChange={ this.change }
							value={ this.state.form.photo.preview || (edit && (BaseURL + edit.element.photo)) } />
						<h3>Imagen Principal</h3>
					</div>
					<div className="col-md-6">
						<Input
							onChange={ this.change }
							name="title"
							label="Título"
							type="text"
							color="gray"
							value={ this.state.form.title }
						/>
					</div>
				</div>
				<h3>Imagenes Secundarias</h3>
				{ images.length == 0 && <p className="no-images">No se han seleccionado imagenes secundarias</p> }
				<OwlCarousel
	              className="owl-theme"
	              responsive={ {
	                0: {
	                  items: 1,
	                },
	                600: {
	                  items: 3,
	                },
	              } }
	            >
	              {
	              	images.map((i: any, index: number) => (
						<div className="item" key={ index }>
							<img src={ edit && i.id != undefined ? (BaseURL + i.file) : i.dataURL } />

							<button className="btn btn-default" type="button" onClick={ () => this.deleteItem(i,index) }>
								<i className="fa fa-trash"></i> Eliminar
							</button>
		                </div>
	              	))
	              }
	            </OwlCarousel>
				<div className="container-paper-clip">
					<PaperClip
						onChange={ (e: any) => {
							let images: any = [...this.state.images];
							images.push(e.target);
							this.setState({
								images
							},this.forceUpdate);				
						} } />
				</div>
				<div className="text-center">
					<Button type="submit">
						Guardar
					</Button>
				</div>
			</form>
		)
	}
}

export default CreateEditCollection;