import ROLES from "./RoleId";

export const SidebarMenu = [
  // {
  //   display_name: "Precios",
  //   path: "/prices",
  //   roles: [ROLES.ROOT],
  //   submodules: [],
  // },
  {
    display_name: "Moderadores",
    path: "/moderators",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Marcas",
    path: "/brands",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Vehiculos",
    path: "/vehicles",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Reporte - Más Buscados",
    path: "/reports/most-searched",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Reporte - Visitas",
    path: "/reports/most-visits",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Textos",
    path: "/texts",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Contacto",
    path: "/edit-contact",
    roles: [ROLES.ROOT],
    submodules: [],
  },
  {
    display_name: "Galería",
    path: "/edit-collections",
    roles: [ROLES.ROOT],
    submodules: [],
  },
];
