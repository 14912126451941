import Swal from "sweetalert2";

const INTERNAL_SERVER_ERROR = 500;
const NOT_FOUND_ERROR = 404;
const UNAUTHORIZED = 401;
const UNPROCESSABLE_ENTITY = 422;

export const handlerError = (error: any) => {
  const { message, response } = error;
  switch (response?.status) {
    case NOT_FOUND_ERROR:
      showError("No hemos encontrado lo que buscaba. 404");
      return;
    case INTERNAL_SERVER_ERROR:
      showError("Ha ocurrido un error interno. 500");
      return;
    case UNAUTHORIZED:
      showError("Usuario no autorizado. 401");
      return;
    case UNPROCESSABLE_ENTITY:
      showError(response.data.msg);
      return;
  }

  if (isNewtworkError(message)) {
    showError("Revise su conexión");
    return;
  }
};

const showError = (text: string) => {
  Swal.fire({
    title: "",
    text: text,
    type: "error",
    showCancelButton: false
  });
};

const isNewtworkError = (message: string): boolean =>
  message.indexOf("Network Error") > -1;
