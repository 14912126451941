import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";
import { Auth, User } from "models";

export interface TokenAuthentication {
  token: string;
  user: User;
}

export interface Register extends User {
  password: string;
  password_confirmation: string;
}

class AuthService {
  static login = (auth: Auth) => {
    return new Promise<TokenAuthentication>((resolve, reject) => {
      axios.post("auth/login", auth).then(
        (response: AxiosResponse<TokenAuthentication>) =>
          resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static register = (register: Register) => {
    return new Promise<Register>((resolve, reject) => {
      axios.post("register", register).then(
        (response: AxiosResponse<Register>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static me = () => {
    return new Promise<User>((resolve, reject) => {
      axios.post("me").then(
        (response: AxiosResponse<User>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default AuthService;
