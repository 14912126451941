import { axios, Globals, Error } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Vehicle, Brand } from "models";

type Source = {
  token: CancelToken;
};

class BrandService {
  static create = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`brands/create`, params).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };

  static getById = (id: number) => {
    return new Promise<Brand>((resolve, reject) => {
      axios.get(`brands/${id}`).then(
        (response: AxiosResponse<Brand>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static get = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`brands/get-all`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static edit = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`brands/edit`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
  static delete = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`brands/delete`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
  static paginate = (page: number, params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`brands/get?page=${page}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
}

export default BrandService;
