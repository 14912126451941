import React from "react";
import {connect} from "react-redux";
import {RootState} from "reducers";
import {Button, Modal, Table, Icon, Pagination} from 'components';
import CreateEditBrand from './create_edit_brand';
import {BrandService} from 'services'
import {Globals, Constants} from 'utils';
import { Link } from "react-router-dom";

class Brands extends React.Component<any> {

    state = {
        brands: [],
        errorMessage: '',
        create: false,
        header: [
            '#',
            'Nombre',
            'Acciones'
        ],
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null
    }

    componentDidMount(): void {
       this.getBrands()
    }
    
    getBrands = async (page: number = 1, params:any = {}) => {
        const {items, links, meta} = await BrandService.paginate(page, params);
        this.props.dispatch({
            type: 'SET_BRANDS',
            payload: items
        });
        this.setState({
            pages: {
                ...links,
                currentPage: meta.currentPage,
                totalPages: meta.totalPages
            }
        });
    }

    create = () => {
        this.setState({
            create: true
        })
    }
    close = () => {
        this.setState({
            create: false
        });
        this.getBrands();
    }

    delete = (element: any) => {
        Globals.confirm('¿Esta seguro de eliminar esta marca?', async () => {
            const { msg } = await BrandService.delete({id: element.id});
            Globals.showSuccess(msg)
            this.getBrands();
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    visible={this.state.create}
                    onClose={this.close}
                    title="Crear/Editar Marcas"
                >
                    <CreateEditBrand
                        onClose={this.close}
                        edit={this.state.edit}
                    />
                </Modal>
                <Table data={ this.props.brands.length } title="Marcas" header={this.state.header} right={
                        <Button className="btn-font-white" outline={true} small={true} onClick={this.create} >
                            <Icon name="plus" />
                        </Button>
                    }>
                    { this.props.brands && Array.isArray(this.props.brands) && this.props.brands.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ i.id }</td>
                                <td>{ i.name }</td>
                                { Constants.BRAND_TYPES.ADMIN_CREATED === i.type && (
                                    <td>
                                        <Button title="Editar" color="primary" small={true} onClick={ () => 
                                            this.setState({
                                                create: true,
                                                edit: {
                                                    element: i,
                                                    index: index,
                                                }
                                            }) }>
                                            <Icon name="edit" />                                                
                                        </Button>
                                        <Button title="Editar" color="danger" small={true} onClick={ () => this.delete(i) }>
                                            <Icon name="trash" />                                                
                                        </Button>
                                        <Link to={`models/${i.id}`}>
                                            <Button title="Modelos" color="warning" small={true}>
                                                <Icon name="list" />                                                
                                            </Button>
                                        </Link>
                                    </td>
                                )}
                                { Constants.BRAND_TYPES.BOT_CREATED === i.type && (
                                    <td>
                                        <Link to={`models/${i.id}`}>
                                            <Button title="Modelos" color="warning" small={true}>
                                                <Icon name="list" />                                                
                                            </Button>
                                        </Link>
                                    </td>
                                )}
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ this.state.pages } 
                    active={ this.state.pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState({
                            page: page
                        });
                        this.getBrands(page);
                } } />
            </React.Fragment>
        )
    }
}

const redux = (state: RootState) => ({
    brands: state.brands
});
export default connect(redux)(Brands);
