const brands = (state = false, action: any) => {
	switch(action.type) {
		case 'SET_BRANDS':
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default brands;