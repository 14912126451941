import { User } from 'models';
import { dolarService } from 'utils';
import { AxiosResponse, AxiosError } from 'axios';

type ProfileResponse = {
  user: User;
  message: string;
}
class ProfileService {
  
  static updateProfile = async (data: any) => {
    return new Promise<ProfileResponse>((resolve, reject) => {
      dolarService.post(`user/profile`, data).then(
          (response: AxiosResponse<ProfileResponse>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };
}

export default ProfileService;
