import React from "react";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { Button, Textarea, Input } from 'components';
import { ContactService } from 'services';
import { Globals } from 'utils';

class Contact extends React.Component<any> {

    state: any = {
        contact: [],
        send: false
    }

    async componentDidMount() {
       if (this.props.contact) {
           await this.setState({
               contact: this.props.contact
           });
       }
       this.load()
    }
    
    load = async () => {
       const res: any = await ContactService.get();
       this.setState({
           contact: res.contact
       });
    }

    submit = async () => {
        await this.setState({
            send: true
        });
        await ContactService.update(this.state.contact).finally(() => {
            this.setState({
                send: false
            });
        });
        Globals.showSuccess("Se han guardado los cambios correctamente");
        this.props.dispatch({
            type: 'SET_CONTACT',
            payload: this.state.contact
        });
    }

    change = (e: any) => {
        e.preventDefault();
        this.setState({
            contact: {
                ...this.state.contact,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        return (
            <div className="contact-admin">
                <div className="row">
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="phone"
                            label="Teléfono"
                            type="text"
                            color="gray"
                            value={ this.state.contact.phone }
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="whatsapp"
                            label="Whatsapp"
                            type="number"
                            color="gray"
                            value={ this.state.contact.whatsapp }
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="facebook"
                            label="Facebook"
                            type="text"
                            color="gray"
                            value={ this.state.contact.facebook }
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="instagram"
                            label="Instagram"
                            type="text"
                            color="gray"
                            value={ this.state.contact.instagram }
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="youtube"
                            label="Youtube"
                            type="text"
                            color="gray"
                            value={ this.state.contact.youtube }
                        />
                    </div>
                    <div className="col-md-6">
                        <Input
                            onChange={ this.change }
                            name="twitter"
                            label="Twitter"
                            type="text"
                            color="gray"
                            value={ this.state.contact.twitter }
                        />
                    </div>
                </div>
                <Textarea
                    color="gray"
                    label="Dirección (Español)"
                    name="address"
                    value={ this.state.contact.address }
                    onChange={ this.change }
                />
                <Textarea
                    color="gray"
                    label="Dirección (Ingles)"
                    name="address_en"
                    value={ this.state.contact.address_en }
                    onChange={ this.change }
                />
                <div className="text-center">
                    <Button onClick={ this.submit } disabled={ this.state.send }>
                        Guardar
                    </Button>
                </div>
            </div>
        )
    }
}

const redux = (state: RootState) => ({
    contact: state.contact
});

export default connect(redux)(Contact);