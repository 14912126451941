import Validator from "./Validator";

const EMAIL_VALIDATOR: Validator = new Validator()
    .notEmpty('Campo requerido')
    .isEmail('Debe ingresar una direccion de correo valida')

const PASSWORD_VALIATOR: Validator = new Validator()
    .notEmpty('Campo requerido')
    .minLength(6, 'Se requiere al menos %x caracteres');

const CONFIRM_PASSWORD_VALIDATOR: Validator = new Validator()
    .notEmpty('Campo requerido')
    .minLength(6, 'Se requiere al menos %x caracteres')
    .match('Las contraseñas no coinciden');

export {
    PASSWORD_VALIATOR,
    CONFIRM_PASSWORD_VALIDATOR,
    EMAIL_VALIDATOR
}
