import { axios, Error, Upload } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class CollectionService {
  static get = (params: any = {}) => {
    return new Promise<any>((resolve, reject) => {    
      axios.post('collections/get',params)
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }

  static create = (params: any = {}) => {
    return new Promise((resolve, reject) => {
      const _axios = params.hasFile ? Upload : axios.post;
      _axios("collections/create", params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        });
    });
  };

  static edit = (params: any = {}) => {
    return new Promise((resolve, reject) => {
      const _axios = params.hasFile ? Upload : axios.post;
      _axios("collections/edit", params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        });
    });
  };

  static delete = (params = {}) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post('collections/delete',params)
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }
}

export default CollectionService;
