import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";
import _axios from 'axios';

const INSTAGRAM_API = 'https://graph.instagram.com';

class InstagramService {
  static get = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post('instagram/get',params)
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }

  static media = (user_id: string, token: string) => {
    return new Promise((resolve, reject) => {
      _axios
        .get(`${ INSTAGRAM_API }/${ user_id }?access_token=${ token }&fields=media{media_url,permalink},media.limit(10)&limit=10`)
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }
}

export default InstagramService;
