import { combineReducers } from "redux";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { user } from "./user";
import { token } from "./token";
import { modules } from "./module";
import prices from "./prices";
import brands from "./brands";
import models from "./models";
import lang from "./lang";
import vehicles from "./vehicles";
import contact from "./contact";
import about from './about';
import carousel from './carousel';
import banners from './banners';
import schedule from './schedule';
import popUp from './pop-up';
import collections from './collections';
import filters from './filter';

export const reducer = combineReducers({
  brands,
  modules,
  models,
  user,
  token,
  prices,
  lang,
  vehicles,
  contact,
  about,
  carousel,
  banners,
  schedule,
  popUp,
  collections,
  filters
});

export type RootState = ReturnType<typeof reducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default reducer;
