import { UserAction, UserState } from "actions";
import { Reducer } from "redux";

export const user: Reducer<UserState, UserAction> = (state = null, action) => {
    switch (action.type) {
        case "User/SET":
            return action.user;
        case "User/REMOVE":
            return null;
        default:
            return state;
    }
};
