import React from "react";
import {Table, Pagination, Button, Select, DatePicker} from 'components';
import {ReportService, ModelService, BrandService} from 'services';
import moment from "moment"
import { Globals } from "utils";

class MostSearched extends React.Component<any> {

    state = {
        items: [],
        brands: [],
        models: [],
        header: [
            'Vehiculo',
            'Cantidad de Busquedas'
        ],
        form: {
            brand: '',
            model: '',
            since: '',
            until: ''
        },
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null
    }

    componentDidMount(): void {
       this.load();
    }
    
    getSearches = async (page: number = 1, params:any = {}) => {
        Globals.setLoading();
        ReportService.paginateMostSearched(page, params)
        .then(({items, meta}) => {
            this.setState({
                items: items,
                pages: {
                    currentPage: meta.currentPage,
                    totalPages: meta.totalPages
                }
            });
        })
        .catch((err) => {
            Globals.showError()
        })
        .finally(() => {
            Globals.quitLoading();
        });
    }

    change = (e: any) => {
        e.preventDefault();
        if(e.target.name === 'brand') {
            this.loadModels(parseFloat(e.target.value));
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value,
                    model: ''
                },
                models: []
            });
		} else {
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value
                }
            });
        }
    }

    changeDatepicker = (value: string, name: string) => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }
    
    loadModels(brand:number) {
		ModelService.get({brandId: brand, orderBy: 'name', orderByType: 'ASC'}).then((response:any) => {
			const { models } = response;
			const modelsFormat:any = models.map((model:any) => {
				return {
					label: model.name,
					value: model.id
				}
			})
			this.setState((state:any) => ({
				form: {
				  ...state.form
				},
				models: modelsFormat
			  }));
		})
	}

	loadBrands() {
		BrandService.get({orderBy: 'name', orderByType: 'ASC'}).then((response:any) => {
			const { brands } = response;
			const brandsFormat:any = brands.map((model:any) => {
				return {
					label: model.name,
					value: model.id
				}
			})
			this.setState((state:any) => ({
				form: {
                  ...state.form,
				},
                brands: brandsFormat
			  }));
		})
    }
    
    load = async () => {
        await this.getSearches();
        await this.loadBrands();
    }
    
    clearFilter = async () =>{
        this.setState((state:any) => ({
            ...state,
            form: {
                brand: '',
                model: '',
                since: '',
                until: ''
            }
        }));
        await this.getSearches();
    }

    render() {
        const { items, form, brands, models, pages } = this.state;
        return (
            <React.Fragment>
                <form className="row form-filter" onSubmit={ async (e: any) => {
                    e.preventDefault();
                    this.getSearches(1, form);
                } }>
                    <div className="col-md-2">
                        <Select
                            name="brand"
                            label="Marca"
                            color="white"
                            disabledFirst={true}
                            placeholder="Todos"
                            onChange={ this.change }
                            value={ form.brand }
                            options={ brands } />
                    </div>
                    <div className="col-md-2">
                        <Select
                            name="model"
                            label="Modelo"
                            color="white"
                            disabledFirst={true}
                            placeholder="Todos"
                            onChange={ this.change }
                            value={ form.model }
                            options={ models } />
                    </div>
                    <div className="col-md-2">
                        <DatePicker
                            name="since"
                            label="Desde"
                            color="white"
							maxDate={form.until ? moment(form.until).toDate() : moment().toDate()}
                            onChange={(value: string) => this.changeDatepicker(value, 'since') }
                            value={ form.since } />
                    </div>
                    <div className="col-md-2">
                        <DatePicker
                            name="until"
                            label="Hasta"
                            color="white"
                            minDate={form.since ? moment(form.since).toDate() : null}
							maxDate={moment().toDate()}
                            onChange={(value: string) => this.changeDatepicker(value, 'until') }
                            value={ form.until } />
                    </div>
                    <div className="col-md-1">
                        <Button className="btn-align-bottom text-black font-bold">
                            Filtrar
                        </Button>
                    </div>
                    <div className="col-md-1">
                        <Button onClick={() => this.clearFilter()} type="button" className="btn-align-bottom text-black font-bold">
                            Limpiar
                        </Button>
                    </div>
                </form>
                <Table data={ items.length } title="Vehiculos Mas Buscados" header={this.state.header}>
                    { items && Array.isArray(items) && items.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ i.brand?.name + ' ' + i.name }</td>
                                <td>{ i.clicksTotal }</td>
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ pages } 
                    active={ pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState((state:any) => ({
                            ...state,
                            pages: {
                                ...state.pages,
                                currentPage: page,
                            },
                        }));
                        this.getSearches(page, form);
                } } />
            </React.Fragment>
        )
    }
}

export default MostSearched;
