import React from "react";
import Layout from "../layout/layout";
import Banner from "../inventory/banner";
import { BannerType } from "models/bannerModel";
import { GalleryBanner, GalleryImage, VehicleTest } from "assets/img";
import {
  WithTranslation,
  withTranslation,
  useTranslation,
} from "react-i18next";
import { Collection } from "models/collectionModel";
import { Viewer } from "components";
import { CollectionService } from "services";
import { CollectionPhoto } from "models/collectionPhotoModel";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "../inventory/loading";
import FormVehicle from '../vehicle/DataSheetVehicle';
import { Modal } from 'react-bootstrap';

interface Props extends WithTranslation {}

const { REACT_APP_BASE_API: BaseURL } = process.env;

class Gallery extends React.Component<Props> {
  state = {
    items: [],
    visible: false,
    images: [],
    total: 0,
    page: 1,
    showModal: false,
    item: ''
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const res: any = await CollectionService.get({
      page: this.state.page,
    });
    this.setState({
      loading: false,
      items: [...this.state.items, ...res.collections.items],
      total: res.collections.meta.totalItems,
    });
  };

  viewMore = (i: Collection) => {
    const photos: any = i.photos?.map((i: CollectionPhoto) => {
      return {
        src: BaseURL + i.file,
      };
    });
    this.setState({
      images: [{ src: BaseURL + i.photo }, ...photos],
      visible: true,
    });
  };

  next = () => {
    const page: number = this.state.page;
    this.setState(
      {
        page: page + 1,
      },
      this.load
    );
  };

  toggleModal = (title: string = '') => {
    this.setState({
      item: title,
      showModal: !this.state.showModal
    });
  }

  render() {
    const { t } = this.props;
    const { items } = this.state;

    return (
      <React.Fragment>
        <Viewer
          visible={this.state.visible}
          onClose={() => this.setState({ visible: false })}
          images={this.state.images}
        />

        <Modal className="modal-gallery" onHide={ () => this.toggleModal() } show={ this.state.showModal }>
          <Modal.Body>
            <div className="text-right">
              <button className="btn btn-default" onClick={ () => this.toggleModal() }>
                <i className="fa fa-close"></i>
              </button>
            </div>
            <FormVehicle onSubmit={ () => this.toggleModal() } gallery={ this.state.item } />
          </Modal.Body>
        </Modal>

        <Layout>
        <div id="galle-ry">
          <Banner
            bg={GalleryBanner}
            align="left"
            hideImage={ true }
            title={t("gallery.GalleryTitle")}
            type={BannerType.GALLERY}
            styles={{
              height: "300px",
              marginBottom: "0px",
            }}
          />
          <div className="wrapper-contain" id="gallery">
            <h4>
              {this.state.total} {t("gallery.Photos")}
            </h4>
            <InfiniteScroll
              loader={<Loading />}
              dataLength={this.state.items.length}
              next={this.next}
              hasMore={this.state.items.length < this.state.total}
            >
              {items.map((i: Collection, index: number) => (
                <GalleryItem key={index} item={i} viewMore={this.viewMore} showModal={ () => this.toggleModal(i.title) } />
              ))}
            </InfiniteScroll>
          </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

interface PropsGalleryItem {
  viewMore: (i: Collection) => void;
  item: Collection;
  showModal: () => void;
}

const GalleryItem = (props: PropsGalleryItem) => {
  const { t } = useTranslation();

  return (
    <div className="gallery-item">
      <div className="card border">
        <div className="card-body p-1">
          <div className="row">
            <div className="col-lg-4">
              <img
                src={BaseURL + props.item.photo}
                className="img-gallery-item"
                onClick={() => props.viewMore(props.item)}
              />
            </div>
            <div className="col-lg-4">
              {props.item.photos && props.item.photos.length > 0 && (
                <img
                  src={BaseURL + props.item.photos[0].file}
                  className="img-gallery-item"
                  onClick={() => props.viewMore(props.item)}
                />
              )}
            </div>
            <div className="col-lg-4">
              <div
                className="view-more"
                onClick={() => props.viewMore(props.item)}
              >
                <p>{t("gallery.ViewMore")}</p>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-12">
              <div className="d-flex justify-content-betwen">
                <h5>{props.item.title}</h5>
                <button className="btn btn-yellow btn-rounded float-right btn-info-gallery" onClick={ props.showModal }>
                  {t("gallery.Info")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Gallery);
