import React, { Component } from "react";
import { Modal } from "components";
import { ProfileService } from "services";
import {
  PASSWORD_VALIATOR,
  CONFIRM_PASSWORD_VALIDATOR,
  EMAIL_VALIDATOR,
} from "utils/Validators";
import Validator from "utils/Validator";
import { RootState } from "reducers";
import { connect, ConnectedProps } from "react-redux";
import { User } from "models";
import { handlerError } from "functions";
import { Globals } from "utils";

type State = {
  showModalEditEmail: boolean;
  showModalEditPassword: boolean;
  form: {
    newPassword: string;
    confirmPassword: string;
    email: string;
  };
};

const mapDispatch = {
  dispatchUser: (user: User) => ({ user: user, type: "User/SET" }),
};

const mapState = (state: RootState) => ({
  user: state.user,
});

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector>;
class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModalEditEmail: false,
      showModalEditPassword: false,
      form: {
        newPassword: "",
        confirmPassword: "",
        email: !!props.user ? props.user.email : "",
      },
    };
  }

  emailValidator: Validator = EMAIL_VALIDATOR.clone();
  passwordValidator: Validator = PASSWORD_VALIATOR.clone();
  confirmPasswordValidator: Validator = CONFIRM_PASSWORD_VALIDATOR.clone();

  handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    this.setState((state) => ({
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  submitEmail = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const { dispatchUser } = this.props;
    const { email } = this.state.form;

    if (!this.emailValidator.validate(email)) {
      Globals.showError("El correo electronico no es valido!");
      return;
    }

    const obj = {
      email: email,
    };
    ProfileService.updateProfile(obj)
      .then((response) => {
        const { user, message } = response;
        dispatchUser({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          status: user.status,
          status_email: user.status_email,
          rol: user.rol,
          modules: user.modules,
        });

        this.setState((state) => ({
          showModalEditEmail: false,
          showModalEditPassword: false,
          form: {
            newPassword: "",
            confirmPassword: "",
            email: user.email,
          },
        }));

        Globals.showSuccess(message);
      })
      .catch(handlerError)
      .finally();
  };

  submitPassword = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const { dispatchUser } = this.props;
    const { newPassword, confirmPassword } = this.state.form;

    if (!this.passwordValidator.validate(newPassword)) {
      Globals.showError("La contraseña debe poseer por lo menos 6 caracteres!");
      return;
    }

    if (!this.confirmPasswordValidator.compare(newPassword, confirmPassword)) {
      Globals.showError("Las contraseñas no coinciden!");
      return;
    }

    const obj = {
      password: newPassword,
    };
    ProfileService.updateProfile(obj)
      .then((response) => {
        const { user, message } = response;

        dispatchUser({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          status: user.status,
          status_email: user.status_email,
          rol: user.rol,
          modules: user.modules,
        });

        this.setState((state) => ({
          showModalEditEmail: false,
          showModalEditPassword: false,
          form: {
            newPassword: "",
            confirmPassword: "",
            email: user.email,
          },
        }));
        Globals.showSuccess(message);
      })
      .catch(handlerError)
      .finally();
  };

  render() {
    return (
      <div>
        <div className="shadow-sm p-3 m-1 bg-white rounded">
          <strong>Perfil</strong>
        </div>

        {/* Cambiar email */}
        <div
          className="shadow-sm p-3 m-1 bg-white rounded mt-2"
          onClick={() => this.setState({ showModalEditEmail: true })}
        >
          <div className="d-flex flex-row p-1 text-info">
            {/* HEADER */}
            <div className="flex-fill">Cambiar correo</div>
            <div>
              <i className="fa fa-edit text-info" />
            </div>
          </div>
        </div>
        <div
          className="shadow-sm p-3 m-1 bg-white rounded mt-2"
          onClick={() => this.setState({ showModalEditPassword: true })}
        >
          <div className="d-flex flex-row p-1 text-info">
            <div className="flex-fill">Cambiar contraseña</div>
            <div>
              <i className="fa fa-edit text-info" />
            </div>
          </div>
        </div>
        <Modal
          visible={this.state?.showModalEditPassword || false}
          onClose={() => this.setState({ showModalEditPassword: false })}
        >
          <form onSubmit={this.submitPassword}>
            <div className="form-group">
              <label htmlFor="newPassword">Nueva contraseña</label>
              <input
                id="newPassword"
                name="newPassword"
                type="password"
                className="form-control"
                aria-describedby="emailHelp"
                onChange={this.handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword">Confirmar contraseña</label>
              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                className="form-control"
                aria-describedby="emailHelp"
                onChange={this.handleChange}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </Modal>
        <Modal
          visible={this.state?.showModalEditEmail || false}
          onClose={() => this.setState({ showModalEditEmail: false })}
        >
          <form onSubmit={this.submitEmail}>
            <div className="form-group">
              <label htmlFor="email">Correo Electronico</label>
              <input
                id="email"
                name="email"
                type="text"
                className="form-control"
                aria-describedby="emailHelp"
                value={this.state.form.email}
                onChange={this.handleChange}
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </form>
        </Modal>
      </div>
    );
  }
}

export default connector(Profile);
