import React from "react";
import { Globals } from "../utils";
import Button from "./button";
import Icon from "./icon";

class PaperClip extends React.Component<any> {
  private file: any;

  dataURItoBlob = (dataURI: any) => {
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);
    let mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  change = (e: any) => {
    let files = e.target.files || e.dataTransfer.files;
    for (let index = 0; index < files.length; index++) {
      const currentFile = files[index];
        if (!currentFile) {
          return;
        }
      let format = currentFile.name.split(".");
      let permitidos = ["jpg", "png", "gif", "jpeg"];
      if (permitidos.indexOf(format[format.length - 1].toLowerCase()) === -1) {
        Globals.showError("El formato de la imagen no es válido");
        return false;
      }
      let tipo = format;
      switch (tipo) {
        case "jpg":
          tipo = "image/jpeg";
          break;
  
        case "png":
          tipo = "image/png";
          break;
  
        case "gif":
          tipo = "image/gif";
          break;
  
        case "jpeg":
          tipo = "image/jpeg";
          break;
  
        default:
          tipo = "image/jpeg";
          break;
      }
      let reader = new FileReader();
      reader.readAsDataURL(currentFile);
      // eslint-disable-next-line no-loop-func
      reader.onload = file => {
        let tempImg: any = new Image();
        tempImg.src = reader.result;
        let self: any = this;
  
        tempImg.onload = function() {
          let MAX_WIDTH = 1000;
          let MAX_HEIGHT = 1000;
          let tempW = tempImg.width;
          let tempH = tempImg.height;
  
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW;
              tempW = MAX_WIDTH;
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH;
              tempH = MAX_HEIGHT;
            }
          }
          let resizedCanvas = document.createElement("canvas");
          resizedCanvas.width = tempW;
          resizedCanvas.height = tempH;
          let ctx: any = resizedCanvas.getContext("2d");
          ctx.drawImage(this, 0, 0, tempW, tempH);
          let dataURL = resizedCanvas.toDataURL(tipo);
          self.props.onChange({
            target: {
              name: self.props.name,
              value: self.dataURItoBlob(dataURL),
              dataURL
            }
          });
        };
      };
      
    }

  };

  render() {
    return (
      <React.Fragment>
        <input
          className="input-file-paperclip"
          ref={ref => (this.file = ref)}
          type="file"
          name={this.props.name}
          onChange={this.change}
          multiple={this.props.multiple ? this.props.multiple : false}
          style={this.props.inputStyle}
        />
        <Button
          color={this.props.value ? "green" : "orange"}
          type="button"
          onClick={() => this.file.click()}
        >
          <Icon name="paperclip" />
          {this.props.value ? "Imagen Cargada" : "Subir Imagen"}
        </Button>
      </React.Fragment>
    );
  }
}

export default PaperClip;
