import React, { ReactNode } from "react";
import axios, { CancelTokenSource, CancelToken } from "axios";
import { Input, Button, Select } from "components";
import { Globals, Constants } from "utils";
import { ModuleService, ModeratorService } from "services";
import { handlerError } from "functions";
import { Module, UserForm } from "models";

type State = {
  isSubmit: boolean;
  showErrors: boolean;
  isEditing: boolean;
  errors: [];
  modules: Module[];
  form: UserForm;
  module_ids: Set<number>;
  translate: any;
};

type Props = {
  onClose(): void;
};

const cancelToken = axios.CancelToken;
const source = cancelToken.source();

class ModeratorCreate extends React.PureComponent<Props, State> {
  public source: CancelTokenSource;

  constructor(props: Props) {
    super(props);

    this.state = {
      isEditing: false,
      isSubmit: false,
      showErrors: false,
      errors: [],
      modules: [],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        status: 0,
        password: "",
        passwordConfirmation: "",
        module_ids: [],
      },
      module_ids: new Set(),
      translate: {
        firstName: "nombre",
        lastName: "apellido",
        email: "correo electrónico",
        status: "estatus",
        password: "contraseña",
        passwordConfirmation: "confirmar contraseña",
      },
    };

    const cancelToken = axios.CancelToken;
    this.source = cancelToken.source();
  }

  componentDidMount() {
    this.load(source);
  }

  load = (source: { token: CancelToken }) => {
    ModuleService.get(source)
      .then((modules) => {
        this.setState({ modules: modules });
      })
      .catch(handlerError);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.currentTarget;

    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));
  };

  toggleModule = (id: number) => {
    const module_ids = new Set(this.state.module_ids);
    module_ids.has(id) ? module_ids.delete(id) : module_ids.add(id);

    this.setState({ module_ids });
  };

  onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const { isSubmit, form, translate, module_ids } = this.state;
    const { onClose } = this.props;

    for (let propName in form) {
      // @ts-ignore
      if (!form[propName] && propName !== "status") {
        // @ts-ignore
        return Globals.showError(
          "El campo " + translate[propName] + " es requerido"
        );
      }
    }

    if (isSubmit) {
      return;
    }

    this.setState({
      isSubmit: true,
    });

    form.module_ids = Array.from(module_ids);

    ModeratorService.store(form, this.source)
      .then(({ user }) => {
        Globals.showSuccess(
          `¡Se ha registrado el usuario ${user.firstName} ${user.lastName} correctamente!`
        );
        setTimeout(() => onClose(), 1000);
      })
      .catch((error) => {
        if (error?.response.status === 422) {
          Globals.showError(error.response.data.error);
          return;
        }

        handlerError(error);
      })
      .finally(() => {
        this.setState({ isSubmit: false });
      });
  };

  render(): ReactNode {
    const { modules, module_ids, isSubmit, form } = this.state;

    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit} noValidate autoComplete="off">
          <div className="row">
            <div className="col">
              <Input
                onChange={this.handleChange}
                name="firstName"
                label="Nombre"
                type="text"
                color="gray"
                value={form.firstName}
              />
            </div>
            <div className="col">
              <Input
                onChange={this.handleChange}
                name="lastName"
                label="Apellido"
                type="text"
                color="gray"
                value={form.lastName}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                onChange={this.handleChange}
                name="email"
                label="Correo electrónico"
                type="text"
                color="gray"
                value={form.email}
              />
            </div>
            <div className="col">
              <Select
                onChange={this.handleChange}
                name="status"
                label="Estatus"
                color="gray"
                options={Constants.STATUS_USER}
                value={form.status}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                onChange={this.handleChange}
                name="password"
                label="Contraseña"
                type="password"
                color="gray"
                value={form.password}
              />
            </div>
            <div className="col">
              <Input
                onChange={this.handleChange}
                name="passwordConfirmation"
                label="Confirmar contraseña"
                type="password"
                color="gray"
                value={form.passwordConfirmation}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              {modules.map((module) => (
                <ButtonSwitch
                  key={module.id}
                  id={module.id}
                  name={module.display_name}
                  isSelected={module_ids.has(module.id)}
                  onClick={this.toggleModule}
                />
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button submitted={isSubmit} block type="submit">
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

type ButtonProps = {
  id: number;
  name: string;
  isSelected: boolean;
  onClick: (id: number) => void;
};

const ButtonSwitch: React.FC<ButtonProps> = (props) => {
  const { id, name, isSelected, onClick } = props;
  return (
    <button
      className={`btn btn-sm ${
        isSelected ? "bg-success" : "bg-primary"
      } my-2 mr-2`}
      onClick={(event: React.FormEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        onClick(id);
      }}
      style={{ minWidth: "auto", color: "#fff" }}
    >
      {name}
    </button>
  );
};

export default ModeratorCreate;
