import React from "react";
import {connect} from "react-redux";
import {RootState} from "reducers";
import {Button, Modal, Table, Icon, Pagination} from 'components';
import CreateEditPrice from './create_edit_price';
import {PriceService} from 'services'
import {Globals} from 'utils';

class Page extends React.Component<any> {

    state = {
        prices: [],
        errorMessage: '',
        create: false,
        header: [
            '#',
            'Minimo',
            'Maximo',
            'Tasa'
        ],
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null
    }

    componentDidMount(): void {
       this.getPrices()
    }
    
    getPrices = async (page: number = 1, params:any = {}) => {
        const {items, links, meta} = await PriceService.paginate(page, params);
        this.props.dispatch({
            type: 'SET_PRICES',
            payload: items
        });
        this.setState({
            pages: {
                ...links,
                currentPage: meta.currentPage,
                totalPages: meta.totalPages
            }
        });
    }

    create = () => {
        this.setState({
            create: true
        })
    }
    close = () => {
        this.setState({
            create: false,
            edit: null
        });
        this.getPrices()
    }

    delete = (element: any) => {
        Globals.confirm('¿Esta seguro de eliminar este rango de precios?', async () => {
            const { msg } = await PriceService.delete({id: element.id});
            Globals.showSuccess( msg )
            this.getPrices()
        })
    }

    render() {
        return (
            <React.Fragment>
                <Modal
                    visible={this.state.create}
                    onClose={this.close}
                    title="Crear/Editar Precios"
                >
                    <CreateEditPrice
                        onClose={this.close}
                        edit={this.state.edit}
                    />
                </Modal>
                <Table textRight={ [1,2,3] } data={ this.props.prices.length } title="Precios" header={this.state.header} right={
                        <Button className="btn-font-white" outline={true} small={true} onClick={this.create} >
                            <Icon name="plus" />
                        </Button>
                    }>
                    { this.props.prices && Array.isArray(this.props.prices) && this.props.prices.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ i.id }</td>
                                <td className="text-right">{ Globals.formatMiles(i.limitMin) }</td>
                                <td className="text-right">{ Globals.formatMiles(i.limitMax) }</td>
                                <td className="text-right">{ Globals.formatMiles(i.rate) }</td>
                                <td>
                                    <Button title="Editar" color="primary" small={true} onClick={ () => 
                                        this.setState({
                                            create: true,
                                            edit: {
                                                element: i,
                                                index: index,
                                            }
                                        }) }>
                                        <Icon name="edit" />                                                
                                    </Button>
                                    <Button title="Editar" color="danger" small={true} onClick={ () => this.delete(i) }>
                                        <Icon name="trash" />                                                
                                    </Button>
                                </td>
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ this.state.pages } 
                    active={ this.state.pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState({
                            page: page
                        });
                        this.getPrices(page);
                } } />
            </React.Fragment>
        )
    }
}

const redux = (state: RootState) => ({
    prices: state.prices
});
export default connect(redux)(Page);
