import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Card, Input, Button } from "components";
import { RootState } from "reducers";
import { AuthService } from "services";
import { User } from "models";
import { handlerError } from "functions";

const mapState = (state: RootState) => ({
  user: state.user,
  modules: state.modules,
});

const mapDispatch = {
  dispatchUser: (user: User) => ({ user: user, type: "User/SET" }),
  dispatchModules: (modules: any[]) => ({
    modules: modules,
    type: "Modules/SET",
  }),
};

const connector = connect(mapState, mapDispatch);

type Props = ConnectedProps<typeof connector> & RouteComponentProps;

const initialState = {
  errors: [],
  showErrors: false,
  submitted: false,
  form: {
    email: "",
    password: "",
  },
};

type State = Readonly<typeof initialState>;

class Login extends React.PureComponent<Props, State> {
  //--
  readonly state: State = initialState;

  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = event.currentTarget;

    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    const { form, submitted } = this.state;
    const { dispatchUser, dispatchModules, history } = this.props;

    if (submitted) {
      return;
    }

    this.setState({ submitted: true });

    AuthService.login(form)
      .then((auth) => {
        const { user } = auth;

        const modulesUser = user.modules.map((module) => ({
          id: module.id,
          display_name: module.display_name,
          path: module.name,
          submodules: [],
        }));
        const allModules = [...modulesUser];

        dispatchUser(user);
        dispatchModules(allModules);

        history.replace("/vehicles");
      })
      .catch(handlerError)
      .finally(() => this.setState({ submitted: false }));
  };

  render() {
    const { form } = this.state;

    return (
      <div className="wrapper-content align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <Card>
              <form onSubmit={this.handleSubmit} noValidate autoComplete="off">
                <div className="container-icon">
                  <i className="fa fa-user"></i>
                </div>
                <div className="form-group">
                  <Input
                    color="gray"
                    name="email"
                    type="text"
                    placeholder="Correo electrónico"
                    onChange={this.handleChange}
                    value={form.email}
                  />
                </div>
                <div className="form-group">
                  <Input
                    color="gray"
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    onChange={this.handleChange}
                    value={form.password}
                  />
                </div>

                <Button block type="submit">
                  Iniciar sesión
                </Button>
              </form>

              {/* <div className="form-group">
                <Link to={"/register"}>Registrarme</Link>
              </div> */}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default connector(Login);
