import React from "react";

interface ButtonProps {
  submitted?: boolean;
  title?: string;
  disabled?: boolean;
  className?: string | undefined;
  outline?: boolean;
  clear?: boolean;
  type?: "button" | "submit";
  submit?: boolean;
  color?: string;
  small?: boolean;
  block?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<ButtonProps> = ({
  submitted = false,
  title,
  disabled,
  className,
  outline,
  clear,
  type,
  color,
  small,
  block,
  children,
  onClick
}) => {
  return submitted ? (
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <button
      title={title}
      disabled={disabled}
      className={`btn btn-primary ${className} ${
        outline ? "btn-outline" : ""
      } ${clear ? "btn-clear" : ""} ${color ? "btn-" + color : "btn-blue"} ${
        small ? "btn-small" : ""
      } ${block ? "btn-block" : ""}`}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

// class Button extends React.Component<any> {

// 	renderButton = () => {
// 		return (
// 			<button
// 				{ ...this.props }
// 				title=""
// 				className={ `btn btn-primary ${ this.props.className }
// 					${ this.props.outline ? 'btn-outline' : '' }
// 					${ this.props.clear ? 'btn-clear' : '' }
// 					${ this.props.color ? 'btn-' + this.props.color : 'btn-blue' }
// 					${ this.props.small ? 'btn-small' : '' }
// 					${ this.props.block ? 'btn-block' : '' }` }>
// 				{ this.props.children }
// 			</button>
// 		)
// 	}

// 	render() {
// 		if (this.props.title) {
// 			return (
// 				<OverlayTrigger
// 			      placement="top"
// 			      overlay={
// 			        <Tooltip id="tooltip-top">
// 			          { this.props.title }
// 			        </Tooltip>
// 			      }
// 			    >
// 					{ this.renderButton() }
// 				</OverlayTrigger>
// 			)
// 		}
// 		else {
// 			return this.renderButton();
// 		}
// 	}
// }

export default Button;
