import React from 'react';
import { BrandService, ModelService } from 'services';
import { Brand } from 'models/brandModel';
import { Model } from 'models/modelModel';
import { withTranslation, WithTranslation } from 'react-i18next';

interface onSubmitProps {
	brand?: string | number,
	model?: string | number,
	maxPrice?: string | number,
	minPrice?: string | number,
	search?: string | undefined | null | string[]
}

interface Props extends WithTranslation, onSubmitProps {
	onSubmit: (params: onSubmitProps) => void
}

class Search extends React.Component<Props> {

	state = {
		brands: [],
		models: [],
		form: {
			brand: this.props.brand || '',
			model: this.props.model || '',
			maxPrice: this.props.maxPrice || '',
			minPrice: this.props.minPrice || '',
			search: this.props.search || ''
		}
	}

	componentDidMount() {
		this.loadBrands();
	}

	loadBrands = async () => {
		const params = {
			orderBy: 'id',
			orderByType: 'ASC'
		}
		const res: any = await BrandService.get(params);
		await this.setState({
			brands: res.brands
		});
		if (this.props.brand) {
			this.loadModels();
		}
	}

	loadModels = async () => {
		await this.setState({
			loadingModel: true
		});
		const params = {
			orderBy: 'id',
			orderByType: 'ASC',
			brandId: this.state.form.brand
		}
		const res: any = await ModelService.get(params);
		this.setState({
			models: res.models,
			loadingModel: false
		});
	}

	change = async (e: any) => {
		await this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render() {
		const { models, brands, form: { brand, model, maxPrice, minPrice, search } } = this.state;
		const { t } = this.props;

		return (
			<form className="search-form" onSubmit={ async (e: any) => {
				e.preventDefault();
				this.props.onSubmit({
					brand,
					model,
					maxPrice,
					minPrice,
					search
				});
			} }>
				<div className="row row-filter">
					<div className="col-md-8">
						<input 
							name="search" 
							placeholder={ t('inventory.Search') }
							type="text" 
							className="form-control" 
							value={ search } 
							onChange={ this.change } />
					</div>
					{/*
					<div className="col-md-2">
						<select 
							name="brand" 
							className="form-control" 
							value={ brand } 
							onChange={ async (e: any) => {
								await this.setState({
									form: {
										...this.state.form,
										model: '',
										brand: e.target.value
									}
								});
								this.loadModels();
							} }>
								<option value="">{ t('inventory.Brand') }</option>
								{
									brands.map((i: Brand,index: number) => (
										<option key={ index } value={ i.id }>{ i.name }</option>
									))
								}
						</select>
					</div>
					{
						brand && (
							<div className="col-md-2">
								<select 
									name="model" 
									className="form-control" 
									value={ model } 
									onChange={ this.change }>
										<option value="">{ t('inventory.Model') }</option>
										{
											models.map((i: Model,index: number) => (
												<option key={ index } value={ i.id }>{ i.name }</option>
											))
										}
								</select>
							</div>
						)
					}
					<div className="col-md-2">
						<input 
							name="minPrice" 
							placeholder={ t('inventory.MinPrice') }
							type="number" 
							className="form-control" 
							value={ minPrice } 
							onChange={ this.change } />
					</div>
					<div className="col-md-2">
						<input 
							name="maxPrice" 
							placeholder={ t('inventory.MaxPrice') }
							type="number" 
							className="form-control" 
							value={ maxPrice } 
							onChange={ this.change } />
					</div>
					*/}
					<div className="col-md-4 text-center">
						<button className="btn btn-default" type="submit">
							{ t('inventory.Search') }
						</button>
					</div>
				</div>
			</form>
		)
	}
}

export default withTranslation()(Search);