import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer } from "reducers";
// import { useDispatch } from "react-redux";
import { ENV } from "utils";

const persistConfig = {
  key: ENV.NAME,
  storage,
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  applyMiddleware(createLogger())
);

// export type AppDispatch = typeof store.dispatch;

// export const useTypedDispatch: () => AppDispatch = useDispatch;

export const persistor = persistStore(store);
