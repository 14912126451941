import { axios, Error } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class ContactService {
  static get = () => {
    return new Promise((resolve, reject) => {
      axios.post("contact/get").then(
        (response: AxiosResponse) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static update = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post("contact/update", params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        });
    });
  };

  static send = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post("contact/send", params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        });
    });
  };
}

export default ContactService;
