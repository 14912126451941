import { axios, Globals, Error } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";

type Source = {
  token: CancelToken;
};

class ReportService {

  static paginateMostSearched = (page:number, params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`reports/most-searched?page=${page}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static paginateMostVisits = (page:number, params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`reports/most-visits?page=${page}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
}

export default ReportService;
