import React from 'react';
import {Input, Button } from 'components'
import {ModelService} from 'services'
import {Globals} from 'utils'

class CreateEditModel extends React.Component<any>{

	state = {
		form:{
			name: '',
			brandId: this.props.brandId
		},
		translate: {
			name: 'Nombre',
			models: 'Modelos'
		}
	}

	componentDidMount(){
		this.load()
	}

	load = () => {
		const { brandId, edit } = this.props;
		if(edit?.element){
			this.setState(state => ({
				form:{
					id: this.props.edit.element.id,
					name: this.props.edit.element.name,
					brandId: brandId
				}
			}))
		}
	}

	submit = async () =>{
		for(var propName in this.state.form){
			// @ts-ignore
			if(!this.state.form[propName]){
				// @ts-ignore
				Globals.showError('El campo '+this.state.translate[propName]+' es requerido')
				return false
			}
		}
		Globals.setLoading()
		const { msg } = await ModelService[ this.props.edit?.element ? 'edit' : 'create'](this.state.form)
		Globals.showSuccess(msg)
		this.props.onClose()
		Globals.quitLoading()
	}

	change = (e: any) => {
		e.preventDefault();
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render(){
		return(
			<React.Fragment>
				<Input
					onChange={this.change}
					name="name"
					label="Nombre"
					type="text"
					color="gray"
					value={this.state.form.name}
				/>
				<Button onClick={this.submit} >
					Guardar
				</Button>
			</React.Fragment>
		)
	}
}

export default CreateEditModel;