import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import { 
  About1,
  About2,
  About3 } from "assets/img";
import {
  EmailCircleBlue,
  LocationCircleBlue,
  PhoneCircleBlue,
} from "assets/icons";
import { useTranslation } from "react-i18next";
import { ImageRegular } from "../../../components";
import { InstagramService, PublicService } from "services";
import { useHistory } from "react-router-dom";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { Constants } from "utils";

const dispatchActions = {
  brands: "SET_BRANDS",
  about: "SET_ABOUT",
};

const AboutUs = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector((state: any) => {
    return {
      brands: state.brands,
      about: state.about,
    };
  });

  const [loading, setLoading] = useState(!state.brands);
  const [brands, setBrands] = useState(!state.brands ? [] : state.brands);
  const [about, setAbout] = useState(!state.about ? [] : state.about);

  useEffect(() => {
    const fetchData = async () => {
      const res: any = await InstagramService.get({
        orderBy: "name",
        orderByType: "ASC",
      });
      setBrands(res.brands);
      dispatch({
        type: dispatchActions.brands,
        payload: res.brands,
      });
      setAbout(res.about);
      dispatch({
        type: dispatchActions.about,
        payload: res.about,
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  const _about: any =
    about &&
    about.filter(
      (i: any) =>
        i.language.name === i18n.language &&
        i.type === Constants.ABOUT_TYPES.ABOUTUS
    );

  const contact = useSelector((state: { contact: any }) => state.contact);

  const visitBrand = async (name: string, brandId: number) => {
    const res = await PublicService.visitByBrand({ brand: brandId });
    history.push("/view/" + name);
  };

  return (
    <Layout>
      <div id="about-us">
        {/* About 1 */}
        <div
          className="about"
          style={{
            backgroundImage: `url(${About1})`,
          }}
        >
          {_about.length > 0 && (
            <div className="flex">
              <div className="offset-xl-6 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 container-about">
                <div className="container-text">
                  <h3>{t("about.AboutCarsbyCars")}</h3>
                  <p>{_about[0].text}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* About 2 */}
        <div className="about">
          {_about.length > 1 && (
            <div
              className="flex"
              style={{
                backgroundImage: `url(${About2})`,
                backgroundColor: "#dcdcdc",
              }}
            >
              <div className="container-about">
                <div className="container-text">
                  <h3>{t("about.WhatWeSpecializeIn")}</h3>
                  <p>{_about[1].text}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Iconos */}
        {/* <div className="about wrapper-contain">
          {contact && (
            <div className="flex">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row">
                <div className="container-about col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="container-image-text text-center">
                    <img src={PhoneCircleBlue} /> <p>{contact.phone}</p>
                  </div>
                </div>
                <div className="container-about col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="container-image-text text-center">
                    <img src={EmailCircleBlue} />{" "}
                    <p>
                      <a style={{
                        whiteSpace: 'pre'
                      }} href={`mailto:${contact.email}`}>{contact.email}</a>
                    </p>
                  </div>
                </div>
                <div className="container-about col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="container-image-text text-center">
                    <img src={LocationCircleBlue} />{" "}
                    <p>
                      {i18n.language == "es"
                        ? contact.address
                        : contact.address_en}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>*/}
        {/* About 3 */}
        <div
          className="about"
          style={{
            backgroundImage: `url(${About3})`,
          }}
        >
          {_about.length > 2 && (
            <div className="flex">
              <div className="offset-xl-6 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 container-about">
                <div className="container-text">
                  <h3>{t("about.FullTransparency")}</h3>
                  <p>{_about[2].text}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Marcas */}
       {/* <div className="banner">
          <div className="container-banner">
            {loading ? (
              <div className="text-center">
                <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              </div>
            ) : (
              <React.Fragment>
                <section className="brands-container">
                  <div className="row brands">
                    {brands.map((i: any, index: number) => (
                      <div
                        className="col-md-2 col-6 px-0 text-center"
                        key={index}
                      >
                        <a
                          className="brand-link"
                          href="#"
                          onClick={(event: any) => visitBrand(i.name, i.id)}
                        >
                          <p className="name-brand">{i.name}</p>
                        </a>
                      </div>
                    ))}
                  </div>
                </section>
              </React.Fragment>
            )}
          </div>
        </div>*/}
      </div>
    </Layout>
  );
};

export default AboutUs;
