import React, { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import { ContactService } from "services";
import { useSelector, useDispatch } from "react-redux";
import Whatsapp from "../home/whatsapp";
import { useHistory } from "react-router-dom";
import PopUp from "./popup";

const Layout = ({
  children,
  bgColor,
  showFooter = true
}: {
  children: React.ReactNode;
  bgColor?: string;
  showFooter?: boolean;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {show} = useSelector((state: { popUp: any }) => state.popUp);
  
  useEffect(() => {
    const fetchData = async () => {
      const res: any = await ContactService.get();
      dispatch({
        type: "SET_CONTACT",
        payload: {
          ...res.contact,
          brands: res.brands,
          models: res.models,
        },
      });
      dispatch({
        type: "SET_BANNERS",
        payload: res.banners,
      });
      const formatPopUp = {
        show: !show ? show : true,
        data: res.popup
      }
      dispatch({
        type: "SET_POPUP",
        payload: formatPopUp,
      });
    };
    fetchData();
  }, []);

  const dataPopUp = useSelector((state: { popUp: any }) => state.popUp);
  const showPopUp = dataPopUp?.show;
  const isHome = history.location.pathname === "/";

  return (
    <div id="public">
      <Header />
      <div
        className={`public-container ${bgColor ? bgColor : ""} `}
        style={{ marginTop: isHome ? "80px" : 0 }}
        >
        {children}
      </div>
      {showFooter && <Footer />}

      <div className="whatsapp-container whatsapp-global">
        <Whatsapp />
      </div>
      {/* <PopUp showPopUp={showPopUp}/> */}
    </div>
  );
};

export default Layout;
