import React from "react";
import { Phone, Location } from "assets/icons";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { BgFooter, Logo } from "assets/img";
import { Link } from "react-router-dom";
import Whatsapp from "../home/whatsapp";
import { Model } from "models";
import { Brand } from "models/brandModel";
import Copyright from "./copyright";
import i18next from "i18next";
import { useHistory } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const contact = useSelector((state: { contact: any }) => state.contact);

  return (
    <footer
      style={{
        backgroundImage: `url(${BgFooter})`,
      }}
    >
      <div className="row">
        <div className="col-md-2">
          <div className="container-logo">
            <Link to="/">
              <img src={Logo} className="logo" />
            </Link>
          </div>
        </div>
        <div className="col-md-7">
          <div className="flex flex-brands">
            <div className="flex-33">
              {/*<h3>{t("home.TopMakes")}</h3>
              {contact.brands &&
                contact.brands.map((i: Brand, index: number) => {
                  return (
                    <Link to={"/view/" + i.name} key={index}>
                      <p>{i.name}</p>
                    </Link>
                  );
                })}*/}
            </div>
            <div className="flex-33">
              {/*  <h3>{t("home.TopModel")}</h3>
              {contact.models &&
                contact.models.map((i: any, index: number) => {
                  return (
                    <a
                      style={{ cursor: "pointer" }}
                      key={index.toString()}
                      onClick={() => {
                        // to={"/view/"+(i.brand?.name || '')+'/'+i.name}
                        history.push(
                          "/view/" + (i.brand?.name || "") + "/" + i.name
                        );
                        window.location.reload();
                      }}
                    >
                      <p>
                        {i.brand?.name} {i.name}
                      </p>
                    </a>
                  );
                })}*/}
            </div>
            <div className="flex-33">
              <Link
                to="/inventory"
                onClick={() => {
                  dispatch({
                    type: "SET_FILTERS",
                    payload: {},
                  });
                }}
              >
                <p>{t("home.Menu.Inventory")}</p>
              </Link>
              <Link to="/about-us">
                <p>{t("home.Menu.About")}</p>
              </Link>
              <Link to="/contact">
                <p>{t("home.Menu.Contact")}</p>
              </Link>
              <Link to="/gallery">
                <p>{t("home.Menu.Gallery")}</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <p>
            <img src={Location} />{" "}
            {i18next.language == "es" ? contact.address : contact.address_en}
          </p>
          <p>
            <img src={Phone} /> {contact.phone}
          </p>
          <div className="container-socials">
            <div className="row row-socials">
              <div className="col-3">
                <a target="_blank" href={contact.twitter}>
                  <i className="fa fa-twitter"></i>
                </a>
              </div>
              <div className="col-3">
                <a target="_blank" href={contact.youtube}>
                  <i className="fa fa-youtube-play"></i>
                </a>
              </div>
              <div className="col-3">
                <a target="_blank" href={contact.instagram}>
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
              <div className="col-3">
                <a target="_blank" href={contact.facebook}>
                  <i className="fa fa-facebook"></i>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="whatsapp-container">
						<Whatsapp number={ contact.whatsapp } />
					</div> */}
        </div>
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer;
