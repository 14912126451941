import React from "react";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { Button, Modal, Table, Icon, Pagination } from 'components';
import CreateEditCollection from './create_edit_collection';
import { CollectionService } from 'services'
import { Globals } from 'utils';
import { Collection } from 'models/collectionModel';

class Collections extends React.Component<any> {

    state = {
        create: false,
        header: [
            'Título',
            'Acciones'
        ],
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null
    }

    componentDidMount() {
       this.load()
    }
    
    load = async (page: number = 1, params: any = {}) => {
        const { collections: { items, links, meta } } = await CollectionService.get({
            page,
            ...params
        });
        this.props.dispatch({
            type: 'SET_COLLECTIONS',
            payload: items
        });
        this.setState({
            pages: {
                ...links,
                currentPage: meta.currentPage,
                totalPages: meta.totalPages
            }
        });
    }

    create = () => {
        this.setState({
            create: true
        })
    }

    close = () => {
        this.setState({
            create: false,
            edit: null
        });
        this.load();
    }

    delete = (element: any) => {
        Globals.confirm('¿Esta seguro de eliminar esta galería?', async () => {
            Globals.setLoading();
            await CollectionService.delete({ id: element.id }).finally(Globals.quitLoading);
            Globals.showSuccess("Se ha eliminado correctamente la galería");
            this.load();
        });
    }

    render() {
        return (
            <div className="collections-admin">
                <Modal
                    className="modal-lg"
                    visible={ this.state.create }
                    onClose={ this.close }
                    title="Crear/Editar Galería"
                >
                    <CreateEditCollection
                        onClose={ this.close }
                        edit={ this.state.edit }
                    />
                </Modal>

                <Table data={ this.props.collections.length } title="Galería" header={ this.state.header } right={
                        <Button className="btn-font-white" outline={ true } small={ true } onClick={ this.create } >
                            <Icon name="plus" />
                        </Button>
                    }>
                    { this.props.collections && Array.isArray(this.props.collections) && this.props.collections.map((i: Collection,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>
                                    <p className="ellipsis">{ i.title }</p>
                                </td>
                                <td>
                                    <Button title="Editar" color="primary" small={ true } onClick={ () => 
                                        this.setState({
                                            create: true,
                                            edit: {
                                                element: i,
                                                index: index,
                                            }
                                        }) }>
                                        <Icon name="edit" />                                                
                                    </Button>
                                    <Button title="Eliminar" color="danger" small={ true } onClick={ () => this.delete(i) }>
                                        <Icon name="trash" />                                                
                                    </Button>
                                </td>
                            </tr>
                        )
                    }) }
                </Table>
                <Pagination 
                    pages={ this.state.pages } 
                    active={ this.state.pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState({
                            page: page
                        });
                        this.load(page);
                } } />
            </div>
        )
    }
}

const redux = (state: RootState) => ({
    collections: state.collections
});
export default connect(redux)(Collections);
