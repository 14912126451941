import { axios } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Module } from "models";

class ModuleService {
  static get = (source: { token: CancelToken }) => {
    return new Promise<Module[]>((resolve, reject) => {
      axios
        .get(`modules/get`, {
          cancelToken: source.token,
        })
        .then(
          (response: AxiosResponse<Module[]>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };
}

export default ModuleService;
