const popUp = (state = false, action: any) => {
	switch(action.type) {
		case 'SET_POPUP':
            return action.payload;
			break;
        default: 
        	return state;
        	break;
	}
}

export default popUp;