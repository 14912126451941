import HoldOn from "react-hold-on";
import Swal from "sweetalert2";
import moment from "moment";
import $ from "jquery";

class Globals {
  setLoading = () => {
    HoldOn.open({
      theme: "sk-bounce"
    });
  };

  quitLoading = () => {
    HoldOn.close();
  };

  formatMiles = (n: any, decimals = false, whiteSpace = false) => {
    var c: any = isNaN(c = Math.abs(c)) ? (decimals ? 2 : 0) : c,
      d: any = d == undefined ? "." : d,
      t: any = t == undefined ? (whiteSpace ? " " : ",") : t,
      s: any = n < 0 ? "-" : "",
      i: string = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j: any = (j = i.length) > 3 ? j % 3 : 0;

    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - parseFloat(i)).toFixed(c).slice(2) : "");
  }

  hideTooltip = () => {
    $("button").blur();
  };

  showSuccess = (message: string) => {
    Swal.fire({
      title: "",
      text: message,
      type: "success",
      showCancelButton: false
    });
  };

  showError = (message?: string) => {
    Swal.fire({
      title: "",
      text: message ? message : "Se ha producido un error",
      type: "error",
      showCancelButton: false
    });
  };

  confirm = (message: string, callback: any) => {
    this.hideTooltip();
    Swal.fire({
      title: "",
      text: message,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar"
    }).then(confirm => {
      if (confirm.value) {
        callback();
      }
    });
  };

  getDate = (
    date: string,
    to: string = "DD/MM/YYYY",
    from: string = "YYYY-MM-DD HH:mm:ss"
  ) => {
    return moment(date, from).format(to);
  };

  clean(cadena: string) {
     var specialChars = "!@#$^&%*()+=-[]\/{}|:<>?,.";
     for (var i = 0; i < specialChars.length; i++) {
         cadena= cadena.replace(new RegExp("\\" + specialChars[i], 'gi'), '');
     }   
     cadena = cadena.toLowerCase();
     cadena = cadena.replace(/ /g,"_");
     cadena = cadena.replace(/á/gi,"a");
     cadena = cadena.replace(/é/gi,"e");
     cadena = cadena.replace(/í/gi,"i");
     cadena = cadena.replace(/ó/gi,"o");
     cadena = cadena.replace(/ú/gi,"u");
     cadena = cadena.replace(/ñ/gi,"n");
     return cadena;
    }

  formatPrice(price: number) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    return formatter.format(price);
  }

  formatNumber(cant: number) {
    const pieces = cant.toFixed(0).split('')
    let ii = pieces.length
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return pieces.join('')
  }
}

export default new Globals();
