import React, { ReactNode, Suspense } from "react";
import { Provider, connect, useSelector, useDispatch } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from 'react-router-last-location';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import { PublicService } from "services";

// Translate
import "functions/i18n";
import i18next from "i18next";
import 'moment/locale/es';
import moment from 'moment';

// Screens (Public)
import Home from "screens/public/home/home";
import ViewBrand from "screens/public/home/view_brand";
import Inventory from "screens/public/inventory/inventory";
import ViewVehicle from "screens/public/vehicle/ViewVehicle";
import Contact from "screens/public/contact/contact";
import Gallery from "screens/public/gallery/gallery";
import AboutUs from "screens/public/about-us/about-us";

// Screens (Admin)
import Login from "screens/admin/login/Login";
import Prices from "screens/admin/prices/prices";
import Profile from "screens/admin/profile/profile";
import Brands from "screens/admin/brands/brands";
import Models from "screens/admin/models/models";
import Vehicles from "screens/admin/vehicles/vehicles";
import MostSearched from "screens/admin/reports/most-searched";
import ModeratorList from "screens/admin/moderators/ModeratorList";
import MostVisits from "screens/admin/reports/most-visits";
import About from "screens/admin/about/about";
import AdminContact from "screens/admin/contact/contact";
import AdminCollections from "screens/admin/collections/collections";

// Layout
import { PrivateRoute } from "components";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./assets/app.scss";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

import { RootState } from "reducers";
import { UserState } from "actions";

class App extends React.Component<any> {
  componentDidMount() {
    if (!store.getState().user) this.load();
  }

  load = () => {
    PublicService.visit();
  };

  componentWillUnmount() {
    console.log(">>: willUnmount");
  }
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <RoutesContainer />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

const RoutesContainer = () => {
  const dispatch = useDispatch();
  const existsPopUp = useSelector((state: { popUp: any }) => state.popUp);
  if(existsPopUp){
    setTimeout(function() {
      const popUpPayload = {
        show: true,
        data: existsPopUp.data
      }
      dispatch({
        type: "SET_POPUP",
        payload: popUpPayload,
      })
    }, 900000)
  }
  const lang = useSelector((state: { lang: string }) => state.lang);
  i18next.changeLanguage(lang);
  moment.locale(lang);

  return (
    <Suspense fallback="">
      <LastLocationProvider>
        {/* Public */}
        <Route exact path="/" component={Home} />
        <Route exact path="/view/:brand?/:model?" component={ViewBrand} />
        <Route exact path="/inventory" component={ViewBrand} />
        <Route exact path="/vehicle/:brand/:slug" component={ViewVehicle} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/about-us" component={AboutUs} />

        {/* Admin */}
        <GuestRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/prices" component={Prices} />
        <PrivateRoute exact path="/brands" component={Brands} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/models/:brandId" component={Models} />
        <PrivateRoute exact path="/moderators" component={ModeratorList} />
        <PrivateRoute exact path="/vehicles" component={Vehicles} />
        <PrivateRoute
          exact
          path="/reports/most-searched"
          component={MostSearched}
        />
        <PrivateRoute exact path="/reports/most-visits" component={MostVisits} />
        <PrivateRoute exact path="/texts" component={About} />
        <PrivateRoute exact path="/edit-contact" component={AdminContact} />
        <PrivateRoute exact path="/edit-collections" component={AdminCollections} />
      </LastLocationProvider>
    </Suspense>
  );
};

interface GuestRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

const mapState = (state: RootState) => ({
  user: state.user,
});

class GuestRouteComponent extends Route<
  GuestRouteProps & {
    user: UserState;
  }
> {
  render(): ReactNode {
    const { component: Component, ...rest }: GuestRouteProps = this.props;
    const { user: isAuthenticated } = this.props;
    const renderComponent: RenderComponent = (props) =>
      !!isAuthenticated ? <Redirect to="/vehicles" /> : <Component {...props} />;

    return <Route {...rest} render={renderComponent} />;
  }
}

export const GuestRoute = connect(mapState)(GuestRouteComponent);

export default App;
