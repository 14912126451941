import { axios } from 'utils';

const createFormData = (data: any) => {
  var formdata = new FormData();
  for (var key in data) {
    if (Array.isArray(data[key])) {
      for (var _key in data[key]) {
        formdata.append(key + '[]', data[key][_key]);
      }      
    }
    else {
      formdata.append(key, data[key]);
    }    
  }
  return formdata;
}

const upload = (url: string, data: any) => {
  return axios.post(url, createFormData(data), {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export default upload;