import React from 'react';
import { ImageRegular, Button, PaperClip, Select } from '../../../components';
import { on, emit } from 'jetemit';
import { Globals } from 'utils';
import { ReferenceCorners } from "assets/img";

const { REACT_APP_BASE_API: BaseURI } = process.env;

const type = "photos";
class VehiclePhotos extends React.Component<any>{

	state = {
		submit: false,
		form:{
			photos: [],
			toRemove: [] as number[],
			toUpload: [] as Array<any>,
			corner: '2'
		},
		translate: {
			photos: 'Fotos',
			toRemove: 'Fotos a remover',
			toUpload: 'Fotos a enviar'
		},
		corners: [
			{
				value: '1',
				label: 'Superior izquierda (1)'
			},
			{
				value: '2',
				label: 'Superior derecha (2)'
			},
			{
				value: '3',
				label: 'Inferior izquierda (3)'
			},
			{
				value: '4',
				label: 'Inferior derecha (4)'
			},
		],
		subscriberError: () => {}
	}

	componentDidMount(){
		this.load();

		this.setState({
			subscriberError: on(this.props.eventErrorSubmit, (data:string) => {
				if(data === type)
					this.resetButton()
			})
		});
	}

	componentWillUnmount() {
		this.state.subscriberError();
	}

	loadData(vehicle:any) {
		this.setState({
			form:{
				id: vehicle.id,
				slug: vehicle.slug,
				photos: vehicle.photos,
				corner: '2',
				toRemove: [] as number[],
				toUpload: [] as Array<any>
			}
		})
	}

	load = async () => {
		const { vehicle } = this.props;
		if(vehicle){
			await this.loadData(vehicle);
		}
	}

	removePhoto = (removedPhoto: any) => {
		let { photos, toRemove, toUpload } = this.state.form;
		let cleanedPhotos: Array<any> = [];
		if(removedPhoto.id) {
			cleanedPhotos = photos.filter((photo:any) => {
				return photo.id !== removedPhoto.id;
			});
			toRemove.push(removedPhoto.id)
		} else {
			cleanedPhotos = photos.filter((photo:any) => {
				return photo.dataURL !== removedPhoto.dataURL;
			});
			toUpload = toUpload.filter((photo:any) => {
				return photo.dataURL !== removedPhoto.dataURL;
			});
		}
		this.setState((state:any) =>({
			form:{
				...state.form,
				photos: cleanedPhotos,
				toRemove: toRemove,
				toUpload: toUpload
			}
		}))
	}

	change = (e: any) => {
		const {name, value} = e.target
		this.setState({
			form: {
				...this.state.form,
				[name]: value
			}
		}, async () => {
			emit(this.props.eventSubmit, 
				{toUpload: this.state.form.toUpload, toRemove: this.state.form.toRemove, corner: this.state.form.corner, save: false})
		});
	}

	resetButton = () => {
		this.setState({
			submit: false
		})
	}

	render(){
		const { form, corners, submit} = this.state;
		return(
			<form onSubmit={(e) => {
				e.preventDefault()
				// this.props.onSubmit(form)
				this.setState({
					submit: true
				})
				emit(this.props.eventSubmit, 
					{toUpload: this.state.form.toUpload, toRemove: this.state.form.toRemove, corner: this.state.form.corner, save: true})
				Globals.setLoading()
			}}>
				<div className="col-md-12 vehicle-photos-container">
					<div className="row text-center p-4">
						<div className="col-md-6">
							<ImageRegular 
								width={150}
								height={150} 
								src={ ReferenceCorners } 
							/>
						</div>
						<div className="col-md-6">
							<Select
								onChange={this.change}
								disabledFirst={true}
								name="corner"
								label="Seleccione la posicion del Parche"
								color="gray"
								options={corners}
								value={form.corner}
							/>
							<p>
								El parche se aplica de acuerdo a la esquina seleccionada, como se muestra en la imagen de ejemplo
							</p>
						</div>
					</div>
					<div className="row col-md-12 col-lg-12 text-center p-4">
						{ form.photos && Array.isArray(form.photos) && form.photos.map((photo: any, index: number) => {
								return (
									<div className="col-md-6 col-lg-3">
										<button type="button" className="close" aria-label="Eliminar" onClick={() => this.removePhoto(photo)}>
											<span aria-hidden="true">&times;</span>
										</button>
										<ImageRegular 
											key={index}
											width={150}
											height={150} 
											src={ photo.id ? (BaseURI + photo.photo) : photo.dataURL } 
										/>
									</div>
								)
						})
					}
					</div>
					<div className="row text-center p-4">
						<div className="col-md-6">
							<PaperClip
								multiple={true}
								onChange={ async (e: any) => {
									const photos: any = [...form.photos];
									const toUpload: any = [...form.toUpload];
									await photos.push(e.target);
									await toUpload.push(e.target);
									await this.setState({
										form: {
											...form,
											photos: photos,
											toUpload: toUpload
										},
										}, async () => {
											await emit(this.props.eventSubmit, 
											{toUpload: this.state.form.toUpload, toRemove: this.state.form.toRemove})
											this.forceUpdate()
										});			
								} } />
						</div>
						<div className="col-md-6">
							<Button disabled={submit}>
								Guardar
							</Button>
						</div>
					</div>
				</div>
			</form>
		)
	}
}

export default VehiclePhotos;