import React from 'react';
import { Textarea, Button } from 'components'
import { AboutService } from 'services'
import { Globals } from 'utils'

class EditAbout extends React.Component<any> {

	state = {
		form: {
			text: ''
		}
	}

	componentDidMount() {
		this.load()
	}

	load = () => {
		this.setState({
			form: {
				text: this.props.item.element.text
			}
		});
	}

	submit = async () => {
		this.props.onClose({
			element: {
				...this.props.item.element,
				text: this.state.form.text
			},
			index: this.props.item.index,
			type: this.props.item.type
		});
	}

	change = (e: any) => {
		e.preventDefault();
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render() {
		return (
			<React.Fragment>
				<Textarea
                    label={ this.props.lang }
                    name="text"
                    value={ this.state.form.text }
                    onChange={ this.change }
                />
				<div className="text-center">
					<Button onClick={ this.submit }>
						Guardar
					</Button>
				</div>
			</React.Fragment>
		)
	}
}

export default EditAbout;