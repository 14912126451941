import { axios } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { User, UserForm } from "models";

export interface Users extends User {
  current_page: number;
  last_page: number;
  data: User[];
}

type Source = {
  token: CancelToken;
};

class UserService {
  static get = (source: Source) => {
    return new Promise<User[]>((resolve, reject) => {
      axios
        .get(`users`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<User[]>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static getByPage = (page: number, source: Source) => {
    return new Promise<Users>((resolve, reject) => {
      axios
        .get(`users?paginated=true&page=${page}`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<Users>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static store = (userForm: UserForm, source: Source) => {
    return new Promise<User>((resolve, reject) => {
      axios.post("users", userForm).then(
        (response: AxiosResponse<User>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static update = (id: number, user: UserForm) => {
    return new Promise<User>((resolve, reject) => {
      axios.put(`users/${id}`, user).then(
        (response: AxiosResponse<User>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static destroy = (id: number | null) => {
    return new Promise<boolean>((resolve, reject) => {
      axios.delete(`users/${id}`).then(
        (response: AxiosResponse<boolean>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default UserService;
