import React from "react";
import { useSelector } from "react-redux";
import { Banner } from "models/bannerModel";
import { BannerBg, BannerCar } from "assets/img";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

interface Props {
  type: number;
  title?: string;
  bg?: string;
  image?: string;
  hideImage?: boolean;
  align?: "left" | "center" | "right";
  styles?: any;
  offset?: number;
}

const BannerContainer = (props: Props) => {
  const banners = useSelector((state: { banners: Banner[] }) => state.banners);
  const banner =
    banners &&
    banners.find((item: Banner) => {
      return item.language.name == i18next.language && item.type == props.type;
    });
  const { t } = useTranslation();

  return (
    <div
      className="banner-container"
      style={{
        backgroundImage: `url(${props.bg || BannerBg})`,
        ...props.styles,
      }}
    >
      <div className="row banner">
        <div className={ `col-md-6 offset-md-${ props.offset || 0 }` }>
          <div className="text-container">
            <h3
              style={{
                textAlign: props.align || "center",
              }}
            >
              {props.title || t("inventory.Banner")}
            </h3>
            {banner && <p>{banner.text}</p>}
          </div>
        </div>
        {!props.hideImage && (
          <div className="col-md-6">
            <img src={props.image || BannerCar} className="img-fluid" />
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerContainer;
