import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Button, CardCustom } from "components";
import { VehicleService } from "services";
import { handlerError } from "functions";
import { Globals } from "utils";

type Props = {
  vehicle?: any;
  onSubmit?: () => void,
  gallery?: string
};

const DataSheetVehicle = (props: Props) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [submitted, setSubmitted] = useState(false);
  const cancelToken = axios.CancelToken;

  const showValidation = (nameField: string): void => {
    return Globals.showError(nameField);
  };

  const submit = (event: any) => {
    event.preventDefault();

    const fullName = firstName + " " + lastName;

    const form = {
      fullName: fullName,
      email,
      phone,
      vehicle: props.vehicle,
      url: window.location.href,
      gallery: props.gallery
    };

    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if (!firstName) {
      return showValidation(t("vehicle.RequireFirstName"));
    } else if (email && !emailRegex.test(email)) {
      return showValidation(t("vehicle.RequireEmailFormat"));
    }

    const source = cancelToken.source();

    setSubmitted(true);

    VehicleService.dataSheet(form, source)
      .then(() => {
        Globals.showSuccess(t("vehicle.SendData"));
        setFirstName("");
        setlastName("");
        setEmail("");
        setPhone("");
        if (props.onSubmit)
          props.onSubmit();
      })
      .catch(handlerError)
      .finally(() => setSubmitted(false));
  };

  return (
    <CardCustom className="shadow-sm">
      <CardCustom.Header className="py-1 px-3">
        <CardCustom.Title className="py-1">
          {t("vehicle.TitleForm")}
        </CardCustom.Title>
      </CardCustom.Header>
      <CardCustom.Body className="p-3">
        <form onSubmit={submit} noValidate autoComplete="off">
          <div className="row">
            <div className="col col-sm col-md">
              <div className="form-group">
                <input
                  required
                  type="text"
                  color="gray"
                  name="firstName"
                  placeholder={t("vehicle.FormLabelFirstName")}
                  className="form-control"
                  maxLength={40}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                  value={firstName}
                />
              </div>
            </div>
            <div className="col col-sm col-md">
              <div className="form-group">
                <input
                  required
                  type="text"
                  color="gray"
                  name="lastName"
                  placeholder={t("vehicle.FormLabelLastName")}
                  className="form-control"
                  maxLength={40}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setlastName(event.target.value);
                  }}
                  value={lastName}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-sm col-md">
              <div className="form-group">
                <input
                  type="email"
                  color="gray"
                  name="email"
                  placeholder={t("vehicle.FormLabelEmail")}
                  className="form-control"
                  maxLength={30}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                  }}
                  value={email}
                />
              </div>
            </div>
            <div className="col col-sm col-md">
              <div className="form-group">
                <input
                  required
                  type="number"
                  color="gray"
                  name="phone"
                  placeholder={t("vehicle.FormLabelPhone")}
                  className="form-control"
                  maxLength={12}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPhone(event.target.value);
                  }}
                  value={phone}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 text-center">
            <div className="col col-sm">
              <Button
                className="font-bold text-center button-rounded"
                color="primary"
                outline
                submitted={submitted}
                type="submit"
              >
                {t("vehicle.Submitted")}
              </Button>
            </div>
          </div>
        </form>
      </CardCustom.Body>
    </CardCustom>
  );
};

export default DataSheetVehicle;
