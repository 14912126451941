import React from "react";

interface InputProps {
  required?: boolean;
  label?: string | undefined;
  name?: string;
  type?: "text" | "number" | "password" | "email";
  color?: string | "";
  placeholder?: string | "";
  maxlength?: number;
  value?: string | number | "";
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({
  required = false,
  label,
  name,
  type = "text",
  color,
  placeholder,
  value,
  maxlength,
  onChange
}) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name}>
          {label} {required ? <span className="text-danger">(*)</span> : ""}
        </label>
      )}
      <input
        // { ...props }
        maxLength={maxlength}
        placeholder={placeholder}
        type={type}
        className={`form-control ${color ? `input-${color}` : "input-white"}`}
        name={name}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Input;

// const Input	= (props: any) => (
// 	<div className="form-group">
// 		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
// 		<input
// 			{ ...props }
// 			type={ props.type ? props.type : 'text' }
// 			className={ `form-control ${ props.color ? 'input-'+props.color : 'input-white' }` }
// 			name={ props.name } />
// 	</div>
// )
