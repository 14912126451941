import React from "react";
import {
  Route,
  RouteComponentProps,
  RouteProps,
  Redirect
} from "react-router-dom";
import { RootState } from "reducers";
import { UserState } from "actions";
import { ReactNode } from "react";
import { connect } from "react-redux";

// Components
import LayoutAdmin from "screens/admin/layout/LayoutAdmin";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const mapState = (state: RootState) => ({
  user: state.user
});

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

class PrivateRoute extends Route<
  PrivateRouteProps & {
    user: UserState;
  }
> {
  render(): ReactNode {
    const { component: Component, ...rest }: PrivateRouteProps = this.props;
    const { user: isAuthenticated } = this.props;

    const renderComponent: RenderComponent = props =>
        !isAuthenticated ?
          <Redirect to="/login" /> :
          <LayoutAdmin>
            <Component {...props} />
          </LayoutAdmin>

    return <Route {...rest} render={renderComponent} />;
  }
}

export default connect(mapState)(PrivateRoute);
