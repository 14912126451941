import { axios } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Service, FormService } from "models";

export interface Services extends Service {
  current_page: number;
  last_page: number;
  data: Service[];
}

class ServicesService {
  static index = (page: number, source: { token: CancelToken }) => {
    return new Promise<Services>((resolve, reject) => {
      axios.get(`services?page=${page}`).then(
        (response: AxiosResponse<Services>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static getByArea = (
    page: number,
    areaId: string,
    source: { token: CancelToken }
  ) => {
    return new Promise<Services>((resolve, reject) => {
      axios.get(`services/${areaId}/area?page=${page}`).then(
        (response: AxiosResponse<Services>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static getById = (id: number, source: { token: CancelToken }) => {
    return new Promise<Service>((resolve, reject) => {
      axios
        .get(`services/${id}/edit`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<Service>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static store = (service: FormService) => {
    return new Promise<Service>((resolve, reject) => {
      axios.post("services", service).then(
        (response: AxiosResponse<Service>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static update = (service: FormService) => {
    return new Promise<Service>((resolve, reject) => {
      axios.put(`services/${service.id}`, service).then(
        (response: AxiosResponse<Service>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static destroy = (id: number | null) => {
    return new Promise<Service>((resolve, reject) => {
      axios.delete(`services/${id}`).then(
        (response: AxiosResponse<Service>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default ServicesService;
