import { ModulesAction, ModulesState } from "actions";
import { Reducer } from "redux";

export const modules: Reducer<ModulesState, ModulesAction> = (
  state = [],
  action
) => {
  switch (action.type) {
    case "Modules/SET":
      return action.modules;
    case "Modules/REMOVE":
      return [];
    default:
      return state;
  }
};
