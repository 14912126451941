import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";
import { Globals } from "utils";

class PriceService {
  static getBrand = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.get(`brands/get?brand=${params.brand}`).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };

  static visitByBrand = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`brands/visit`, params).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };

  static visitByModel = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`brands/visitModel`, params).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };
  static visitVehicle = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`brands/visitVehicle`, params).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };
  static visit = () => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`visit/register`).then(
        (response: AxiosResponse<any>) => resolve(response?.data),
        (error: AxiosError) => {
          Globals.quitLoading();
          reject(error);
        }
      );
    });
  };
}

export default PriceService;
