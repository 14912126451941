import { axios, Globals, Error, Upload } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Vehicle, DataSheetVehicle } from "models";

type Source = {
  token: CancelToken;
};

class VehicleService {
  static getBySlug = (slug: string) => {
    return new Promise<Vehicle>((resolve, reject) => {
      axios.get(`vehicles/${slug}`).then(
        (response: AxiosResponse<Vehicle>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static dataSheet = (form: DataSheetVehicle, source: Source) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`vehicles/data-sheet`, form, {
          cancelToken: source.token,
        })
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static create = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios.post(`vehicles/create`, params)
      .then((response: AxiosResponse<any>) => {
        resolve(response?.data);
      })
      .catch((err: AxiosError) => {
        Error.default(err);
        reject(err);
      })
      .finally(Globals.quitLoading);
    });
  };

  static get = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`vehicles/get`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static paginate = (page: number, params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`vehicles/get?page=${page}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static edit = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`vehicles/edit`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static delete = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`vehicles/delete`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static publish = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`vehicles/publish`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static removePhotos = (params: any, slug: string) => {
    return new Promise<any>((resolve, reject) => {
      const _axios = params.hasFile ? Upload : axios.post;
      _axios(`vehicles/remove-photos/${slug}`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };

  static massPublish = (params: any) => {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`vehicles/mass-publish`, params)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          Error.default(err);
          reject(err);
        })
        .finally(Globals.quitLoading);
    });
  };
}

export default VehicleService;
