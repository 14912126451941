import React, { useEffect } from "react";
import Layout from "../layout/layout";
import Banner from "../inventory/banner";
import { BannerType } from "models/bannerModel";
import { withTranslation, WithTranslation } from "react-i18next";
import { Contact as ContactBanner } from "assets/img";
import Copyright from "../layout/copyright";
import { connect } from "react-redux";
import { RootState } from "reducers";
import {
  FacebookRed,
  InstagramRed,
  TwitterRed,
  YoutubeRed,
  Location,
  Phone,
  Email,
} from "assets/icons";
import { ENV, Globals } from "utils";
import i18next from "i18next";
import Loading from "../inventory/loading";
import { ScheduleService, ContactService } from "services";
import { Schedule } from "models/scheduleModel";
import moment from "moment";

interface Props extends WithTranslation, RootState {
  dispatch: any;
}

class Contact extends React.Component<Props> {
  state = {
    loading: true,
    schedule: [],
    submitted: false,
    form: {
      name: "",
      lastname: "",
      email: "",
      message: "",
      phone: "",
    },
  };

  componentDidMount() {
    if (this.props.schedule) {
      this.setState({
        loading: false,
        schedule: this.props.schedule,
      });
    }
    this.load();
  }

  load = async () => {
    const res: any = await ScheduleService.get();
    this.setState({
      loading: false,
      schedule: res.schedule,
    });
    this.props.dispatch({
      type: "SET_SCHEDULE",
      payload: res.schedule,
    });
  };

  submit = async (e: any) => {
    e.preventDefault();
    this.setState({ submitted: true });

    const { t } = this.props;

    await ContactService.send({
      ...this.state.form,
      phone: parseInt(this.state.form.phone),
      lang: i18next.language,
    })
      .then(() => {
        Globals.showSuccess(t("contact.SendData"));

        this.setState({
          form: {
            name: "",
            lastname: "",
            email: "",
            message: "",
            phone: "",
          },
        });
      })
      .finally(() => {
        this.setState({
          submitted: false,
        });
      });
  };

  change = (e: any) => {
    e.preventDefault();
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { t, contact } = this.props;
    const {
      schedule,
      loading,
      submitted,
      form: { name, lastname, phone, email, message },
    } = this.state;

    return (
      <Layout showFooter={false}>
        <Banner
          bg={ContactBanner}
          hideImage={true}
          offset={3}
          align="left"
          title={t("contact.BannerTitle")}
          type={BannerType.CONTACT}
          styles={{
            marginBottom: "0px",
          }}
        />
        <div className="wrapper-contain" id="contact">
          <h3>{t("contact.Title")}</h3>
          <div className="row">
            <div className="col-lg-5">
              <form onSubmit={this.submit}>
                <div className="form-group">
                  <input
                    onChange={this.change}
                    name="name"
                    className="form-control"
                    type="text"
                    value={name}
                    placeholder={t("contact.Name")}
                  />
                </div>
                <div className="form-group">
                  <input
                    onChange={this.change}
                    name="lastname"
                    className="form-control"
                    type="text"
                    value={lastname}
                    placeholder={t("contact.Lastname")}
                  />
                </div>
                <div className="form-group">
                  <input
                    onChange={this.change}
                    name="phone"
                    className="form-control"
                    type="number"
                    value={phone}
                    placeholder={t("contact.Phone")}
                  />
                </div>
                <div className="form-group">
                  <input
                    onChange={this.change}
                    name="email"
                    className="form-control"
                    type="text"
                    value={email}
                    placeholder={t("contact.Email")}
                  />
                </div>
                <div className="form-group">
                  <textarea
                    onChange={this.change}
                    name="message"
                    value={message}
                    className="form-control"
                    placeholder={t("contact.Message")}
                  ></textarea>
                </div>
                {submitted ? (
                  <Loading />
                ) : (
                  <div className="text-center">
                    <button className="btn btn-default">
                      {t("contact.Submit")}
                    </button>
                  </div>
                )}
              </form>
              {loading ? (
                <Loading />
              ) : (
                <div className="table-hours">
                  <div className="table-hours-header">
                    <p>{t("contact.Hours")}</p>
                  </div>
                  <div className="table-hours-body">
                    {schedule.map((i: Schedule, index: number) => (
                      <div className="item-table-hours" key={index}>
                        <p className="capitalize">{moment.weekdays(i.day)}</p>
                        {i.open ? (
                          <p>
                            {moment(i.start, "HH:mm").format("h:mm A")} -{" "}
                            {moment(i.end, "HH:mm").format("h:mm A")}
                          </p>
                        ) : (
                          <p>{t("contact.Closed")}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-7">
              <div className="table-hours">
                <div className="table-hours-header">
                  <p className="text-uppercase">
                    {ENV.NAME + " " + t("contact.Information")}
                  </p>
                </div>
                <div className="table-hours-body table-information">
                  <h4>{t("contact.General")}</h4>
                  <a
                    href={`tel:${contact.phone}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                  <p>
                    <img src={Phone} /> {contact.phone}
                  </p>
                </a>
                  <a
                    href={`mailto:${contact.email}`}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <p>
                      <img src={Email} /> {contact.email}
                    </p>
                  </a>
                  <p>
                    <img src={Location} />{" "}
                    {i18next.language == "es"
                      ? contact.address
                      : contact.address_en}
                  </p>
                </div>
              </div>
              <div className="sociales">
                <a target="_blank" href={contact.twitter}>
                  <img src={TwitterRed} />
                </a>
                <a target="_blank" href={contact.youtube}>
                  <img src={YoutubeRed} />
                </a>
                <a target="_blank" href={contact.instagram}>
                  <img src={InstagramRed} />
                </a>
                <a target="_blank" href={contact.facebook}>
                  <img src={FacebookRed} />
                </a>
              </div>
              <div className="container-google-maps">
                {/* <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.262485048239!2d-80.35478858565963!3d25.794913113522043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b9465aab07cf%3A0xf48abd281162ce5a!2s2315%20NW%2097th%20Ave%2C%20Doral%2C%20FL%2033172%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sve!4v1588192996188!5m2!1ses-419!2sve"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen={false}
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>*/}
              </div>
            </div>
          </div>
          <Copyright />
        </div>
      </Layout>
    );
  }
}

export default withTranslation()(
  connect((state: RootState) => {
    return {
      contact: state.contact,
      schedule: state.schedule,
    };
  })(Contact)
);
