import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { VehicleService, BrandService } from "services";
import { prop, handlerError, formatMoney } from "functions";
import { Loading } from "components";
import { useTypedSelector } from "reducers";
import { Vehicle, Photo } from "models";
import {
  Tachometer,
  Right,
  Left,
  RightVehicle,
  LeftVehicle,
} from "assets/icons";
// Component
import Layout from "../layout/layout";
import DataSheetVehicle from "./DataSheetVehicle";
import { Constants, Globals } from "utils";
import VehicleContainer from "../home/vehicle-container";
import { Button } from "components";
import { useHistory } from "react-router-dom";
import { Viewer } from "components";

const { REACT_APP_BASE_API: baseURL } = process.env;

const ViewVehicle = () => {
  const { brand, slug } = useParams();
  const { t } = useTranslation();
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [vehiclesByBrand, setVehicleByBrand] = useState<Vehicle[]>([]);
  const [vehiclePhoto, setVehiclePhoto] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [carouselItem, setCarouselItem] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState<any>([]);
  const history = useHistory();

  const vehicles = useTypedSelector(prop("vehicles"));

  useEffect(() => {
    if (!brand || !slug) {
      return;
    }

    getVehicleRedux(brand, slug, true);

    window.scrollTo(0, 1);
  }, [brand, slug]);

  useEffect(() => {
    if (!brand || !vehicle) {
      return;
    }

    if (vehiclesByBrand.length === 0 && vehicle) {
      getVehiclesByBrand(brand);
    }
  }, [brand, vehicle]);

  const getVehicleRedux = async (
    brand: string,
    slug: string,
    withLoading: boolean = false
  ) => {
    setLoading(withLoading);

    const vehiclesByBrand = await getVehiclesByBrandRedux(brand);

    const vehicleBySlug = vehiclesByBrand
      ? vehiclesByBrand.find(({ slug: slugVehicle }) => slugVehicle === slug)
      : null;

    if (vehiclesByBrand.length === 0 && vehicle) {
      getVehiclesByBrand(brand);
    }

    if (!vehiclesByBrand || !vehicleBySlug) {
      return VehicleService.getBySlug(slug)
        .then((vehicle: Vehicle) => {
          setVehicle(vehicle);
          setVehiclePhoto(vehicle?.photos[0]?.photo);
        })
        .catch(handlerError)
        .finally(() => setLoading(false));
    }

    setVehicleByBrand(filterVehicles(vehiclesByBrand));
    setVehicle(vehicleBySlug);
    setVehiclePhoto(vehicleBySlug?.photos[0]?.photo);
    setLoading(false);
  };

  const getNameVehicle = (): string => {
    return `${vehicle?.year || ""} ${brand} ${vehicle?.model?.name || ""}`;
  };

  const getCurrentPhoto = (): string => {
    return baseURL + `${vehiclePhoto}`;
  };

  const vehicleName: string = getNameVehicle();

  const filterVehicles = (vehicles: Vehicle[]): Vehicle[] => {
    return vehicles.filter(({ status }) => status === Constants.STATUS_ACTIVE);
  };

  const getVehiclesByBrandRedux = (brand: string) => {
    const vehiclesByBrand: Vehicle[] = vehicles[brand] || [];

    return new Promise<Vehicle[]>((resolve, _) => {
      resolve(vehiclesByBrand);
    });
  };

  const getVehiclesByBrand = async (brand: string) => {
    const vehiclesBrand: Vehicle[] = vehicles[brand] || [];

    if (vehiclesBrand.length === 0 && vehicle) {
      const getBrandAsync = await BrandService.getById(vehicle.brand.id);
      setVehicleByBrand(filterVehicles(getBrandAsync.vehicles));
    }
  };

  const getFormatMoney = (amount: number): string => {
    return "$" + formatMoney(amount);
  };

  const prev = (vehicle: Vehicle) => {
    if (carouselItem > 0) {
      const item = carouselItem - 1;
      setCarouselItem(item);
      setVehiclePhoto(vehicle.photos[item].photo);
      setTimeout(() => {
        // $('.owl-carousel').trigger('prev.owl.carousel');
        $(".owl-carousel").trigger("to.owl.carousel", [item, 500]);
      }, 100);
    }
  };

  const next = (vehicle: Vehicle) => {
    if (carouselItem < vehicle.photos.length - 1) {
      const item = carouselItem + 1;
      setCarouselItem(item);
      setVehiclePhoto(vehicle.photos[item].photo);
      setTimeout(() => {
        // $('.owl-carousel').trigger('next.owl.carousel');
        $(".owl-carousel").trigger("to.owl.carousel", [item, 500]);
      }, 100);
    }
  };

  const nextCarousel = () => {
    setTimeout(() => {
      $(".owl-carousel").trigger("next.owl.carousel");
    }, 100);
  };

  const prevCarousel = () => {
    setTimeout(() => {
      $(".owl-carousel").trigger("prev.owl.carousel");
    }, 100);
  };

  const viewPhoto = () => {
    setImages(
      vehicle?.photos.map((i: { photo: string }) => {
        return {
          src: baseURL + `${i.photo}`,
        };
      })
    );
    setVisible(true);
  };

  return (
    <Layout bgColor="bg-gray">
      <div
        className="wrapper-contain"
        style={{
          padding: "0px",
        }}
      >
        <Button onClick={() => history.goBack()} className="btn-back-vehicle">
          <img src={Left} />
          {t("vehicle.Back")}
        </Button>
      </div>

      <Viewer
        visible={visible}
        onClose={() => setVisible(false)}
        images={images}
        activeIndex={carouselItem}
      />

      {isLoading ? (
        <Loading />
      ) : (
        <div className="view-vehicle-container">
          {vehicle ? (
            <>
              <div className="row wrapper-contain" id="init">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-0 text-center">
                  <picture className="image-container">
                    <button
                      disabled={carouselItem <= 0}
                      className="btn btn-default btn-left"
                      onClick={() => prev(vehicle)}
                    >
                      <img src={LeftVehicle} />
                    </button>
                    <img
                      onClick={viewPhoto}
                      src={getCurrentPhoto()}
                      className="img-fluid img-thumbnail"
                      alt={vehicleName}
                      style={{
                        padding: 0,
                        borderRadius: 0,
                        cursor: "pointer",
                      }}
                    />
                    <button
                      disabled={carouselItem >= vehicle.photos.length - 1}
                      className="btn btn-default btn-right"
                      onClick={() => next(vehicle)}
                    >
                      <img src={RightVehicle} />
                    </button>
                  </picture>
                  <div className="row justify-content-center mx-0 my-3">
                    <div className="col px-0 thumbs">
                      {vehicle.photos && (
                        <div className="container-carousel-photos">
                          <button
                            className="btn btn-default btn-left"
                            onClick={() => prevCarousel()}
                          >
                            <img src={LeftVehicle} />
                          </button>
                          <RCCSlider
                            photos={vehicle.photos}
                            onClick={(url: string, index: number) => {
                              setCarouselItem(index);
                              setVehiclePhoto(url);
                            }}
                          />
                          <button
                            className="btn btn-default btn-right"
                            onClick={() => nextCarousel()}
                          >
                            <img src={RightVehicle} />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-0">
                  <div className="row">
                    <div className="col">
                      <h3 className="font-weight-bold text-uppercase">
                        {vehicleName}
                      </h3>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2">
                      <button className="btn btn-yellow font-weight-bold btn-price">
                        {!vehicle.priceOriginal
                          ? t("vehicle.CallPrice")
                          : getFormatMoney(vehicle.price)}
                      </button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2">
                      <p className="text-blacktext-md-center text-lg-center text-xl-center font-weight-bold">
                        <img
                          src={Tachometer}
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "top",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {Globals.formatNumber(
                          vehicle.mileage ? vehicle.mileage : 0
                        )}{" "}
                        <small>mi</small>
                      </p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 my-2">
                      <p className="text-black text-md-center text-lg-center text-xl-center font-weight-bold">
                        {t("vehicle.StockNumber")}: {vehicle.stockNumber}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <p
                        className="description-vehicle"
                        style={{ lineHeight: "2rem" }}
                      >
                        <span className="font-weight-bold">
                          {t("vehicle.Brand")}:
                        </span>
                        <span className="brand text-uppercase"> {brand} </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Model")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.model?.name}{" "}
                        </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Year")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.year}{" "}
                        </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Transmission")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.transmission}{" "}
                        </span>
                        {vehicle.fuelType && (
                          <>
                            <span className="font-weight-bold">
                              {t("vehicle.FuelType")}:
                            </span>
                            <span className="brand text-uppercase">
                              {" "}
                              {vehicle.fuelType}{" "}
                            </span>
                          </>
                        )}
                        <span className="font-weight-bold">
                          {t("vehicle.Engine")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.engine}{" "}
                        </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Interior")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.interior}{" "}
                        </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Exterior")}:
                        </span>
                        <span className="brand text-uppercase">
                          {" "}
                          {vehicle.exterior}{" "}
                        </span>
                        <span className="font-weight-bold">
                          {t("vehicle.Package")}:
                        </span>
                        <span className="text-uppercase">
                          {" "}
                          {vehicle.package}{" "}
                        </span>
                      </p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="card bg-white">
                        <DataSheetVehicle vehicle={vehicle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 bg-white">
                <section className="row justify-content-center wrapper-contain">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 specs-details">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-gray specs-details-title">
                      <h5 className="font text-uppercase font-weight-bold mb-0 ml-3 py-3">
                        {t("vehicle.VehicleDetails")}
                      </h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 details-section">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
                        <span className="list-style-rcc my-2">
                          {vehicle?.vehicleDetails}
                        </span>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
                        <span className="list-style-rcc my-2">
                          {vehicle?.location}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 specs-details">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 bg-gray specs-details-title">
                      <h5 className="font text-uppercase font-weight-bold mb-0 ml-3 py-3">
                        {t("vehicle.Specifications")}
                      </h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 details-section">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <RCCSpecsColumnFirst
                            vehicle={vehicle}
                            translate={t}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <RCCSpecsColumnSecond
                            vehicle={vehicle}
                            translate={t}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          ) : (
            <div className="row text-center my-3">
              <div className="col">
                <h1>{t("vehicle.NotFound")}</h1>
              </div>
            </div>
          )}
          {vehiclesByBrand.length > 0 && vehicle && (
            <div className="row wrapper-contain pb-5">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                <h3 className="text-center font text-uppercase font-weight-bold my-3">
                  {t("vehicle.SimilaryVehicles")}
                </h3>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-3 px-0">
                <RCCBrand vehicles={vehiclesByBrand} />
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

const getUrlPhoto = (urlPhoto: string): string => {
  return baseURL + urlPhoto;
};

const RCCBrand = ({ vehicles }: { vehicles: Vehicle[] }) => {
  return (
    <>
      <div className="row justify-content-md-center justify-content-start">
        {vehicles.slice(0, 6).map((vehicle: Vehicle) => (
          <div
            className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 px-0 my-2"
            key={vehicle.id.toString()}
          >
            <div className="item item-vehicle">
              <VehicleContainer vehicle={vehicle} />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const RCCSlider = ({
  photos,
  onClick,
}: {
  photos: Photo[];
  onClick: (url: string, index: number) => void;
}) => {
  const mapPhotos = photos.filter(({ photo }) => photo !== null);
  const startPosition = React.useRef(0);

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        autoplay={false}
        margin={10}
        dots={false}
        lazyLoad={true}
        onTranslated={(object: any) => {
          if (object.item.index != startPosition.current) {
            startPosition.current = object.item.index;
          }
        }}
        startPosition={startPosition.current}
        navText={[`<img src="${Left}" />`, `<img src="${Right}" />`]}
        responsive={{
          0: {
            items: 6,
          },
          300: {
            items: 5,
          },
          600: {
            items: 5,
          },
          1200: {
            items: 5,
          },
        }}
        autoplayTimeout={2500}
      >
        {mapPhotos.map((photo: Photo, index: number) => (
          <div className="item item-vehicle" key={photo.id.toString()}>
            <img
              src={getUrlPhoto(photo.photo)}
              onClick={() => onClick(photo.photo, index)}
              style={{ cursor: "pointer" }}
            />
          </div>
        ))}
      </OwlCarousel>
    </>
  );
};

const RCCSpecsColumnFirst = ({
  vehicle,
  translate,
}: {
  vehicle: Vehicle;
  translate: (text: string) => void;
}) => {
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Year")} {vehicle?.year}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Brand")} {vehicle?.brand?.name}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Model")} {vehicle?.model?.name}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Package")} {vehicle?.package}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Body")} {vehicle?.body}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Style")} {vehicle?.style}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Drivetrain")} {vehicle?.drivetrain}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Doors")} {vehicle?.doors}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Engine")} {vehicle?.engine}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.FuelType")} {vehicle?.fuelType}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Horsepower")} {vehicle?.horsepower}
        </span>
      </div>
    </>
  );
};

const RCCSpecsColumnSecond = ({
  vehicle,
  translate,
}: {
  vehicle: Vehicle;
  translate: (text: string) => void;
}) => {
  return (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.TorqueRating")} {vehicle?.torqueRating}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.EngineCylinders")} {vehicle?.engineCylinders}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.EngineDisplacement")}{" "}
          {vehicle?.engineDisplacement}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Transmission")} {vehicle?.transmission}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Interior")} {vehicle?.interior}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Exterior")} {vehicle?.exterior}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.FuelTank")} {vehicle?.fuelTank}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.TitleStatus")} {vehicle?.titleStatus}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.Mileage")} {vehicle?.mileage}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.DamageType")} {vehicle?.damageType}
        </span>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 my-2">
        <span className="list-style-rcc">
          {translate("vehicle.AirbagStatus")} {vehicle?.airbagStatus}
        </span>
      </div>
    </>
  );
};

export default ViewVehicle;
