import React from 'react';
import { Input, Select, Button, Textarea } from 'components'
import  { BrandService, ModelService } from 'services'
import { Constants } from 'utils';
import { on, emit } from 'jetemit';

const type = "data";

class VehicleData extends React.Component<any>{

	state = {
		submit: false,
		form:{
			price: 0,
			year: 0,
			mileage: '',
			body: '',
			// bodySpanish: '',
			style: '',
			styleSpanish: '',
			exterior: '',
			exteriorSpanish: '',
			interior: '',
			interiorSpanish: '',
			package: '',
			packageSpanish: '',
			stockNumber: '',
			engine: '',
			horsepower: '',
			torqueRating: '',
			engineCylinders: '',
			engineDisplacement: '',
			drivetrain: '',
			doors: '',
			transmission: '',
			fuelType: '',
			fuelTypeSpanish: '',
			fuelTank: '',
			mpgCity: '',
			mpgHwy: '',
			damageType: '',
			damageTypeSpanish: '',
			titleStatus: '',
			titleStatusSpanish: '',
			vehicleDetails: '',
			vehicleDetailsSpanish: '',
			airbagStatus: '',
			airbagStatusSpanish: '',
			location: '',
			locationSpanish: '',
			status: 0,
			brand: '',
			model: ''
		},
		brands: [],
		models: [],
		translate: {
			price: 'Precio',
			year: 'Año',
			mileage: 'Millas',
			body: 'Carroceria',
			// bodySpanish: 'Carroceria - Español',
			style: 'Estilo',
			styleSpanish: 'Estilo - Español',
			exterior: 'Exterior',
			exteriorSpanish: 'Exterior - Español',
			interior: 'Interior',
			interiorSpanish: 'Interior - Español',
			package: 'Paquete',
			packageSpanish: 'Paquete - Español',
			stockNumber: 'Numero de Stock',
			engine: 'Motor',
			transmission: 'Transmission',
			horsepower: 'Caballos de Fuerza',
			torqueRating: 'Torque',
			engineCylinders: 'Cilindros del Motor',
			engineDisplacement: 'Desplazamiento del Motor',
			drivetrain: 'Tracción',
			doors: 'Puertas',
			fuelType: 'Tipo de Combustible',
			fuelTypeSpanish: 'Tipo de Combustible - Español',
			fuelTank: 'Tanque de Combustible',
			mpgCity: 'MPG Ciudad',
			mpgHwy: 'MPG Autopista',
			status: 'Estado',
			damageType: 'Tipo de Daño',
			damageTypeSpanish: 'Tipo de Daño - Español',
			titleStatus: 'Titulo del Estado',
			titleStatusSpanish: 'Titulo del Estado - Español',
			vehicleDetails: 'Detalles del Vehiculo',
			vehicleDetailsSpanish: 'Detalles del Vehiculo - Español',
			airbagStatus: 'Estado de las bolsas de aire',
			airbagStatusSpanish: 'Estado de las bolsas de aire - Español',
			location: 'Ubicacion',
			locationSpanish: 'Ubicacion - Español',
			brand: 'Marca',
			model: 'Modelo'
		},
		subscriber: () => {},
		subscriberError: () => {}
	}

	componentDidMount() {
		this.load();
		this.setState({
			subscriber: on(this.props.eventSubmit, async (data:any) => {
				await this.spreadPhotosData(data)
				if(data.save){
					const { form, models, brands } = this.state;
					this.props.onSubmit(form, models, brands)
				}
			}),
			subscriberError: on(this.props.eventErrorSubmit, (data:string) => {
				if(data === type)
					this.resetButton()
			})
		});		
	}

	componentWillUnmount() {
		this.state.subscriber();
		this.state.subscriberError();
	}

	loadModels(brand:number) {
		ModelService.get({brandId: brand, orderBy: 'name', orderByType: 'ASC'}).then((response:any) => {
			const { models } = response;
			const modelsFormat:any = models.map((model:any) => {
				return {
					label: model.name,
					value: model.id
				}
			})
			this.setState((state:any) => ({
				form: {
				  ...state.form
				},
				models: modelsFormat
			  }));
		})
	}

	loadBrands() {
		BrandService.get({orderBy: 'name', orderByType: 'ASC'}).then((response:any) => {
			const { brands } = response;
			const brandsFormat:any = brands.map((model:any) => {
				return {
					label: model.name,
					value: model.id
				}
			})
			this.setState((state:any) => ({
				form: {
				  ...state.form
				},
				brands: brandsFormat
			  }));
		})
	}

	loadData() {
		const { vehicle } = this.props;
		this.setState({
			form:{
				id: vehicle.id,
				slug: vehicle.slug,
				body: vehicle.body,
				// bodySpanish: vehicle.bodySpanish,
				style: vehicle.style,
				styleSpanish: vehicle.styleSpanish,
				price: vehicle.price,
				year: vehicle.year,
				mileage: vehicle.mileage,
				exterior: vehicle.exterior,
				exteriorSpanish: vehicle.exteriorSpanish,
				interior: vehicle.interior,
				interiorSpanish: vehicle.interiorSpanish,
				package: vehicle.package,
				packageSpanish: vehicle.packageSpanish,
				stockNumber: vehicle.stockNumber,
				engine: vehicle.engine,
				transmission: vehicle.transmission,
				horsepower: vehicle.horsepower,
				torqueRating: vehicle.torqueRating,
				engineCylinders: vehicle.engineCylinders,
				engineDisplacement: vehicle.engineDisplacement,
				drivetrain: vehicle.drivetrain,
				doors: vehicle.doors,
				fuelType: vehicle.fuelType,
				fuelTypeSpanish: vehicle.fuelTypeSpanish,
				fuelTank: vehicle.fuelTank,
				mpgCity: vehicle.mpgCity,
				mpgHwy: vehicle.mpgHwy,
				status: vehicle.status,
				damageType: vehicle.damageType,
				damageTypeSpanish: vehicle.damageTypeSpanish,
				titleStatus: vehicle.titleStatus,
				titleStatusSpanish: vehicle.titleStatusSpanish,
				vehicleDetails: vehicle.vehicleDetails,
				vehicleDetailsSpanish: vehicle.vehicleDetailsSpanish,
				airbagStatus: vehicle.airbagStatus,
				airbagStatusSpanish: vehicle.airbagStatusSpanish,
				location: vehicle.location,
				locationSpanish: vehicle.locationSpanish,
				brand: vehicle.brand?.id,
				model: vehicle.model?.id
			}
		})
	}

	load = async () => {
		const { vehicle } = this.props;
		await this.loadBrands();
		if(vehicle){
			await this.loadModels(parseFloat(vehicle.brand?.id));
			await this.loadData();
		}
	}

	change = (e: any) => {
		const {name, value} = e.target
		this.setState({
			form: {
				...this.state.form,
				[name]: value
			}
		}, async () => {
			if(name === 'brand') {
				await this.loadModels(parseFloat(value));
			}
			emit(this.props.eventSubmit, {...this.state.form, type: type})
		});
	}

	spreadPhotosData = (data:any) => {
		this.setState({
			form: {
				...this.state.form,
				photos: {
					...data
				}
			}
		})
	}

	resetButton = () => {
		this.setState({
			submit: false
		})
	}

	render(){
		const { brands, form, models, submit } = this.state;
		return(
			<form onSubmit={(e) => {
				e.preventDefault()
				this.setState({
					submit: true
				})
				this.props.onSubmit(form, models, brands)
			}}>
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-3">
							<Select
								onChange={this.change}
								name="brand"
								label="Marca"
								color="gray"
								options={brands}
								value={form.brand}
							/>
						</div>
						<div className="col-md-3">
							<Select
								onChange={this.change}
								name="model"
								label="Modelo"
								color="gray"
								options={models}
								value={form.model}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="year"
								label="Año"
								type="number"
								color="gray"
								value={form.year}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="price"
								label="Precio"
								type="number"
								color="gray"
								value={form.price}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="stockNumber"
								label="Numero de Stock"
								color="gray"
								value={form.stockNumber}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="mileage"
								label="Millas"
								type="number"
								color="gray"
								value={form.mileage}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="body"
								label="Carroceria"
								type="text"
								color="gray"
								value={form.body}
							/>
						</div>
						{/* <div className="col-md-3">
							<Input
								onChange={this.change}
								name="bodySpanish"
								label="Carroceria - Español"
								type="text"
								color="gray"
								value={form.bodySpanish}
							/>
						</div> */}
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="style"
								label="Estilo"
								type="text"
								color="gray"
								value={form.style}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="styleSpanish"
								label="Estilo - Español"
								type="text"
								color="gray"
								value={form.styleSpanish}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="exterior"
								label="Exterior"
								type="text"
								color="gray"
								value={form.exterior}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="exteriorSpanish"
								label="Exterior - Español"
								type="text"
								color="gray"
								value={form.exteriorSpanish}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="interior"
								label="Interior"
								type="text"
								color="gray"
								value={form.interior}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="interiorSpanish"
								label="Interior - Español"
								type="text"
								color="gray"
								value={form.interiorSpanish}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="package"
								label="Paquete"
								type="text"
								color="gray"
								value={form.package}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="packageSpanish"
								label="Paquete - Español"
								type="text"
								color="gray"
								value={form.packageSpanish}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="engine"
								label="Motor"
								type="text"
								color="gray"
								value={form.engine}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="transmission"
								label="Transmision"
								type="text"
								color="gray"
								value={form.transmission}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="horsepower"
								label="Caballos de Fuerza"
								type="text"
								color="gray"
								value={form.horsepower}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="torqueRating"
								label="Torque"
								type="text"
								color="gray"
								value={form.torqueRating}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="engineCylinders"
								label="Cilindros del Motor"
								type="text"
								color="gray"
								value={form.engineCylinders}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="engineDisplacement"
								label="Desplazamiento del Motor"
								type="text"
								color="gray"
								value={form.engineDisplacement}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="drivetrain"
								label="Traccion"
								type="text"
								color="gray"
								value={form.drivetrain}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="doors"
								label="Puertas"
								type="text"
								color="gray"
								value={form.doors}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="fuelType"
								label="Tipo de Combustible"
								type="text"
								color="gray"
								value={form.fuelType}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="fuelTypeSpanish"
								label="Tipo de Combustible - Español"
								type="text"
								color="gray"
								value={form.fuelTypeSpanish}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="fuelTank"
								label="Tanque de Combustible"
								type="text"
								color="gray"
								value={form.fuelTank}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="mpgCity"
								label="MPG Ciudad"
								type="text"
								color="gray"
								value={form.mpgCity}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="mpgHwy"
								label="MPG Autopista"
								type="text"
								color="gray"
								value={form.mpgHwy}
							/>
						</div>
						<div className="col-md-3">
							<Select
								onChange={this.change}
								name="status"
								label="Estado"
								color="gray"
								options={Constants.VEHICLE_STATUS_OPTIONS}
								value={form.status}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="titleStatus"
								label="Titulo del Estado"
								type="text"
								color="gray"
								value={form.titleStatus}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="titleStatusSpanish"
								label="Titulo del Estado - Español"
								type="text"
								color="gray"
								value={form.titleStatusSpanish}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="damageType"
								label="Tipo de Daño"
								type="text"
								color="gray"
								value={form.damageType}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="damageTypeSpanish"
								label="Tipo de Daño - Español"
								type="text"
								color="gray"
								value={form.damageTypeSpanish}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="location"
								label="Ubicacion"
								type="text"
								color="gray"
								value={form.location}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="locationSpanish"
								label="Ubicacion - Español"
								type="text"
								color="gray"
								value={form.locationSpanish}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="airbagStatus"
								label="Estado de las bolsas de aire"
								type="text"
								color="gray"
								value={form.airbagStatus}
							/>
						</div>
						<div className="col-md-3">
							<Input
								onChange={this.change}
								name="airbagStatusSpanish"
								label="Estado de las bolsas de aire - Español"
								type="text"
								color="gray"
								value={form.airbagStatusSpanish}
							/>
						</div>
						<div className="col-md-3">
							<Textarea
								onChange={this.change}
								name="vehicleDetails"
								label="Detalles del Vehiculo"
								type="text"
								color="gray"
								value={form.vehicleDetails}
							/>
						</div>
						<div className="col-md-3">
							<Textarea
								onChange={this.change}
								name="vehicleDetailsSpanish"
								label="Detalles del Vehiculo - Español"
								type="text"
								color="gray"
								value={form.vehicleDetailsSpanish}
							/>
						</div>
					</div>
				</div>
				<Button disabled={submit}>
					Guardar
				</Button>
			</form>
		)
	}
}

export default VehicleData;