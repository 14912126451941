import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface Props {
	src: string | undefined
}

const Image = (props: Props) => (
	<LazyLoadImage 
		placeholder={ <div /> }
		effect="opacity"
		src={ props.src } />
)

export default Image;