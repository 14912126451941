import React from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
import { Vehicle } from "models";
import { useTranslation } from "react-i18next";
import VehicleContainer from "../home/vehicle-container";
import { Pagination } from "components";

interface Props {
  vehicles: Vehicle[];
  onViewVehicle?: () => void;
  onNext: () => void;
  changePage: (page: number) => void;
  renderLoading: React.ReactNode;
  total: number;
  height?: number;
  loading?: boolean;
  pages?: {
    last: number | string;
    next: number | string;
    currentPage: number | string;
    totalPages: number | string;
  };
}

const ScrollVehicles = ({
  vehicles,
  changePage,
  onNext,
  renderLoading,
  total,
  height,
  loading,
  pages,
  onViewVehicle
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="container-vehicles">
      {vehicles.length == 0 && !loading && (
        <h3 className="no-vehicles">{t("inventory.NoVehicles")}</h3>
      )}
      {/* <InfiniteScroll
        dataLength={vehicles.length}
        next={onNext}
        hasMore={vehicles.length < total}
        loader={renderLoading}
        height={height}
      > */}
      <div className="row justify-content-start">
        {vehicles.map((i: Vehicle, index: number) => (
          <div className="col-lg-4 col-md-6" key={index}>
            <VehicleContainer vehicle={i} onViewVehicle={ onViewVehicle } />
          </div>
        ))}
      </div>
      {/* </InfiniteScroll> */}
      {pages && (
        <div className="row justify-content-center">
          <Pagination
            pages={pages}
            active={pages.currentPage}
            onChange={(page: number) => changePage(page)}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollVehicles;
