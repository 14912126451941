import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { store } from "store";

const { REACT_APP_BASE_API_URL:baseURL } = process.env;
const dolarService: AxiosInstance = axios.create({baseURL});
dolarService.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers.common["Authorization"] = `Bearer ${
        store.getState().token?.token
    }`;  return config;
});

export default dolarService;