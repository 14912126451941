import React from "react";
import Layout from "../layout/layout";
import { PublicService, InventoryService, InstagramService } from "services";
import { RootState } from "reducers";
import { connect } from "react-redux";
import { ENV } from "utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Button, Icon, DatePicker, Input } from "components";
import moment from 'moment'
import BlueCar from 'assets/img/blue-car-view-brand.png';
import {Globals} from 'utils';
import Banner from '../inventory/banner';
import { BannerType } from 'models/bannerModel';
import Select from 'react-select';
import VehicleContainer from '../home/vehicle-container';
import Loading from '../inventory/loading';
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import ScrollVehicles from '../inventory/scroll-vehicles';
import Search from '../inventory/search';
import queryString from 'query-string';
import { Left } from "assets/icons";
import { withLastLocation } from 'react-router-last-location';
import BannerInventory from 'assets/img/inventory.jpeg';

const dispatchActions = {
  brands: "SET_BRANDS"
};

interface onSearchProps {
	brand?: string | number,
	model?: string | number,
	maxPrice?: string | number,
	minPrice?: string | number,
	search?: string | undefined | null | string[]
}

class ViewBrand extends React.Component<any> {
	
  private ref = React.createRef<HTMLDivElement>();

  state = {
    items: [],
    respItems: [],
    models: [],
    index: 0,
    hasMore: true,
    size: undefined,
    selected: {
      name: "",
      vehicles: [],
		},
		pages: {
			last: '',
			next: '',
			currentPage: 1,
			totalPages: ''
		},
    form: {
		search: ''
	},
    dates:{
    	since: {
    		value: {
    			label: '',
    			value: ''
    		},
    		items: []
    	},
    	until: {
    		value: {
    			label: '',
    			value: ''
    		},
    		items: []
    	}
    },
    show:{
    	models: true,
    	years: true,
    	prices: true,
    	brands: true
    },
    range:{
    	since: 0,
    	until: 1,
    	maxDates:{
    		since: 0,
    		until: 1
    	}
    },
    minYear: 22,
    since: undefined,
    brand: this.props.match?.params?.brand || '',

    // Variables al no tener marca
	page: 1,
	total: 0,
	loading: true,
	vehicles: [],
	brands_selected: [],
	models_selected: []
  };

  async componentDidMount() {
  	const parsed = queryString.parse(this.props.location.search);
	const location: onSearchProps = this.props.location.state;
	if (parsed) {
		await this.setState({
			form: {
				...this.state.form,
				search: parsed.search
			}				
		});
	}

    this.load();
  }

  load = async () => {
		if(this.props.match.params.brand){
		// 	let wasFetched = false
		// 	if(
		// 		this.props.vehicles &&
		// 		this.props.vehicles[this.props.match.params.brand] &&
		// 		this.props.vehicles[this.props.match.params.brand].length > 0){
		//         await this.setState({
		//        		selected: {
		//        			name: this.props.t('viewBrand.All'),
		// 				vehicles: this.props.vehicles[this.props.match.params.brand]
		//        		}
		//        	});
		//         await this.fetchMoreData()
		//         this.getModels()
		//         await this.setPriceRanges()
		//         this.setDates()
		//         if(this.props.match.params.model){
		//         	this.withModel()
		//         }
		//         wasFetched = true
		// 	}
			PublicService.visitByBrand({brand: this.props.match.params.brand})
		// 	const res: any = await PublicService.getBrand({brand: this.props.match.params.brand})
		// 	let models: any = {}
		// 	let vehicles: any =  {}
		// 	if(res?.data?.models && res?.data?.models?.length > 0){
		// 		models = {
		// 			...this.props.models,
		// 			[this.props.match.params.brand]: await res.data.models.map((element: any, i: number) => {
		//             	return {
		//             		name: element.name
		//             	}
		//             })
		// 		}
		// 	}
		// 	await this.props.dispatch({
	 //            type: 'SET_MODELS',
	 //            payload: models
	 //        });
		// 	if(res?.data?.vehicles && res?.data?.vehicles?.length > 0){
		// 		vehicles = {
		// 			...this.props.vehicles,
		// 			[this.props.match.params.brand]: res.data.vehicles
		// 		}
		// 	}
		// 	await this.props.dispatch({
	 //            type: 'SET_VEHICLES',
	 //            payload: vehicles
	 //        });
	 //        if(!wasFetched){
		//        	await this.setState({
		//        		selected: {
		//        			name: this.props.t('viewBrand.All'),
		// 				vehicles: this.props.vehicles[this.props.match.params.brand]
		//        		}
		//        	});
	 //       		await this.fetchMoreData()
	 //       		this.getModels()
	 //       		await this.setPriceRanges()
	 //       		this.setDates()
	 //       	}
  //      		if(this.props.match.params.model){
	 //        	this.withModel()
	 //        }
		}
		const res: any = await InstagramService.get({
	        orderBy: "name",
	        orderByType: "ASC",
	    });
	    this.props.dispatch({
	        type: dispatchActions.brands,
	        payload: res.brands,
	    });
	    if (this.props.match.params.brand) {
			let brands_selected: any = [...this.state.brands_selected];
			const brand: any = res.brands.find((i: any) => i.name == this.props.match.params.brand);
			brands_selected.push(brand.id);
			await this.setState({
				brands_selected
			});
		}

		const { since, until } = this.state.range; 

		console.log(this.props.filters)
		
		if (this.props.lastLocation && this.props.lastLocation.pathname.indexOf('/vehicle') != -1)
			await this.setState({
				brands_selected: this.props.filters.brands || this.state.brands_selected,
				models_selected: this.props.filters.models || this.state.models_selected,
				page: this.props.filters.page || this.state.page,
				form: {
					...this.state.form,
					search: this.props.filters.search || this.state.form.search
				},
				dates: {
					...this.state.dates,
					since: {
						...this.state.dates.since,
						value: {
							label: this.props.filters.since || this.state.dates.since.value.label,
							value: this.props.filters.since || this.state.dates.since.value.value
						}
					},
					until: {
						...this.state.dates.until,
						value: {
							label: this.props.filters.until || this.state.dates.until.value.label,
							value: this.props.filters.until || this.state.dates.until.value.value
						}
					}
				},
				range: {
					...this.state.range,
					since: this.props.filters.minPrice || (since > 0 ? since : null),
					until: this.props.filters.maxPrice || (until > 1 ? until : null)
				}
			});

		this.props.dispatch({
			type: 'SET_FILTERS',
			payload: {}
		});

		this.loadAll();
	}

	loadAll = async (reset: boolean = false) => {
		const { since, until } = this.state.range; 
		
		window.scrollTo(0, 50);

		const params = {
			brands: [...this.state.brands_selected],
			models: [...this.state.models_selected],
			page: this.state.page,
			minPrice: since > 0 ? since : null,
			maxPrice: until > 1 ? until : null,
			since: this.state.dates.since.value.value,
			until: this.state.dates.until.value.value,
			search: this.state.form.search
		}
		const res: any = await InventoryService.get(params);
		// @ts-ignore
		const models_selected = res.models.map((i: any) => i.id).filter((i: number) => this.state.models_selected.indexOf(i) != -1);

		this.setState({
			loading: false,
			vehicles: reset ? res.vehicles.items : [...res.vehicles.items],
			total: res.vehicles.meta.totalItems,
			pages: {
				currentPage: parseInt(res.vehicles.meta.currentPage),
				totalPages: res.vehicles.meta.totalPages
			},
			models_selected,
			models: res.models,
			range: {
				since: since > 0 ? since : res.range.min,
				until: until > 1 ? until : res.range.max,
				maxDates: {
					since: res.range.min,
					until: res.range.max
				}
			}
		});
		this.setDates();
	}

	withModel = () => {
		const model: any = this.state.models.find((element: any) => {
			return element.name == this.props.match.params.model
		});
		if(model){
			this.setState({
				selected: model,
				items: model.vehicles
			})
		}
	}

	fetchMoreData = async () => {
	    if(this.state.items?.length >= this.state.selected?.vehicles?.length){
	    	this.setState({
	    		hasMore: false
	    	});
	    	return false;
	    }
	    const index = this.state.index+15;
	    const items = [... (this.state.items || []), ... (this.state.selected?.vehicles ? this.state.selected?.vehicles.slice(this.state.index, index) : [])]
		await this.setState({
			items,
			respItems: items,
			index
		});
		return true
	};

  
  setDates = () => {
  	let items = []
  	for (var i = 1; i < this.state.minYear; i++) {
  		items.push({
  			label: moment().subtract(i, 'years').format('YYYY'),
	  		value: moment().subtract(i, 'years').format('YYYY')
  		})
  	}
		items.reverse()
		items.push({
  		label: moment().format('YYYY'),
  		value: moment().format('YYYY')
		});
  	items.push({
  		label: moment().add(1,'y').format('YYYY'),
  		value: moment().add(1,'y').format('YYYY')
		});

  	this.setState({
  		dates:{
  			since: {
  				value: this.state.dates.since.value.value ? this.state.dates.since.value : items[0],
  				items
  			},
  			until: {
  				value: this.state.dates.until.value.value ? this.state.dates.until.value : items[items.length],
  				items
  			}
  		}
  	})
  }


  getModels = async () => {
    let models: any = [
      {
        name: this.props.t("viewBrand.All"),
        vehicles: this.props.vehicles[this.props.match.params.brand],
      },
    ];
    if(this.props.vehicles && this.props.vehicles[this.props.match.params.brand]){
	    await this.props.vehicles[this.props.match.params.brand].map(
	      async (element: any, i: number) => {
	        if (element.model?.name) {
	          let index = models.findIndex((_element: any) => {
	            return _element.name == element.model.name;
	          });
	          let vehicles: any[] = [];

	          if (index !== -1) {
	            vehicles = [...models[index].vehicles];
	            vehicles.push(element);
	            models[index] = {
	              name: element.model.name,
	              vehicles: vehicles,
	            };
	          } else {
	            vehicles.push(element);
	            models.push({
	              name: element.model.name,
	              vehicles: vehicles,
	            });
	          }
	        }
	      }
	    );
	    this.setState({
	      models,
	    });
    }
  };

	filterByModel = (element: any) => {
		if(element.name !== this.props.t('viewBrand.All'))
			PublicService.visitByModel({model: element.name})

		this.setState({
			items: element.vehicles,
			selected: element
		})
	}
	lookMore = (element: any) => {
		PublicService.visitVehicle({vehicle: element.id})
		this.props.history.push('/vehicle/'+element.model.name+'/'+element.slug)
	}

	filterByBrand = (element: any) => {
		this.props.history.push('/view/'+element.name)
		window.location.reload()
	}

	filterByDate = async () => {
		if(this.state.respItems?.length > 0){
			// this.state.items?.length > 0
			let items: any = []
			await this.state.respItems.forEach((element: any, i: number) => {
				if((element.year >= this.state.dates.since?.value?.value) && (element.year <= this.state.dates.until?.value?.value)){
					items.push(element)
				}
			});
			this.setState({
				items
			});
		}
	}

	onChange = (element: {label: string, value: string}, type: string) => {
		this.setState((state: any) => {
			state.dates[type].value = element;
			return state
		}, this.reload);
	}

	componentDidUpdate() {
		if (this.ref && this.ref.current?.clientHeight != this.state.size) {
			this.setState({
				size: this.ref.current?.clientHeight
			});
		}
	}

	show = (element: string) => {
		this.setState((state: any) => {
			state.show[element] = !state.show[element]
			return state
		});
	}

	onSlide = (render: any, handle: any, value: any, un: any, percent: any) => {
	    this.setState({
	    	range:{
	    		... this.state.range,
	    		since: parseInt(value[0]),
	    		until: parseInt(value[1])
	    	}
	    }, this.reload);
	};

	filterByPrice = async () => {
		if(this.state.respItems?.length > 0){
			let items: any = []
			await this.state.respItems.forEach((element: any, i: number) => {
				if((element.price >= this.state.range?.since) && (element.price <= this.state.range?.until)){
					items.push(element)
				}
			});
			this.setState({
				items
			});
		}
	}

	// setPriceRanges = async () => {
	// 	if(this.state.items?.length > 0){
	// 		let since: number = 0
	// 		let until: number = 0
	// 		let minYear: number = parseInt(moment().format('YYYY'))
	// 		await this.state.items.forEach((element: any, i: number) => {
	// 			if(element.price <= since || since == 0)
	// 				since = element.price
	// 			if(element.price >= until)
	// 				until = element.price
	// 			if(element.year <= minYear)
	// 				minYear = element.year
	// 		});
	// 		await this.setState({
	// 			range:{
	// 				since,
	// 				until,					
	// 				maxDates:{
	// 					since,
	// 					until
	// 				}
	// 			},
	// 			minYear: parseInt(moment().format('YYYY')) - minYear
	// 		});
	// 		return true
	// 	}
	// }

	// setPriceRangesAll = async () => {
	// 	const { vehicles } = this.state;
	// 	if (vehicles.length > 0){
	// 		let since: number = 0
	// 		let until: number = 0
	// 		let minYear: number = parseInt(moment().format('YYYY'))
	// 		await vehicles.forEach((element: any, i: number) => {
	// 			if (element.price <= since || since == 0)
	// 				since = element.price
	// 			if (element.price >= until)
	// 				until = element.price
	// 			if (element.year <= minYear)
	// 				minYear = element.year
	// 		});
	// 		await this.setState({
	// 			range:{
	// 				since,
	// 				until,
	// 				maxDates:{
	// 					since,
	// 					until
	// 				}
	// 			},
	// 			minYear: parseInt(moment().format('YYYY')) - minYear
	// 		});
	// 	}
	// }

	addBrandFilter = async (brand: any) => {
		PublicService.visitByBrand({ brand: brand.id });
		const brands_selected: any = [...this.state.brands_selected];
		const index = brands_selected.indexOf(brand.id);
		if (index == -1) {
			brands_selected.push(brand.id);
			await this.setState({
				brands_selected
			});
		}
		else {
			await this.setState({
				brands_selected: brands_selected.filter((i: number) => i != brand.id)
			});
		}
		this.reload();
	}

	addModelFilter = async (model: any) => {
		PublicService.visitByModel({ model: model.id })
		const models_selected: any = [...this.state.models_selected];
		const index = models_selected.indexOf(model.id);
		if (index == -1) {
			models_selected.push(model.id);
			await this.setState({
				models_selected
			});
		}
		else {
			await this.setState({
				models_selected: models_selected.filter((i: number) => i != model.id)
			});
		}
		this.reload();
	}

	deleteModelFilter = async (model: number) => {
		const models_selected: any = [...this.state.models_selected];
		await this.setState({
			models_selected: models_selected.filter((i: number) => i != model)
		});
		this.reload();
	} 

	deleteBrandFilter = async (brand: number) => {
		const brands_selected: any = [...this.state.brands_selected];
		await this.setState({
			brands_selected: brands_selected.filter((i: number) => i != brand)
		});
		this.reload();
	}

	reload = async () => {
		await this.setState({
			loading: true,
			page: 1,
			total: 0
		});
		this.loadAll(true);
	}

	onSearch = async (form: onSearchProps) => {
		await this.setState({
			loading: true,
			page: 1,
			total: 0,
			form
		});
		this.loadAll(true);
	}

	setReducer = () => {
		const { since, until } = this.state.range;

		const params = {
			brands: [...this.state.brands_selected],
			models: [...this.state.models_selected],
			page: this.state.page,
			minPrice: since > 0 ? since : null,
			maxPrice: until > 1 ? until : null,
			since: this.state.dates.since.value.value,
			until: this.state.dates.until.value.value,
			search: this.state.form.search
		}
		this.props.dispatch({
			type: 'SET_FILTERS',
			payload: params
		});
	}

	render() {
		const { loading, vehicles, total, models } = this.state;
		const { brands, history } = this.props;
		const showBrand = false;
		const brands_selected: any = [...this.state.brands_selected];
		const models_selected: any = [...this.state.models_selected];
		
		return (
			<Layout>
				<React.Fragment>
					<div className="view-brand">
						<Banner
							offset={ 3 }
							bg={ BannerInventory }
							hideImage={ true }
							type={ BannerType.BRANDS } />
						<div className="wrapper-contain" style={ {
							padding: '0px'
						} }>
							<Button
								onClick={() => history?.push('/') }
								className="btn-back-vehicle"
								>
								<img src={ Left } />
								{ this.props.t('vehicle.Back') }
							</Button>
						</div>
						<div className="row wrapper-contain body-contain">
							<div className="col-md-3 side-left">
								<div className="text-center">
									<div className="side-models" ref={ this.ref }>
										<div className="text-left">
											{ (showBrand || models_selected.length > 0 || brands_selected.length > 0) && <div className="section">
												<div className="side-header">
													<p className="text-blue"> {this.props.t('viewBrand.toChoose')}</p>
												</div>
												<div className="side-body">
													<div className="container container-margin-top">
														{
															showBrand && (
																<div className="tab container text-center">
																	<p> {this.state.brand || 'Any'} </p>
																</div>
															) 													
														}

														{
															!showBrand && brands_selected.map((brand: any,index: number) => {
																const _brand: any = brands.find((i: any) => i.id == brand);
																return (
																	<div className="tab container text-center">
																		<p>
																			{ _brand.name } 
																			<button className="btn btn-default" onClick={ () => this.deleteBrandFilter(brand) }>
																				<i className="fa fa-close"></i>
																			</button>
																		</p>
																	</div>
																)
															})
														}

														{
															!showBrand && models_selected.map((model: any,index: number) => {
																const _model: any = models.find((i: any) => i.id == model);
																if (_model)
																	return (
																		<div className="tab container text-center">
																			<p>
																				{ _model.name }
																				<button className="btn btn-default" onClick={ () => this.deleteModelFilter(model) }>
																					<i className="fa fa-close"></i>
																				</button>
																			</p>
																		</div>
																	)
															})
														}
													</div>
												</div>
											</div> }
											{ this.state.models && this.state.models.length > 0 && <div className="section">
												<div className="side-header" onClick={() => this.show('models')} >
													<div className="row">
														<div className="col-sm-10">
															<p className="text-blue"> {this.props.t('viewBrand.model')}</p>
														</div>
														<div className="col-sm-2 px-0">
															<Icon
																name={(this.state.show.models ? 'chevron-up' : 'chevron-down')+' text-blue'}
															/>
														</div>
													</div>
												</div>
												<div className="side-body">
													<div className="container">
														{
															this.state.show.models && this.state.models?.map((element: any, i: any) => {
																return (
																	<div key={i} className="text-left model">
																		<button className={`btn btn-link btn-model ${this.state.selected.name == element.name || models_selected.indexOf(element.id) != -1 ? 'btn-model-selected' : ''}`} onClick={() => {
																			if (showBrand)
																				this.filterByModel(element);
																			else {
																				this.addModelFilter(element);
																			}
																		}} >
																			<p>
																				{element.name+': '} {'('+(element.vehicles?.length || element.vehiclesCount)+')'}
																			</p>
																		</button>
																	</div>
																)
															})
														}
													</div>
												</div>
											</div> }
											<div className="section">
												<div className="side-header" onClick={() => this.show('years')}>
													<div className="row">
														<div className="col-sm-10">
															<p className="text-blue"> {this.props.t('viewBrand.year')} </p>
														</div>
														<div className="col-sm-2 px-0">
															<Icon
																name={(this.state.show.years ? 'chevron-up' : 'chevron-down')+' text-blue'}
															/>
														</div>
													</div>
												</div>
												{
													this.state.show.years && (
														<div className="side-body">
															<div className="container container-margin-top">
																<div className="row">
																	<div className="col-md-6 px-0">
																		<div className="tab full-width">
																			<Select
																	          className="basic-single"
																	          classNamePrefix="select"
																	          defaultValue={this.state.dates.since.items[0]}
																	          isSearchable
																	          name="color"
																	          options={this.state.dates.since.items}
																	          onChange={(element: any) => this.onChange(element, 'since')}
																	          value={this.state.dates.since.value}
																	          placeholder="Tamaño"
																	        />
																		</div>
																	</div>
																	<div className="col-md-6 px-0">
																		<div className="tab full-width">
																			<Select
																	          className="basic-single"
																	          classNamePrefix="select"
																	          defaultValue={this.state.dates.until.items[this.state.dates.until.items.length-1]}
																	          isSearchable
																	          name="color"
																	          onChange={(element: any) => this.onChange(element, 'until')}
																	          value={this.state.dates.until.value}
																	          options={this.state.dates.until.items}
																	        />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												}
											</div>
											<div className="section">
												<div className="side-header" onClick={() => this.show('prices')}>
													<div className="row">
														<div className="col-sm-10">
															<p className="text-blue"> {this.props.t('viewBrand.price')} </p>
														</div>
														<div className="col-sm-2 px-0">
															<Icon
																name={(this.state.show.prices ? 'chevron-up' : 'chevron-down')+' text-blue'}
															/>
														</div>
													</div>
												</div>
												{
													this.state.show.prices && (
														<div className="side-body">
															<div className="container container-margin-top">
																<Nouislider
														          connect
														          start={[
														          	this.state.range?.maxDates?.since || this.state.range?.since || 0,
														          	this.state.range?.maxDates?.until || this.state.range?.until || 1]}
														          behaviour="tap"
														          range={{
														            min: [this.state.range?.maxDates?.since || 0],
														            max: [this.state.range?.maxDates?.until || 1]
														          }}
														          onChange={this.onSlide}
														        />
																<div className="row">
																	<div className="col-md-6 px-0" style={{padding: 5}} >
																		<div className="tab container px-0 full-width">
																			<p className="black-text">${Globals.formatMiles(this.state.range?.since || 0)}</p>
																		</div>
																	</div>
																	<div className="col-md-6 px-0" style={{padding: 5}}>
																		<div className="tab container px-0 full-width">
																			<p className="black-text">${Globals.formatMiles(this.state.range?.until || 1)}</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												}
											</div>
											<div className="section container">
												<div className="side-header" onClick={() => this.show('brands')}>
													<div className="row">
														<div className="col-sm-10">
															<p className="text-blue"> {this.props.t('viewBrand.brand')} </p>
														</div>
														<div className="col-sm-2 px-0">
															<Icon
																name={(this.state.show.brands ? 'chevron-up' : 'chevron-down')+' text-blue'}
															/>
														</div>
													</div>
												</div>
												<div className="side-body container">
													{
														this.state.show.brands && this.props.brands && this.props.brands?.map((element: any, i: number) => {
															return(
																<div key={i} className="text-left model">
																	<button className={`btn btn-link btn-model ${this.state.selected.name == element.name || brands_selected.indexOf(element.id) != -1 ? 'btn-model-selected' : ''}`} onClick={() => {
																		if (showBrand)
																			this.filterByBrand(element);
																		else {
																			this.addBrandFilter(element);
																		}
																	}} >
																		<p>
																			{element.name}
																		</p>
																	</button>
																</div>
															)
														})
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-9 pr-0 side-right">
								{ showBrand && (
									<div>
										<InfiniteScroll
										  height={ this.state.size }
											dataLength={this.state.items.length}
											next={this.fetchMoreData}
											hasMore={this.state.hasMore}
											loader={ <Loading /> }
										>
											<p className="text-left text-uppercase">
												<strong>
													{this.state.items?.length || 0}&nbsp;
													{this.props.t('viewBrand.vehiclesFound')}
												</strong>
											</p>
											<div className="row scroll-items">
												{
													this.state.items && this.state.items.length > 0 && this.state.items.map((element: any, i: number) => {
														return (
															<div className="col-md-6 col-lg-4" key={i}>
																<VehicleContainer full={ true } vehicle={ element } />
															</div>
														)
													}) 
													
												}
											</div>
									    </InfiniteScroll>
									</div>
								) }

								<Search
									search={ queryString.parse(this.props.location.search).search }
									onSubmit={ this.onSearch } />

								{
									!showBrand && (
										<React.Fragment>
											{
												loading ? (
													<div className="container-loading">
														<Loading />
													</div>
												) : (
													<div className="container-scroll-vehicles">
														<ScrollVehicles
															key={ this.state.size }
															onViewVehicle={ this.setReducer }
															changePage={(page: number) => {
																this.setState({
																	page: page,
																	loading: true
																}, () => this.loadAll())
															}}
															loading={ loading }
															height={ this.state.size }
															vehicles={ vehicles }
															total={ total }
															renderLoading={ Loading }
															pages={this.state.pages}
															onNext={ async () => {
																			const page = this.state.page + 1;
																			await this.setState({
																				page
																			});
																			this.loadAll();
																	} } />
													</div>
												)
											}
										</React.Fragment>
									)
								}
							</div>
						</div>
					</div>
				</React.Fragment>
			</Layout>
		)
	}
}
const redux: any = (state: RootState) => ({
  brands: state.brands,
  models: state.models,
  vehicles: state.vehicles,
  filters: state.filters
});
export default withTranslation()(connect(redux)(withLastLocation(ViewBrand)));
