const STATUS_ACTIVE = 1;
const STATUS_INACTIVE = 0;

const NATIONALITIES = [
  { value: "V", label: "V" },
  { value: "E", label: "E" },
  { value: "J", label: "J" },
];

const BRAND_TYPES = {
  BOT_CREATED: 1,
  ADMIN_CREATED: 2,
};

const STATUS_USER = [
  { value: 1, label: "Activo" },
  { value: 0, label: "Inactivo" },
];

const MODEL_TYPES = {
  BOT_CREATED: 1,
  ADMIN_CREATED: 2,
};

const ADMIN_VEHICLE_TYPES = {
  BOT_CREATED_MODIFIED: 0,
  ADMIN_CREATED_MODIFIED: 1,
};

const VEHICLE_STATUS_OPTIONS = [
  { value: 0, label: "No publicado" },
  { value: 1, label: "Publicado" },
  { value: 2, label: "Vendido" },
];

const VEHICLE_STATUS_LIST = {
  NOT_PUBLISHED: 0,
  PUBLISHED: 1,
  SOLD: 2,
};

const VEHICLE_PUBLISH_OPTIONS = [
  { value: 0, label: "Despublicar" },
  { value: 1, label: "Publicar" }
]

const ABOUT_TYPES = {
  HOME: 1,
  ABOUTUS: 2
}

export default {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_USER,
  NATIONALITIES,
  MODEL_TYPES,
  BRAND_TYPES,
  ADMIN_VEHICLE_TYPES,
  VEHICLE_STATUS_OPTIONS,
  VEHICLE_STATUS_LIST,
  VEHICLE_PUBLISH_OPTIONS,
  ABOUT_TYPES
};
