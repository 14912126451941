import React from "react";
import { useDispatch } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Logo } from "assets/img";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { PhoneBlue } from "assets/icons";
import { useHistory } from "react-router-dom";
import moment from 'moment';

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contact = useSelector((state: { contact: any }) => state.contact);
  const history = useHistory();

  const changeLang = (lang: string) => {
    i18next.changeLanguage(lang);
    dispatch({
      type: "SET_LANG",
      payload: lang,
    });
  };

  const isHome = history.location.pathname === "/";

  return (
    <header className={isHome ? "sticky-fixed" : ""}>
      <div className="container-nav">
          <nav className="navbar navbar-expand-md bg-light">
              <Link to="/">
              <img src={Logo} className="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#publicNavbar"
            >
              <i className="fa fa-bars"></i>
            </button>

            <div className="collapse navbar-collapse" id="publicNavbar">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    onClick={ () => {
                      dispatch({
                        type: 'SET_FILTERS',
                        payload: {}
                      });
                    } }
                    className={`nav-link ${
                      history.location.pathname == "/" ? "active" : ""
                    }`}
                    to="/"
                  >
                  {t("home.Menu.Home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={ () => {
                      dispatch({
                        type: 'SET_FILTERS',
                        payload: {}
                      });
                    } }
                    className={`nav-link ${
                      history.location.pathname == "/inventory" ? "active" : ""
                    }`}
                    to="/inventory"
                  >
                    {t("home.Menu.Inventory")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      history.location.pathname == "/about-us" ? "active" : ""
                    }`}
                    to="/about-us"
                  >
                    {t("home.Menu.About")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      history.location.pathname == "/contact" ? "active" : ""
                    }`}
                    to="/contact"
                  >
                    {t("home.Menu.Contact")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link 
                    className={`nav-link ${
                      history.location.pathname == "/gallery" ? "active" : ""
                    }`}
                    to="/gallery"
                  >
                    {t("home.Menu.Gallery")}
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        {/*<div className="col-gray">
          <div className="phone">
            <img src={PhoneBlue} />
            <p>{contact.phone}</p>
          </div>
          <div className="container-lang">
            <div
              className={`item-lang ${
                i18next.language == "es" ? "active" : undefined
              }`}
            >
              <a
                href="#"
                onClick={(e: any) => {
                  e.preventDefault();
                  changeLang("es");
                }}
              >
                ES
              </a>
            </div>
            <div
              className={`item-lang ${
                i18next.language == "en" ? "active" : undefined
              }`}
            >
              <a
                href="#"
                onClick={(e: any) => {
                  e.preventDefault();
                  changeLang("en");
                }}
              >
                EN
              </a>
            </div>
          </div>
        </div>*/}
      </div>
    </header>
  );
};

export default Header;
