import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import home_es from "locales/es/home.json";
import viewBrand_es from "locales/es/viewBrand.json";
import vehicle_es from "locales/es/vehicle.json";
import inventory_es from 'locales/es/inventory.json';
import contact_es from 'locales/es/contact.json';
import about_es from 'locales/es/about.json';
import gallery_es from 'locales/es/gallery.json';

// English
import home_en from "locales/en/home.json";
import viewBrand_en from "locales/en/viewBrand.json";
import vehicle_en from "locales/en/vehicle.json";
import inventory_en from 'locales/en/inventory.json';
import contact_en from 'locales/en/contact.json';
import about_en from 'locales/en/about.json';
import gallery_en from 'locales/en/gallery.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "es",
    resources: {
      es: {
        translation: {
          home: home_es,
          viewBrand: viewBrand_es,
          inventory: inventory_es,
          vehicle: vehicle_es,
          contact: contact_es,
          about: about_es,
          gallery: gallery_es,
        },
      },
      en: {
        translation: {
          home: home_en,
          viewBrand: viewBrand_en,
          inventory: inventory_en,
          vehicle: vehicle_en,
          contact: contact_en,
          about: about_en,
          gallery: gallery_en,
        },
      },
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "locales/{{lng}}/{{ns}}.json",
    },
  });

export default i18n;
