import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class InventoryService {
  static get = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios.post("inventory/get", params).then(
        (response: AxiosResponse) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default InventoryService;
