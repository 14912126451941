export type Banner = {
  text: string,
  language: {
  	name: string
  },
  type: number
};

export const BannerType = {
	BRANDS: 1,
	INVENTORY: 2,
	CONTACT: 3,
	GALLERY: 4
}