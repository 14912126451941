import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class ScheduleService {
  static get = () => {
    return new Promise((resolve, reject) => {
      axios
        .post('schedule/get')
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }
}

export default ScheduleService;
