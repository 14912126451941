import React from 'react';
import {Input, Button} from 'components'
import {PriceService} from 'services'
import {Globals} from 'utils'

class CreateEditPrice extends React.Component<any>{

	state = {
		form:{
			limitMin: '',
			limitMax: '',
			rate: ''
		},
		translate: {
			limitMin: 'Minimo',
			limitMax: 'Maximo'
		}
	}

	componentDidMount(){
		this.load()
	}

	load = () => {
		if(this.props.edit?.element){
			this.setState({
				form:{
					id: this.props.edit.element.id,
					limitMin: this.props.edit.element.limitMin,
					limitMax: this.props.edit.element.limitMax,
					rate: this.props.edit.element.rate
				}
			})
		}
	}

	submit = async () =>{
		for(var propName in this.state.form){
			// @ts-ignore
			if(!this.state.form[propName]){
				// @ts-ignore
				Globals.showError('El campo '+this.state.translate[propName]+' es requerido')
				return false
			}
		}
		Globals.setLoading()
		const { msg }:any = await PriceService[ this.props.edit?.element ? 'edit' : 'create'](this.state.form)
		Globals.showSuccess(msg);
		this.props.onClose()
		Globals.quitLoading()
	}

	change = (e: any) => {
		e.preventDefault();
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	render(){
		return(
			<React.Fragment>
				<Input
					onChange={this.change}
					name="limitMin"
					label="Minimo"
					type="number"
					color="gray"
					value={this.state.form.limitMin}
				/>
				<Input
					onChange={this.change}
					name="limitMax"
					label="Maximo"
					type="number"
					color="gray"
					value={this.state.form.limitMax}
				/>
				<Input
					onChange={this.change}
					name="rate"
					label="Tasa"
					type="number"
					color="gray"
					value={this.state.form.rate}
				/>
				<Button onClick={this.submit} >
					Guardar
				</Button>
			</React.Fragment>
		)
	}
}

export default CreateEditPrice;