import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import OwlCarousel from "react-owl-carousel";
import { Bg, BgAbout, About } from "assets/img";
import { useTranslation } from "react-i18next";
import { InstagramService, InventoryService, PublicService } from "services";
import { Link, useHistory } from "react-router-dom";
import i18n from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { Vehicle } from "models/vehicleModel";
import VehicleContainer from "./vehicle-container";
import Loading from "../inventory/loading";
import ScrollVehicles from "../inventory/scroll-vehicles";
import { Search as SearchIcon } from "assets/icons";
import { ENV, Constants } from "utils";
import AboutHome from "assets/img/about-home.jpeg";
import BgIframe from "assets/img/bg-iframe.jpg";
import BannerTestimonios from "assets/img/banner-testimonios.jpg";

import arrowLeft from "assets/icons/arrowLeft.svg";
import arrowRight from "assets/icons/arrowRight.svg";

interface Instagram {
  media_url: string;
  permalink: string;
}

interface onSearchProps {
  brand?: string | number;
  model?: string | number;
  maxPrice?: string | number;
  minPrice?: string | number;
}

const dispatchActions = {
  brands: "SET_BRANDS",
  carousel: "SET_CAROUSEL",
  about: "SET_ABOUT",
};

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const state = useSelector((state: any) => {
    return {
      brands: state.brands,
      about: state.about,
      carousel: state.carousel,
    };
  });

  const [loading, setLoading] = useState(!state.brands);
  const [brands, setBrands] = useState(!state.brands ? [] : state.brands);
  const [brandsOriginal, setBrandsOriginal] = useState(
    !state.brands ? [] : state.brands
  );
  const [about, setAbout] = useState(!state.about ? [] : state.about);
  const [vehicles, setVehicles] = useState<Vehicle[]>(
    !state.carousel ? [] : state.carousel
  );

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [inventory, setInventory] = useState<Vehicle[]>([]);
  const [loadingInventory, setLoadingInventory] = useState(true);
  const [search, setSearch] = useState("");
  // const [instagram,setInstagram] = useState([]);

  const keyDown = (event: any) => {
    if (event.keyCode === 13) {
      history.push("inventory?search=" + search);
    }
  };

  const onSearch = (form: onSearchProps) => {
    history.push({
      pathname: "/inventory",
      state: form,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const res: any = await InstagramService.get({
        orderBy: "name",
        orderByType: "ASC",
      });
      setVehicles(res.vehicles);
      dispatch({
        type: dispatchActions.carousel,
        payload: res.vehicles,
      });
      setBrands(res.brands);
      setBrandsOriginal(res.brands);
      dispatch({
        type: dispatchActions.brands,
        payload: res.brands,
      });
      setAbout(res.about);
      dispatch({
        type: dispatchActions.about,
        payload: res.about,
      });
      setLoading(false);
      // Consulta de las imagenes de Instagram, de momento no se va a usar
      // const instagramData: any = await InstagramService.media(res.instagram.user_id,res.instagram.token);
      // setInstagram(instagramData.media.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const load = async () => {
      const params = {
        page,
      };
      const res: any = await InventoryService.get(params);
      setLoadingInventory(false);
      setInventory([...inventory, ...res.vehicles.items]);
      setTotal(res.vehicles.meta.totalItems);
    };
    load();
  }, [page]);

  const _about: any =
    about &&
    about.find(
      (i: any) =>
        i.language.name === i18n.language &&
        i.type === Constants.ABOUT_TYPES.HOME
    );

  const contact = useSelector((state: { contact: any }) => state.contact);

  const visitBrand = async (name: string, brandId: number) => {
    const res = await PublicService.visitByBrand({ brand: brandId });
    history.push("/view/" + name);
  };

  return (
    <Layout>
      <div id="home">
        {/* Banner */}
        <div
          className="banner"
          style={{
            backgroundImage: `url(${Bg})`,
          }}
        >
          <div className="container-banner">
            {/*<h2 className="title">{t("home.Title")}</h2>
<h3 className="subtitle">{t("home.SubTitle")}</h3>*/}
            {loading ? (
              <div className="text-center">
                <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
              </div>
            ) : (
              <React.Fragment>
                <div className="input-group">
                  <input
                    onChange={(event: any) => setSearch(event.target.value)}
                    type="text"
                    value={search}
                    onKeyDown={keyDown}
                    placeholder={t("home.BrandAndModel")}
                    className="form-control"
                  />
                  <div className="input-group-append">
                    <Link to={"/inventory?search=" + search}>
                      <img src={SearchIcon} />
                    </Link>
                  </div>
                </div>
                {/* <section className="brands-container">
<div className="row brands">
{brands.map((i: any, index: number) => (
<div
className="col-md-2 col-6 px-0 text-center"
key={index}
>
<a
className="brand-link"
href="#"
onClick={(event: any) => visitBrand(i.name, i.id)}
>
<p className="name-brand">{i.name}</p>
</a>
</div>
))}
</div>
</section>*/}
              </React.Fragment>
            )}
          </div>
        </div>

        {/* Búsqueda */}
        {/* <Search onSubmit={ onSearch } /> */}

        {/* Nuevo Inventario */}
        <section className="section-new-inventory">
          <div className="carousel wrapper-contain">
            {vehicles.length > 0 && <h3>{t("home.NewInventory")}</h3>}
            <OwlCarousel
              className="owl-theme"
              loop
              nav
              navText={[
                `<img src="${arrowLeft}" />`,
                `<img src="${arrowRight}" />`,
              ]}
              margin={10}
              navContainerClass="owl-rccslider"
              navClass={["row-left", "row-right"]}
              dots={false}
              autoplay={true}
              responsive={{
                0: {
                  items: 1,
                },
                600: {
                  items: 2,
                },
                900: {
                  items: 3,
                },
              }}
              autoplayTimeout={2500}
            >
              {vehicles.map((i: Vehicle, index) => (
                <div className="item item-vehicle" key={index}>
                  <VehicleContainer vehicle={i} />
                </div>
              ))}
            </OwlCarousel>
          </div>
        </section>

        {/* About */}
        <div
          className="about"
          style={{
            backgroundImage: `url(${AboutHome})`,
          }}
        >
          {_about && (
            <div className="flex">
              <div className="container-about">
                <div className="container-text">
                  <h3>{ENV.NAME}</h3>
                  <p>{_about.text}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Inventario */}

        <div className="wrapper-contain inventory">
          {loadingInventory ? (
            <Loading />
          ) : (
            <div className="container-inventario">
              <h3>{t("home.Inventory")}</h3>
              <ScrollVehicles
                height={725}
                vehicles={inventory.slice(0, 15)}
                changePage={(page: number) => console.log(page)}
                total={total}
                renderLoading={Loading}
                onNext={() => setPage(page + 1)}
              />
            </div>
          )}
        </div>
        {/* testimonios */}
        <div
          className="testimonio"
          style={{
            backgroundImage: `url(${BannerTestimonios})`,
          }}
        >
          <div className="flex">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <OwlCarousel
                    className="owl-theme"
                    loop
                    nav
                    navText={[
                      `<img src="${arrowLeft}" />`,
                      `<img src="${arrowRight}" />`,
                    ]}
                    margin={10}
                    navContainerClass="owl-rccslider"
                    navClass={["row-left", "row-right"]}
                    dots={true}
                    autoplay={true}
                    responsive={{
                      0: {
                        items: 1,
                      },
                      600: {
                        items: 1,
                      },
                    }}
                    autoplayTimeout={5000}
                  >
                    <div className="item">
                      <div className="bg-t">
                        <h2>
                          Brindamos el servicio de exportación, principalmente a
                          Venezuela, Panamá, República Dominicana y México. Te
                          ayudamos en todo el proceso de la compra así como en
                          el envío y la nacionalización de tu vehículo.
                        </h2>
                      </div>
                    </div>
                    <div className="item">
                      <div className="bg-t">
                        <h2>
                          Estamos aliados con las principales y más responsables
                          compañías de envíos tanto en los Estados Unidos como a
                          nivel internacional, lo cual permite que la entrega de
                          tu auto sea simple, rápida y segura.
                        </h2>
                      </div>
                    </div>
                    <div className="item">
                      <div className="bg-t">
                        <h2>
                          Coordinaremos el envío del auto y prepararemos los
                          documentos necesarios de envío y despacho de aduana.
                        </h2>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* iframe */}
        <div
          className="about"
          style={{
            backgroundImage: `url(${BgIframe})`,
          }}
        >
          <div className="flex">
            <div className="container">
              <div className="iframe">
                <iframe
                  width="70%"
                  height="500"
                  src="https://www.youtube.com/embed/-m0stGu0FMw"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* Instagram */}
        {/* <div className="instagram">
<OwlCarousel
className="owl-theme"
loop
dots={ false }
autoplay={ true }
responsive={ {
0: {
items: 1
},
600: {
items: 3
},
800: {
items: 5
}
} }
autoplayTimeout={ 2500 }
>
{ instagram.map((i: Instagram, index) => (
<div className="item item-instagram" key={ index }>
<a target="_blank" href={ i.permalink }>
<img src={ i.media_url } />
</a>
</div>
)) }
</OwlCarousel>
</div>*/}
      </div>
    </Layout>
  );
};

export default Home;
