import { axios } from "utils";
import { AxiosError, AxiosResponse } from "axios";

class BannerService {
  static get = () => {
    return new Promise((resolve, reject) => {
      axios
        .post('banners/get')
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }

  static update = (params = {}) => {
    return new Promise((resolve, reject) => {
      axios
        .post('banners/update',params)
        .then(
          (response: AxiosResponse) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  }
}

export default BannerService;
