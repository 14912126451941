import React from "react";
import { Image } from "components";
import { Link } from "react-router-dom";
import { Vehicle } from "models";
import { Globals } from "utils";
import { useTranslation } from "react-i18next";
import { Tachometer } from "assets/icons";
import { PublicService } from "services";
import { useHistory } from "react-router-dom";

interface Props {
  vehicle: Vehicle;
  full?: boolean;
  onViewVehicle?: () => void;
}

const { REACT_APP_BASE_API: BaseURI } = process.env;

const VehicleContainer = (props: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const lookMore = async (element: any) => {
    if (props.onViewVehicle) {
      props.onViewVehicle();
    }
    const res = await PublicService.visitVehicle({ vehicle: element.id });
    history.push("/vehicle/" + element.brand?.name + "/" + element.slug);
  };
  
  return (
    <div
      className="vehicle-container"
      style={{
        width: "80%",
        cursor: "pointer",
      }}
      onClick={() => lookMore(props.vehicle)}
    >
      <div className="vehicle-shadow">
        <div className="container-image">
          <Image
            src={
              props.vehicle.photos.length > 0
                ? BaseURI + props.vehicle.photos[0].photo
                : undefined
            }
          />
        </div>
        <div className="flex">
          <div className="name">
            <p>
              {props.vehicle.brand?.name} {props.vehicle.model?.name}{" "}
              {props.vehicle.year}
            </p>
          </div>
        </div>
        <div className="row row-stock">
          <div className="col-lg-4 px-0">
            {props.vehicle.stockNumber && (
              <p>
                {t("inventory.Stock")}: {props.vehicle.stockNumber}
              </p>
            )}
          </div>          
          <div className="col-lg-4 px-0">
            {props.vehicle.mileage && (
              <p>
                <img src={Tachometer} />{" "}
                {Globals.formatNumber(props.vehicle.mileage)} mi
              </p>
            )}
          </div>          
          <div className="col-lg-4 px-0">
            {!props.vehicle.priceOriginal ? (
              <p className="callprice">{t("vehicle.CallPrice")}</p>
            ) : (
              <p className="price">{Globals.formatPrice(props.vehicle.price)}</p>
            )}
          </div>
        </div>
        <div className="text-center">
          <button type="button" className="btn btn-default details">
            {t("home.Details")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VehicleContainer;
