import { axios } from "utils";
import { AxiosError, AxiosResponse, CancelToken } from "axios";
import { Area, Areas, AreaWithServices } from "models";

class AreaService {
  static get = (source: { token: CancelToken }) => {
    return new Promise<Area[]>((resolve, reject) => {
      axios
        .get(`areas`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<Area[]>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static getByPage = (page: number, source: { token: CancelToken }) => {
    return new Promise<Areas>((resolve, reject) => {
      axios
        .get(`areas?paginated=true&page=${page}`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<Areas>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static getWithServices = (source: { token: CancelToken }) => {
    return new Promise<AreaWithServices[]>((resolve, reject) => {
      axios
        .get(`areas-services`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<AreaWithServices[]>) =>
            resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static getById = (id: number, source: { token: CancelToken }) => {
    return new Promise<Area>((resolve, reject) => {
      axios
        .get(`areas/${id}/edit`, {
          cancelToken: source.token
        })
        .then(
          (response: AxiosResponse<Area>) => resolve(response?.data),
          (error: AxiosError) => reject(error)
        );
    });
  };

  static store = (area: { name: string }) => {
    return new Promise<Area>((resolve, reject) => {
      axios.post("areas", area).then(
        (response: AxiosResponse<Area>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static update = (area: { id: number | null; name: string }) => {
    return new Promise<Area>((resolve, reject) => {
      axios.put(`areas/${area.id}`, area).then(
        (response: AxiosResponse<Area>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };

  static destroy = (id: number | null) => {
    return new Promise<Area>((resolve, reject) => {
      axios.delete(`areas/${id}`).then(
        (response: AxiosResponse<Area>) => resolve(response?.data),
        (error: AxiosError) => reject(error)
      );
    });
  };
}

export default AreaService;
