import React from "react";
import {Table, Pagination, Button, DatePicker} from 'components';
import {ReportService} from 'services';
import moment from "moment"
import { Globals } from "utils";

class MostVisits extends React.Component<any> {

    state = {
        items: [],
        totalVisits: 0,
        header: [
            'Fecha',
            'Visitas'
        ],
        form: {
            since: '',
            until: ''
        },
        pages: {
            last: '',
            next: '',
            currentPage: '',
            totalPages: ''
        },
        edit: null
    }

    componentDidMount(): void {
        moment.locale('es');
       this.load();
    }
    
    getSearches = async (page: number = 1, params:any = {}) => {
        Globals.setLoading();
        ReportService.paginateMostVisits(page, params)
        .then(({items, meta}) => {
            const totalVisits = items.reduce((prev:number, next:any) => prev + parseFloat((next.total).toFixed(2)), 0)
            this.setState({
                items: items,
                totalVisits: totalVisits,
                pages: {
                    currentPage: meta.currentPage,
                    totalPages: meta.totalPages
                }
            });
        })
        .catch((err) => {
            Globals.showError()
        })
        .finally(() => {
            Globals.quitLoading();
        });
    }

    change = (e: any) => {
        e.preventDefault();
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    changeDatepicker = (value: string, name: string) => {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    }
    
    load = async () => {
        await this.getSearches();
    }
    
    clearFilter = async () =>{
        this.setState((state:any) => ({
            ...state,
            form: {
                since: '',
                until: ''
            }
        }));
        await this.getSearches();
    }

    render() {
        const { items, form, pages, totalVisits } = this.state;
        return (
            <React.Fragment>
                <form className="row form-filter" onSubmit={ async (e: any) => {
                    e.preventDefault();
                    this.getSearches(1, form);
                } }>
                    <div className="col-md-3">
                        <DatePicker
                            name="since"
                            label="Desde"
                            color="white"
							maxDate={form.until ? moment(form.until).toDate() : moment().toDate()}
                            onChange={(value: string) => this.changeDatepicker(value, 'since') }
                            value={ form.since } />
                    </div>
                    <div className="col-md-3">
                        <DatePicker
                            name="until"
                            label="Hasta"
                            color="white"
                            minDate={form.since ? moment(form.since).toDate() : null}
							maxDate={moment().toDate()}
                            onChange={(value: string) => this.changeDatepicker(value, 'until') }
                            value={ form.until } />
                    </div>
                    <div className="col-md-2">
                        <Button className="btn-align-bottom text-black font-bold">
                            Filtrar
                        </Button>
                    </div>
                    <div className="col-md-2">
                        <Button onClick={() => this.clearFilter()} type="button" className="btn-align-bottom text-black font-bold">
                            Limpiar
                        </Button>
                    </div>
                </form>
                <Table data={ items.length } title="Visitas" header={this.state.header}>
                    { items && Array.isArray(items) && items.map((i: any,index: number) => {
                        return (
                            <tr key={ index }>
                                <td>{ moment(i.created_at).format('MM/DD/YYYY') }</td>
                                <td>{ i.total }</td>
                            </tr>
                        )
                    }) }
                    <tr>
                        <th>Total de Visitas:</th>
                        <td>{totalVisits}</td>
                    </tr>
                </Table>
                <Pagination 
                    pages={ pages } 
                    active={ pages.currentPage }
                    onChange={ async (page: number) => {
                        await this.setState((state:any) => ({
                            ...state,
                            pages: {
                                ...state.pages,
                                currentPage: page,
                            },
                        }));
                        this.getSearches(page, form);
                } } />
            </React.Fragment>
        )
    }
}

export default MostVisits;
