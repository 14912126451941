import React from 'react';
import { WhatsappWhite } from 'assets/img';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface Props {
	number?: string
}

const Whatsapp = (props: Props) => {
	const { t } = useTranslation();
	const contact = useSelector((state: { contact: {
		whatsapp: string
	} }) => state.contact);

	return (
		<div className="whatsapp-button">
			<a target="_blank" className="whatsapp-button" href={ `https://wa.me/${ props.number || contact.whatsapp }` }>
				<div className="container-icon">
					<img src={ WhatsappWhite } />
				</div>
				<div className="container-text">
					<p>{ t('home.Whatsapp') }</p>
				</div>
			</a>
		</div>
	)	
}

export default Whatsapp;